<template>
  <v-layout align-start>
    <v-flex>
      <v-data-table
        :headers="headers"
        :items="viaje"
        :search="search"
        :single-expand="true"
        :expanded.sync="expanded"
        sort-by="nombre"
        class="elevation-1"
        show-expand
        style="background: rgba(255, 255, 255, 0.7)"
      >
        <template #item.nro="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">{{ item.nro }}</span>
            </template>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th colspan="2">DETALLES</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="py-n5">
                    <td class="text-caption">COMISIONADO</td>
                    <td class="text-caption">
                      {{ item.comisionado_comisionado.usuario_usuario.nombre }}
                    </td>
                  </tr>
                  <tr class="py-n5">
                    <td class="text-caption">MOTIVO VIAJE</td>
                    <td class="text-caption">
                      {{ item.motivo }}
                    </td>
                  </tr>
                  <tr class="py-n5">
                    <td class="text-caption">CONEXION</td>
                    <td class="text-caption">
                      {{ item.ruta_viaje }}
                    </td>
                  </tr>
                  <tr class="py-n5">
                    <td class="text-caption">FINANCIAMIENTO</td>
                    <td class="text-caption">
                      {{ item.financiamiento_financiamiento.nombre }}
                    </td>
                  </tr>
                  <tr class="py-n5">
                    <td class="text-caption">TIPO VIAJE</td>
                    <td class="text-caption">
                      {{
                        item.viaje_nacionals[0].region_nacional_region_nacional
                          .nombre
                      }}
                    </td>
                  </tr>
                  <tr class="py-n5">
                    <td class="text-caption">RESOLUCION</td>
                    <td class="text-caption">
                      {{ item.resolucion_resolucion.descripcion }}
                    </td>
                  </tr>
                  <tr class="py-n5">
                    <td class="text-caption">FIN DE SEMANA</td>
                    <td class="text-caption">
                      {{ item.fines_semana }}
                    </td>
                  </tr>
                  <tr class="py-n5">
                    <td class="text-caption">PROGRAMA</td>
                    <td class="text-caption">
                      {{
                        item.comisionado_comisionado.usuario_usuario
                          .oficina_oficina.nombre
                      }}
                    </td>
                  </tr>
                  <tr class="py-1">
                    <td class="text-caption">TRANSPORTE</td>
                    <td class="text-caption">
                      {{ item.transporte_transporte.nombre }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-tooltip>
        </template>
        <template #item.total_viatico="{ item }">
          {{
            new Intl.NumberFormat("es-BO", { minimumFractionDigits: 2 }).format(
              item.total_viatico
            )
          }}
        </template>
        <template #item.tpasaje="{ item }">
          {{
            new Intl.NumberFormat("es-BO", { minimumFractionDigits: 2 }).format(
              item.tpasaje
            )
          }}
        </template>
        <template #item.fecha1="{ item }">
          {{
            item.fecha1
            /* new Date(
              item.fecha1.split(" ")[0].split("/").reverse().join("-")
            ).toLocaleDateString("en-GB") +
            " " +
            item.fecha1.split(" ")[1] */
          }}
        </template>
        <template #item.fecha2="{ item }">
          {{
            item.fecha2
            /* new Date(
              item.fecha2.split(" ")[0].split("/").reverse().join("-")
            ).toLocaleDateString("en-GB") +
            " " +
            item.fecha2.split(" ")[1] */
          }}
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>PRESUPUESTO VIAJES</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-autocomplete
              v-model="unidadModel"
              :items="unidad"
              @change="(valor) => changeUnidad(valor)"
              label="Unidad"
              v-if="isAdmin"
            ></v-autocomplete>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Buscar...."
              single-line
              hide-details
            >
            </v-text-field>
            <v-spacer></v-spacer>
            <!-- <v-btn depressed color="primary" @click="listTicket">
              <v-icon left dark fab large> airplane_ticket </v-icon>
              Pasaje Aereo
            </v-btn> -->
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-dialog v-model="dialog" max-width="850px">
                <!--<template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                    <v-icon left dark>person_add</v-icon> Agregar
                  </v-btn>
                </template>-->
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row class="py-md-1 my-md-n6">
                        <v-col cols="12">
                          <v-textarea
                            v-model="editedItem.motivo"
                            rows="2"
                            no-resize
                            color="primary"
                            :rules="[
                              (v) => !!v || 'Motivo es requerido es Requerido',
                            ]"
                            label="Motivo de Viaje"
                            required
                          >
                          </v-textarea>
                        </v-col>
                      </v-row>
                      <v-row class="py-md-1 my-md-n6">
                        <v-col cols="12" sm="6" md="4">
                          <v-autocomplete
                            v-model="editedItem.pais"
                            :items="pais"
                            :rules="[(v) => !!v || 'Pais es Requerido']"
                            label="Pais"
                            @change="(valor) => changeState(valor)"
                            required
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                          v-if="editedItem.pais == 187 || showLugar"
                        >
                          <v-select
                            v-model="editedItem.iddepto"
                            :items="departamentos"
                            :rules="[(v) => !!v || 'Departamento es Requerido']"
                            label="Departamento Destino"
                            required
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.lugar"
                            label="Lugar"
                            :rules="[(v) => !!v || 'Lugar es Requerido']"
                            @change="(valor) => changeState2(valor)"
                            required
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="py-md-1 my-md-n6">
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.ruta_viaje"
                            label="Ruta Viaje"
                            :rules="[(v) => !!v || 'Ruta es Requerido']"
                            required
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="6" md="4">
                          <v-select
                            v-model="editedItem.resolucion"
                            :items="resolucion"
                            :rules="[(v) => !!v || 'Resolución es Requerido']"
                            label="Resolución"
                            required
                          >
                          </v-select>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                          v-if="editedItem.pais == 187 || showLugar"
                        >
                          <v-select
                            v-model="editedItem.tv"
                            :items="rn"
                            :rules="[
                              (v) => !!v || 'Tipo de Viaje es Requerido',
                            ]"
                            label="Tipo de Viaje"
                            @change="(valor) => changeTipo(valor)"
                            required
                          >
                          </v-select>
                          <v-checkbox
                            v-model="editedItem.distancia"
                            v-if="showCheck"
                            label="¿Es Menor a 100 Km?"
                          >
                          </v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" v-else>
                          <v-select
                            v-model="editedItem.tv"
                            :items="ri"
                            :rules="[
                              (v) => !!v || 'Tipo de Viaje es Requerido',
                            ]"
                            label="Tipo de Viaje"
                            required
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-select
                            v-model="editedItem.idunidad"
                            :items="unidad"
                            :rules="[(v) => !!v || 'Unidad es Requerido']"
                            label="Unidad"
                            required
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-select
                            v-model="editedItem.transporte"
                            :items="transporte"
                            :rules="[(v) => !!v || 'Transporte es Requerido']"
                            label="Transporte"
                            required
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-select
                            v-model="editedItem.financiamiento"
                            :items="financiamientos"
                            :rules="[
                              (v) => !!v || 'Financiamiento es Requerido',
                            ]"
                            label="Financiamiento"
                            required
                          >
                          </v-select>
                        </v-col>
                        <v-row class="py-md-0 my-md-n6">
                          <v-col cols="12">
                            <v-radio-group
                              v-model="editedItem.radioPoa"
                              label="Se encuentra en el POA"
                              row
                              :rules="[(v) => !!v || 'Poa es Requerido']"
                              required
                            >
                              <v-radio label="SI" value="1"></v-radio>
                              <v-radio label="NO" value="2"></v-radio>
                            </v-radio-group>
                          </v-col>
                        </v-row>
                        <v-row class="py-md-3 my-md-n6">
                          <v-col cols="12">
                            <v-radio-group
                              v-model="editedItem.radioRow"
                              label="Comisión de Viajes:"
                              row
                              :rules="[
                                (v) => !!v || 'Financiamiento es Requerido',
                              ]"
                              required
                            >
                              <v-radio label="Ninguna" value="1"></v-radio>
                              <v-radio
                                label="Excepcion - (ART. 14 RPV)"
                                value="2"
                              ></v-radio>
                              <v-radio
                                label="Extraordinario - (Instr. MAE)"
                                value="3"
                              ></v-radio>
                            </v-radio-group>
                          </v-col>
                        </v-row>
                        <v-row class="py-md-1 my-md-n6">
                          <v-col cols="12" sm="6" md="4">
                            <v-menu
                              v-model="menu"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="editedItem.date"
                                  :items="date"
                                  label="Dia partida"
                                  prepend-icon="calendar_month"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  :rules="[(v) => !!v || 'Fecha es Requerido']"
                                  required
                                >
                                </v-text-field>
                              </template>
                              <v-date-picker
                                v-model="editedItem.date"
                                @input="menu = false"
                              >
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-menu
                              ref="menu"
                              v-model="menu3"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              :return-value.sync="editedItem.time"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="editedItem.time"
                                  :items="editedItem.time"
                                  label="Hora de Partida"
                                  prepend-icon="schedule"
                                  readonly
                                  v-bind="attrs"
                                  :rules="[(v) => !!v || 'Hora es Requerido']"
                                  v-on="on"
                                  required
                                >
                                </v-text-field>
                              </template>
                              <v-time-picker
                                v-if="menu3"
                                v-model="editedItem.time"
                                full-width
                                format="24hr"
                                @click:minute="$refs.menu.save(editedItem.time)"
                              >
                              </v-time-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                        <v-row class="py-md-1 my-md-n6">
                          <v-col cols="12" sm="6" md="4">
                            <v-menu
                              v-model="menu2"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="editedItem.date2"
                                  :items="editedItem.date2"
                                  label="Dia Retorno"
                                  prepend-icon="calendar_month"
                                  readonly
                                  v-bind="attrs"
                                  :rules="[(v) => !!v || 'Fecha es Requerido']"
                                  v-on="on"
                                  required
                                >
                                </v-text-field>
                              </template>
                              <v-date-picker
                                v-model="editedItem.date2"
                                :min="editedItem.date"
                                @input="menu2 = false"
                              >
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-menu
                              ref="menu2"
                              v-model="menu4"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              :return-value.sync="editedItem.time2"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="editedItem.time2"
                                  :items="editedItem.time2"
                                  label="Hora de Retorno"
                                  prepend-icon="schedule"
                                  readonly
                                  v-bind="attrs"
                                  :rules="[(v) => !!v || 'Hora es Requerido']"
                                  v-on="on"
                                  required
                                >
                                </v-text-field>
                              </template>
                              <v-time-picker
                                v-if="menu4"
                                v-model="editedItem.time2"
                                format="24hr"
                                full-width
                                @click:minute="
                                  $refs.menu2.save(editedItem.time2)
                                "
                              >
                              </v-time-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn
                      :disabled="!valid"
                      color="blue darken-1"
                      text
                      @click="save"
                    >
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-form>
            <v-form ref="form2" v-model="valid2" lazy-validation>
              <v-dialog v-model="dialog2" max-width="800px">
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle2 }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row class="py-md-0 my-md-n7">
                        <v-col cols="12" sm="4" md="3">
                          <v-subheader>Autorización</v-subheader>
                        </v-col>
                        <v-col cols="12" sm="8" md="8">
                          <v-text-field
                            v-model="editedItem2.cite"
                            solo
                            readonly
                            required
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="py-md-0 my-md-n7">
                        <v-col cols="12" sm="4" md="3">
                          <v-subheader>Programa</v-subheader>
                        </v-col>
                        <v-col cols="12" sm="8" md="8">
                          <v-text-field
                            v-model="editedItem2.unidad"
                            solo
                            readonly
                            required
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="py-md-0 my-md-n7">
                        <v-col cols="12" sm="4" md="3">
                          <v-subheader>Periodo de Comisión</v-subheader>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                          <v-text-field
                            v-model="editedItem2.periodoIda"
                            solo
                            readonly
                            required
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                          <v-text-field
                            v-model="editedItem2.periodoVuelta"
                            solo
                            readonly
                            required
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="py-md-0 my-md-n7">
                        <v-col cols="12" sm="4" md="3">
                          <v-subheader>Viatico Financiamiento</v-subheader>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                          <v-text-field
                            v-model="editedItem2.viatico"
                            solo
                            readonly
                            required
                            suffix="DIA(S)"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                          <v-text-field
                            v-model="editedItem2.financiamiento"
                            suffix="Bs."
                            solo
                            readonly
                            required
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="py-md-0 my-md-n6">
                        <v-col cols="12" sm="4" md="3">
                          <v-subheader>Saldo Pasaje/Viatico</v-subheader>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                          <v-text-field
                            v-model="editedItem2.pasaje"
                            suffix="Bs."
                            type="number"
                            solo
                            readonly
                            required
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                          <v-text-field
                            v-model="editedItem2.salViatico"
                            suffix="Bs."
                            type="number"
                            solo
                            readonly
                            required
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="py-md-0 my-md-n7" v-if="swtr"></v-row>
                      <v-list two-line>
                        <template
                          v-if="swtr"
                          v-for="(pasaje, index) in editedItem2.pasajeTerr"
                        >
                          <div class="mb-md-3 mt-md-5">
                            {{ "Pasaje Terrestre N° " + (index + 1) }}
                          </div>
                          <v-list-item>
                            <v-list-item-action>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    color="error"
                                    @click="delPasaje(index)"
                                  >
                                    <v-icon>remove_circle_outline</v-icon>
                                  </v-btn>
                                </template>
                                <span>Eliminar Pasaje</span>
                              </v-tooltip>
                            </v-list-item-action>
                            <v-list-item-content class="py-md-1 my-md-n6">
                              <v-col cols="12" sm="4" md="6">
                                <v-text-field
                                  v-model="pasaje.pasaje"
                                  type="number"
                                  step="0.1"
                                  max="999.99"
                                  label="Pasaje Terrestre"
                                  :rules="[
                                    (v) => v > -0.00001 || 'Cifra incorrecta',
                                  ]"
                                  suffix="Bs."
                                  class="inputPrice"
                                  required
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" sm="4" md="6">
                                <v-text-field
                                  v-model="pasaje.factura"
                                  type="number"
                                  label="Factura Terrestre"
                                  class="inputPrice"
                                  required
                                >
                                </v-text-field>
                              </v-col>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    color="success"
                                    @click="addPasaje()"
                                  >
                                    <v-icon>add_circle_outline</v-icon>
                                  </v-btn>
                                </template>
                                <span>Adicionar Pasaje</span>
                              </v-tooltip>
                            </v-list-item-action>
                          </v-list-item>
                        </template>
                      </v-list>

                      <br />
                      <v-row class="py-md-0 my-md-n7" v-if="swtr"> </v-row>
                      <v-list two-line>
                        <template
                          v-if="swtrAereo"
                          v-for="(boleto, index) in editedItem2.boletoAereo"
                        >
                          <div class="mb-md-3 mt-md-5">
                            {{ "Boleto de Avion N° " + (index + 1) }}
                          </div>
                          <v-row class="py-md-0 my-md-n7" v-if="swtrAereo">
                            <v-row
                              class="py-md-0 my-md-n7"
                              v-if="swtrAereo && swBol"
                            >
                              <v-col cols="12">
                                <v-select
                                  v-model="editedItem2.auxBoleto"
                                  :items="boletos"
                                  label="Reutilizar Boleto"
                                  @change="(valor) => changeBoleto(valor)"
                                  required
                                >
                                </v-select>
                              </v-col>
                            </v-row>
                            <v-list-item>
                              <v-list-item-action>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      v-bind="attrs"
                                      v-on="on"
                                      color="error"
                                      @click="removeboleto(index)"
                                    >
                                      <v-icon>remove_circle_outline</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Eliminar Boleto</span>
                                </v-tooltip>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-col
                                  class="py-md-0 my-md-0"
                                  cols="2"
                                  sm="4"
                                  md="4"
                                >
                                  <v-select
                                    v-model="boleto.lineaAerea"
                                    :items="linea"
                                    :rules="[
                                      (v) => !!v || 'Linea Aerea es Requerido',
                                    ]"
                                    label="Linea Aerea"
                                    required
                                  >
                                  </v-select>
                                </v-col>
                                <v-col
                                  class="py-md-0 my-md-0"
                                  cols="2"
                                  sm="4"
                                  md="4"
                                  v-if="swtrAereo"
                                >
                                  <v-text-field
                                    v-model="boleto.reBoleto"
                                    label="Codigo Boleto"
                                    v-if="swtrAereo"
                                    :rules="[
                                      (v) =>
                                        !!v || 'Codigo de Boleto es Requerido',
                                    ]"
                                    required
                                  >
                                  </v-text-field>
                                </v-col>
                                <v-col
                                  class="py-md-0 my-md-0"
                                  cols="2"
                                  sm="4"
                                  md="4"
                                  v-if="editedItem2.resolucion != 4"
                                >
                                  <v-text-field
                                    v-model="boleto.pasAereo"
                                    type="number"
                                    step="0.1"
                                    label="Pasaje Aereo"
                                    :rules="[
                                      (v) => v > -0.00001 || 'Cifra incorrecta',
                                      (v) => !!v || 'Pasaje Aereo es Requerido',
                                    ]"
                                    class="inputPrice"
                                    suffix="Bs."
                                    required
                                  >
                                  </v-text-field>
                                </v-col>
                              </v-list-item-content>
                              <v-list-item-action>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      v-bind="attrs"
                                      v-on="on"
                                      color="success"
                                      @click="addboleto()"
                                    >
                                      <v-icon>add_circle_outline</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Añadir Boleto</span>
                                </v-tooltip>
                              </v-list-item-action>
                            </v-list-item>
                          </v-row>
                        </template>
                      </v-list>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="presupClose">
                      Cancelar
                    </v-btn>
                    <v-btn
                      :disabled="!valid2"
                      color="blue darken-1"
                      text
                      @click="savePresup"
                    >
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-form>
            <v-dialog v-model="dialogCancel" max-width="500px">
              <v-card>
                <v-card-title class="text-h5"
                  >¿Esta seguro de Anular el viaje?
                </v-card-title>
                <v-card-text>
                  <v-textarea
                    v-model="motivo"
                    auto-grow
                    label="Motivo"
                    rows="1"
                  ></v-textarea>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="red darken-1" text @click="closeCancelViaje"
                    >Cancelar
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="cancelViajeConfirm"
                    >Aceptar
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialog4" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">Cambiar Fecha</v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" lg="6">
                        <v-menu
                          ref="menu5"
                          v-model="menu5"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="date"
                              label="Date"
                              persistent-hint
                              prepend-icon="mdi-calendar"
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="date"
                            no-title
                            @input="menu5 = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeCamFecha">
                    Cancelar
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="crearPDF(solPdf)"
                    >Guardar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!---------------------------------------------------------------------------------------->
            <!-------------MODAL DE LIQUIDACION------------------------------------------------------->

            <v-form ref="form3" v-model="valid3" lazy-validation>
              <v-dialog v-model="dialog3" max-width="900px">
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle3 }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row class="py-md-1 my-md-n6">
                        <v-col cols="12">
                          <v-subheader>Motivo de Viaje</v-subheader>
                          <v-textarea
                            v-model="editedItem3.motivoViaje"
                            rows="2"
                            solo
                            readonly
                            required
                          >
                          </v-textarea>
                        </v-col>
                      </v-row>
                      <v-row class="py-md-0 my-md-n7">
                        <v-col cols="12" sm="3" md="3">
                          <v-subheader>Lugar de Comision</v-subheader>
                        </v-col>
                        <v-col cols="12" sm="3" md="3">
                          <v-select
                            v-model="editedItem3.paisComision"
                            :items="pais"
                            :rules="[(v) => !!v || 'Pais es Requerido']"
                            solo
                            readonly
                            required
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="12" sm="3" md="3">
                          <v-text-field
                            v-model="editedItem3.iddeptoComision"
                            solo
                            readonly
                            required
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3" md="3">
                          <v-text-field
                            v-model="editedItem3.lugarli"
                            solo
                            readonly
                            required
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="py-md-0 my-md-n7">
                        <v-col cols="12" sm="4" md="3">
                          <v-subheader>Ruta de Viaje</v-subheader>
                        </v-col>
                        <v-col cols="12" sm="8" md="9">
                          <v-text-field
                            v-model="editedItem3.ruta_viajeLi"
                            solo
                            readonly
                            required
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="py-md-0 my-md-n7">
                        <v-col cols="12" sm="4" md="3">
                          <v-subheader>Resolucion</v-subheader>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                          <v-select
                            v-model="editedItem3.resolucionLi"
                            :items="resolucion"
                            solo
                            readonly
                            required
                          >
                          </v-select>
                        </v-col>
                      </v-row>
                      <v-row class="py-md-0 my-md-n7">
                        <v-col cols="12" sm="4" md="3">
                          <v-subheader>Programa-Transporte</v-subheader>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                          <v-select
                            v-model="editedItem3.idunidadLi"
                            :items="unidad"
                            :rules="[(v) => !!v || 'Unidad es Requerido']"
                            solo
                            readonly
                            required
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                          <v-select
                            v-model="editedItem3.transporteLi"
                            :items="transporte"
                            solo
                            readonly
                            required
                          >
                          </v-select>
                        </v-col>
                      </v-row>
                      <v-row class="py-md-0 my-md-n7">
                        <v-col cols="12" sm="4" md="4">
                          <v-subheader>¿Menor a 100 Km?</v-subheader>
                        </v-col>
                        <v-checkbox
                          v-model="editedItem3.distanciaLi"
                          solo
                          readonly
                          required
                        >
                        </v-checkbox>
                      </v-row>
                      <v-row class="py-md-0 my-md-n7">
                        <v-col cols="12" sm="4" md="3">
                          <v-subheader>Viaje-Financiamiento</v-subheader>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                          <v-text-field
                            v-model="editedItem3.tvLi"
                            label="Tipo de Viaje"
                            solo
                            readonly
                            required
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                          <v-select
                            v-model="editedItem3.financiamientoLi"
                            :items="financiamientos"
                            :rules="[
                              (v) => !!v || 'Financiamiento es Requerido',
                            ]"
                            solo
                            readonly
                            required
                          >
                          </v-select>
                        </v-col>
                      </v-row>
                      <v-row class="py-md-0 my-md-n7">
                        <v-col cols="12" sm="4" md="3">
                          <v-subheader>Periodo de Comision</v-subheader>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                          <v-text-field
                            v-model="editedItem3.fecha1li"
                            solo
                            readonly
                            required
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                          <v-text-field
                            v-model="editedItem3.fecha2li"
                            solo
                            readonly
                            required
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="py-md-0 my-md-n7">
                        <v-col cols="12" sm="4" md="3">
                          <v-subheader
                            >Costo de Pasajes (Aereo/Terrestre)
                          </v-subheader>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                          <v-text-field
                            v-model="editedItem3.pasAereoLi"
                            solo
                            :disabled="showaereo"
                            required
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                          <v-text-field
                            v-model="editedItem3.pasTerrestreLi"
                            solo
                            :disabled="showterrestre"
                            required
                            value="Factura"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="py-md-0 my-md-n7">
                        <v-col cols="12" sm="4" md="3">
                          <v-subheader>Viático</v-subheader>
                        </v-col>

                        <v-radio-group
                          v-model="editedItem3.anticipo"
                          :rules="[(v) => !!v || 'Seleccion Requerida']"
                          label=""
                          row
                          required
                        >
                          <v-radio label="Reembolso" value="NO"></v-radio>
                          <v-radio label="Anticipo" value="SI"></v-radio>
                        </v-radio-group>
                        <!--                         <v-radio-group
                          v-model="editedItem3.facturacionterres"
                          :rules="[(v) => !!v || 'Seleccion Requerida']"
                          label=""
                          row
                          required
                          @change="(valor) => changetipote(valor)"
                        >
                          <v-radio
                            label="Reembolso"
                            value="reembolso"
                          ></v-radio>
                          <v-radio label="Anticipo" value="anticipo"></v-radio>
                        </v-radio-group> -->
                      </v-row>
                      <v-row class="py-md-0 my-md-n7">
                        <v-col cols="12" sm="4" md="3">
                          <v-subheader
                            >Recomendaciones Observaciones</v-subheader
                          >
                        </v-col>
                        <v-col cols="12" sm="8" md="8">
                          <v-text-field
                            v-model="editedItem3.observaciones"
                            label="Observaciones"
                            required
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close1">
                      Cancelar
                    </v-btn>
                    <v-btn
                      :disabled="!valid3"
                      color="blue darken-1"
                      text
                      @click="save1"
                    >
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-form>
          </v-toolbar>

          <!--------------------------------------------------------------------------------------------------->
          <!--------------------------------------------------------------------------------------------------->
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="text-truncate">
            <v-tooltip
              bottom
              v-if="item.estado_estado.id > 2 && rol == 'ROLE_ADMIN'"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  class="mr-2"
                  @click="editItem(item)"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mode_edit </v-icon>
                </v-btn>
              </template>
              <span>Editar Viaje</span>
            </v-tooltip>
            <v-tooltip
              bottom
              v-if="item.estado_estado.id == 1 || item.estado_estado.id == 2"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  class="mr-2"
                  @click="editItem(item)"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mode_edit</v-icon>
                </v-btn>
              </template>
              <span>Editar Viaje</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.estado_estado.id == 1">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  class="mr-2"
                  @click="presupEdit(item)"
                  color="accent"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>sell</v-icon>
                </v-btn>
              </template>
              <span>Presupuestar</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.estado_estado.id <= 4">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  class="mr-2"
                  @click="camFecha(item)"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>print</v-icon>
                </v-btn>
              </template>
              <span>Imprimir Solicitud</span>
            </v-tooltip>
            <v-tooltip
              bottom
              v-if="
                item.estado_estado.id == 2 ||
                (item.estado_estado.id == 3 && item.transporte != 3)
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  class="mr-2"
                  @click="presupEdit2(item)"
                  color="success"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>sell </v-icon>
                </v-btn>
              </template>
              <span>Editar Presupuesto</span>
            </v-tooltip>
            <v-tooltip
              bottom
              v-if="item.estado_estado.id > 3 && rol == 'ROLE_ADMIN'"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  class="mr-2"
                  @click="presupEdit2(item)"
                  color="success"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>sell </v-icon>
                </v-btn>
              </template>
              <span>Editar Presupuesto</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.estado_estado.id == 3">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  class="mr-2"
                  @click="editItem1(item)"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>request_quote</v-icon>
                </v-btn>
              </template>
              <span>Liquidar</span>
            </v-tooltip>
            <v-tooltip
              bottom
              v-if="item.estado_estado.id == 1 || item.estado_estado.id == 2"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  class="mr-2"
                  @click="cancelViaje(item)"
                  color="error"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>do_disturb_alt</v-icon>
                </v-btn>
              </template>
              <span>Cancelar Viaje</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.estado_estado.id >= 2">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  class="mr-2"
                  @click="crearPresupPDF(item)"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>print</v-icon>
                </v-btn>
              </template>
              <span>Imprimir Presupuesto</span>
            </v-tooltip>
            <v-tooltip
              bottom
              v-if="
                item.estado_estado.id == 2 &&
                rol == 'ROLE_ADMIN' &&
                new Date() > new Date(item.fecha_informe)
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  class="mr-2"
                  @click="changeEdit(item)"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>edit_document</v-icon>
                </v-btn>
              </template>
              <span
                >Habilitar subir informe
                {{ new Date(item.fecha_informe) }}
                {{ item.fecha_informe }}</span
              >
            </v-tooltip>
            <v-tooltip bottom v-if="item.estado_estado.id == 4">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  class="mr-2"
                  @click="crearLiquiPDF(item)"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>local_printshop</v-icon>
                </v-btn>
              </template>
              <span>Imprimir Liquidación</span>
            </v-tooltip>
            <v-tooltip
              bottom
              v-if="item.estado_estado.id == 3 || item.estado_estado.id == 4"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  class="mr-2"
                  @click="bajarPDF(item)"
                  color="info"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>picture_as_pdf</v-icon>
                </v-btn>
              </template>
              <span>Descargar Informe</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.estado_estado.id == 3">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  class="mr-2"
                  @click="rechazar(item)"
                  color="error"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>clear</v-icon>
                </v-btn>
              </template>
              <span>Rechazar Informe</span>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="viajeList()"> Refrescar </v-btn>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <div v-if="item.expanded">
              <v-data-table
                :headers="headers2"
                :items="item.pasajes"
                :disable-pagination="true"
                class="mx-16"
                hide-default-footer
              >
                <template #item.pas_aereo="{ item }">
                  {{
                    new Intl.NumberFormat("es-BO", {
                      minimumFractionDigits: 2,
                    }).format(item.pas_aereo)
                  }}
                </template>
                <template v-slot:item.actions="{ item }">
                  <div class="text-truncate" v-if="item.expanded">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          icon
                          class="mr-2"
                          @click="liberar(item)"
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>airplane_ticket</v-icon>
                        </v-btn>
                      </template>
                      <span>Liberar</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          icon
                          class="mr-2"
                          @click="anular(item)"
                          color="error"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>no_sim</v-icon>
                        </v-btn>
                      </template>
                      <span>Anular</span>
                    </v-tooltip>
                  </div>
                </template>
                <template v-slot:no-data>
                  <h6>No se encuentra en presupuesto</h6>
                </template>
                <template v-slot:body.append>
                  <tr>
                    <td class="text-right" colspan="2">Total:</td>
                    <td class="text-right">
                      {{
                        new Intl.NumberFormat("es-BO", {
                          minimumFractionDigits: 2,
                        }).format(sumField("pas_aereo", item.pasajes))
                      }}
                    </td>
                    <td></td>
                  </tr>
                </template>
              </v-data-table>
            </div>
            <v-data-table
              :headers="headers2"
              :items="item.pasajes"
              :disable-pagination="true"
              class="mx-16"
              hide-default-footer
            >
              <template #item.pas_aereo="{ item }">
                {{
                  new Intl.NumberFormat("es-BO", {
                    minimumFractionDigits: 2,
                  }).format(item.pas_aereo)
                }}
              </template>
              <template v-slot:item.actions="{ item }">
                <div class="text-truncate">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        icon
                        class="mr-2"
                        @click="liberar(item)"
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>airplane_ticket</v-icon>
                      </v-btn>
                    </template>
                    <span>Liberar</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        icon
                        class="mr-2"
                        @click="anular(item)"
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>no_sim</v-icon>
                      </v-btn>
                    </template>
                    <span>Anular</span>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:no-data>
                <h6>No se encuentra en presupuesto</h6>
              </template>
              <template v-slot:body.append>
                <tr>
                  <td class="text-right" colspan="2">Total:</td>
                  <td class="text-right">
                    {{
                      new Intl.NumberFormat("es-BO", {
                        minimumFractionDigits: 2,
                      }).format(sumField("pas_aereo", item.pasajes))
                    }}
                  </td>
                  <td></td>
                </tr>
              </template>
            </v-data-table>
          </td>
        </template>
      </v-data-table>
      <v-snackbar
        v-model="snackbar"
        :timeout="2200"
        bottom
        centered
        rounded="pill"
        :color="color"
        elevation="12"
      >
        <template>
          {{ message }}
        </template>
      </v-snackbar>
    </v-flex>
  </v-layout>
</template>

<script>
import axios from "axios";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import QRCode from "qrcode";
import "../assets/fonts/verdana-normal.js";

const logo = require("@/assets/logoCab.jpg");
const logo2 = require("@/assets/membrete.png");
export default {
  data() {
    return {
      dialog: false,
      dialogCancel: false,
      dialogTicket: false,
      valid: false,
      rol: "",
      dialog2: false,
      dialog3: false,
      dialog4: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 10),
      valid2: false,
      valid3: false,
      solPdf: {},
      showLugar: false,
      showCheck: false,
      search: "",
      message: "",
      color: "",
      snackbar: false,
      dateNow: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 10),
      timeNow: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substring(11, 5),
      menu: "",
      menu2: "",
      menu3: "",
      menu4: "",
      menu5: "",
      menuli: "",
      menu2li: "",
      menu3li: "",
      menu4li: "",
      motivo: "",
      model: 1,
      showaereo: "",
      showterrestre: "", //showfacte:"",
      //showfacaer:"",
      swtr: false,
      swtrAereo: false,
      swBol: false,
      viaje: [],
      checkbox: false,
      departamentos: [],
      pais: [],
      categoria: "",
      transporte: [],
      unidad: [],
      financiamientos: [],
      presupuesto: [],
      expanded: [],
      ri: [],
      rn: [],
      linea: [],
      boletos: [],
      reuBoleto: [],
      resolucion: [],
      unidadModel: "",
      headers: [
        { text: "Nro°", value: "nro", sortable: false, width: "7%" },
        { text: "Pais", value: "pais_pai.nombre", sortable: false },
        {
          text: "Funcionario",
          value: "comisionado_comisionado.usuario_usuario.nombre",
        },
        {
          text: "Departamento",
          value: "iddepto_departamento.departamento",
          sortable: false,
        },
        { text: "Lugar", value: "lugar", sortable: false },
        { text: "Ida", value: "fecha1", sortable: false },
        { text: "Retorno", value: "fecha2", sortable: false },
        {
          text: "Viaticos",
          value: "total_viatico",
          sortable: false,
          align: "end",
        },
        { text: "Pasajes", value: "tpasaje", sortable: false, align: "end" },
        { text: "Dias", value: "dias", sortable: false },
        { text: "Estado", value: "estado_estado.nombre", sortable: false },
        {
          text: "Opciones",
          value: "actions",
          align: "center",
          width: "10%",
          sortable: false,
        },
      ],
      headers2: [
        { text: "Linea Aerea", value: "aereo.linea_aerea" },
        { text: "Codigo", value: "numero_boleto" },
        {
          text: "Costo Boleto",
          value: "pas_aereo",
          align: "right",
          width: "140",
        },
        {
          text: "Opciones",
          value: "actions",
          align: "center",
          width: "10%",
          sortable: false,
        },
      ],
      editedIndex: -1,
      editedIndex2: -1,
      editedIndex3: -1,
      editedItem: {
        anticipo: null,
        cite: null,
        comisionado: null,
        cot_dolar: null,
        dias: null,
        estado: null,
        fecha1: null,
        fecha2: null,
        fecha_informe: null,
        fecha_reliquidado: null,
        financiamiento: null,
        fines_semana: null,
        fuente_financiamiento: null,
        id: null,
        iddepto: null,
        idpresupuesto: null,
        idunidad: null,
        iva: null,
        lugar: null,
        motivo: null,
        pais: null,
        pais_pai: null,
        representacion: null,
        resolucion: null,
        ruta_viaje: null,
        slug: null,
        total_viatico: null,
        transporte: null,
        viatico: null,
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substring(0, 10),
        date2: null,
        time: null,
        time2: null,
        tv: "",
        distancia: false,
        radioRow: "",
        radioPoa: "",
      },
      defaultItem: {
        anticipo: null,
        cite: null,
        comisionado: null,
        cot_dolar: null,
        dias: null,
        estado: null,
        fecha1: null,
        fecha2: null,
        fecha_informe: null,
        fecha_reliquidado: null,
        financiamiento: null,
        fines_semana: null,
        fuente_financiamiento: null,
        id: null,
        iddepto: null,
        idpresupuesto: null,
        idunidad: null,
        iva: null,
        lugar: null,
        motivo: null,
        pais: null,
        pais_pai: null,
        representacion: null,
        resolucion: null,
        ruta_viaje: null,
        slug: null,
        total_viatico: null,
        transporte: null,
        viatico: null,
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substring(0, 10),
        date2: null,
        time: null,
        time2: null,
        tv: "",
        distancia: false,
        radioRow: "",
        radioPoa: "",
        facturacion: "",
      },
      editedItem2: {
        id: "",
        cite: "",
        unidad: "",
        periodoIda: "",
        periodoVuelta: "",
        viatico: "",
        financiamiento: "",
        pasaje: "",
        salViatico: "",
        pasTerrestre: 0,
        boletoAereo: [
          {
            id: null,
            reBoleto: null,
            lineaAerea: null,
            pasAereo: null,
          },
        ],
        delboletoAereo: [],
        pasajeTerr: [
          {
            id: null,
            pasaje: null,
            factura: null,
          },
        ],
        delpasajeTerr: [],
        aux: 0,
        auxBoleto: "",
      },
      defaultItem2: {
        id: "",
        cite: "",
        unidad: "",
        periodoIda: "",
        periodoVuelta: "",
        viatico: "",
        financiamiento: "",
        pasaje: "",
        salViatico: "",
        pasTerrestre: "",
        boletoAereo: [
          {
            id: null,
            reBoleto: null,
            lineaAerea: null,
            pasAereo: null,
          },
        ],
        delboletoAereo: [],
        pasajeTerr: [
          {
            id: null,
            pasaje: null,
            factura: null,
          },
        ],
        delpasajeTerr: [],
        aux: 0,
        auxBoleto: "",
      },
      editedItem3: {
        id: "",
        anticipo: "NO",
        motivoViaje: "",
        paisComision: "",
        iddeptoComision: "",
        lugarli: "",
        ruta_viajeLi: "",
        resolucionLi: null,
        transporteLi: "",
        distanciaLi: "",
        idunidadLi: null,
        tvLi: "",
        financiamientoLi: "",
        pasAereoLi: "",
        pasTerrestreLi: "",
        boletosLi: "",
        fines_semana: null,
        fecha1li: "",
        fecha2li: "",
        fecha1: "",
        fecha2: "",
        num_factura: null,
        tazaAereoLi: "",
        fines_semanali: "",
        //cut_monto: null,
        //preventivo_c31: null,
        observaciones: null,
        //reembolso: "",
        //anticipo:null,
        //showanticipo:false,
        snackbar: false,
        estado: null,
        idviaje: "",
        idpasajes: "",
        //fecha2Li1:null
        saldo_viaticos: null,
        dateli: null,
        date2li: null,
        timeli: null,
        time2li: null,
        fecha_edita: null,
        descuento: null,
        recibo: null,
        facturacionaereo: null,
        num_facturaaereo: null,
        num_facturaterres: null,
        facturacionterres: null,
        descuento: 0.155,
      },
      defaultItem3: {
        id: "",
        anticipo: "NO",
        motivoViaje: "",
        paisComision: "",
        iddeptoComision: "",
        lugarli: "",
        ruta_viajeLi: "",
        resolucionLi: "",
        distanciaLi: "",
        idunidadLi: "",
        tvLi: "",
        boletosLi: "",
        financiamientoLi: "",
        fines_semanali: "",
        fecha1: "",
        fecha2: "",
        pasAereoLi: "",
        pasTerrestreLi: "",
        num_factura: null,
        tazaAereoLi: "",
        //cut_monto: null,
        //preventivo_c31: null,
        observaciones: null,
        //cut_comprobante: null,
        // reembolso: "",
        //anticipo:null,
        //showanticipo:false,
        snackbar: false,
        estado: null,
        idviaje: "",
        idpasajes: "",
        //fecha2Li1:null
        saldo_viaticos: null,
        dateli: "",
        date2li: "",
        timeli: "",
        time2li: "",
        dias: "",
        fecha_edita: "",
        descuento: "",
        facturacion: "",
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Registro" : "Editar Registro";
    },
    formTitle2() {
      return this.editedIndex2 === -1
        ? "Asignación de Presupuesto"
        : "Editar Asignación de Presupuesto";
    },
    formTitle3() {
      return this.editedIndex3 === -1
        ? "Registrar Liquidacion"
        : "Editar Asignacion de Liquidacion";
    },
    isAdmin() {
      return (
        this.$store.state.user && this.$store.state.user.rol == "ROLE_ADMIN"
      );
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialog2(val) {
      val || this.presupClose();
    },
    dialgo3(val) {
      val || this.close1();
    },
  },
  created() {
    this.initialize();
    //this.liquidacionList();
  },
  methods: {
    initialize() {
      let aux = this;
      let header = { Token: this.$store.state.token };
      let conf = { headers: header };
      axios
        .get("pais/list", conf)
        .then((response) => {
          response.data.map((item) => {
            aux.pais.push({ text: item.nombre, value: item.id });
          });
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 403) {
            this.$store.dispatch("out");
          }
        });
      axios
        .get("departamento/list", conf)
        .then((response) => {
          response.data.map((item) => {
            aux.departamentos.push({ text: item.departamento, value: item.id });
          });
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get("transporte/list", conf)
        .then((response) => {
          response.data.map((item) => {
            aux.transporte.push({ text: item.nombre, value: item.id });
          });
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get("unidad/list", conf)
        .then((response) => {
          response.data.map((item) => {
            aux.unidad.push({ text: item.descripcion, value: item.id });
          });
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get("financiamiento/list", conf)
        .then((response) => {
          response.data.map((item) => {
            aux.financiamientos.push({ text: item.nombre, value: item.id });
          });
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get("region_nacional/list", conf)
        .then((response) => {
          response.data.map((item) => {
            aux.rn.push({ text: item.nombre, value: item.id });
          });
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get("region_internacional/list", conf)
        .then((response) => {
          response.data.map((item) => {
            aux.ri.push({ text: item.nombre, value: item.id });
          });
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get("linea_aerea/list", conf)
        .then((response) => {
          response.data.map((item) => {
            aux.linea.push({ text: item.nombre, value: item.id });
          });
        })
        .catch((error) => {
          console.log(error);
        });
      axios.get("resolucion/list", conf).then((response) => {
        response.data.map((item) => {
          aux.resolucion.push({ text: item.descripcion, value: item.id });
        });
      });
      this.viajeList();
    },
    viajeList() {
      let aux = this;
      let header = { Token: this.$store.state.token };
      let conf = { headers: header };
      var regex = /(\d+)/g;
      this.rol = this.$store.state.user.rol;
      //console.log(this.$store.state.user);
      axios
        .get("usuario/query/" + this.$store.state.user._id, conf)
        .then((data) => {
          let unidad;
          if (this.$store.state.user.rol == "ROLE_ADMIN") {
            aux.unidadModel = 1;
            this.changeUnidad(1);
          } else {
            unidad = data.data.unidad;
            axios
              .get(`viaje/list/${unidad}`, conf)
              .then((response) => {
                aux.viaje = response.data;
                aux.viaje.map((element) => {
                  let tpasaje = 0;
                  element.pasajes.map((item) => {
                    tpasaje = tpasaje + item.total;
                  });
                  element.tpasaje = tpasaje;
                  element.fecha1 =
                    new Date(element.fecha1).toLocaleDateString("en-GB") +
                    " " +
                    new Date(element.fecha1).toLocaleTimeString("en-GB", {
                      hour: "2-digit",
                      minute: "2-digit",
                    });
                  element.fecha2 =
                    new Date(element.fecha2).toLocaleDateString("en-GB") +
                    " " +
                    new Date(element.fecha2).toLocaleTimeString("en-GB", {
                      hour: "2-digit",
                      minute: "2-digit",
                    });
                  element.fecha_informe = new Date(
                    element.fecha_informe
                  ).toLocaleDateString("en-GB");
                  element.nro =
                    element.cite.match(regex)[0] +
                    "-" +
                    element.cite.match(regex)[1];
                  if (element.viaje_internacionals.length > 0) {
                    element.total_viatico = element.total_viatico * 6.97;
                  }
                  if (element.pasajes.length > 0) {
                    /*  if (element.estado == 2) {
                    element.expanded = true;
                  } else {
                    element.expanded = false;
                  } */
                    element.pasajes.map((item) => {
                      if (element.estado == 2) {
                        item.expanded = true;
                      } else {
                        item.expanded = false;
                      }
                      //console.log(item);
                    });
                  }
                });
                //console.log(aux.viaje);
              })
              .catch(function (error) {
                console.log(error);
              });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    camFecha(item) {
      this.solPdf = item;
      console.log(this.solPdf);
      this.dialog4 = true;
    },
    closeCamFecha() {
      this.solPdf = {};
      this.dialog4 = false;
    },
    editItem(item) {
      console.log(item.fecha_informe);
      console.log(new Date().getTime());
      console.log(new Date(item.fecha_informe).getTime());
      /* if (item.pais == 187) {
        this.showLugar = true;
      } else {
        this.showLugar = false;
      } */
      if (item.distancia == 1) {
        this.editedItem.distancia = true;
      } else {
        this.editedItem.distancia = false;
      }
      if (this.$store.state.user.rol == "ROLE_ADMIN")
        this.editedItem.date = new Date(
          item.fecha1.split(" ")[0].split("/").reverse().join("-")
        ).setDate(1);
      else
        this.editedItem.date = new Date(
          item.fecha1.split(" ")[0].split("/").reverse().join("-")
        )
          .toISOString()
          .substring(0, 10);
      this.editedIndex = this.viaje.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.date = new Date(
        item.fecha1.split(" ")[0].split("/").reverse().join("-")
      )
        .toISOString()
        .substring(0, 10);
      this.editedItem.time = item.fecha1.split(" ")[1];
      this.editedItem.date2 = new Date(
        item.fecha2.split(" ")[0].split("/").reverse().join("-")
      )
        .toISOString()
        .substring(0, 10);
      this.editedItem.time2 = item.fecha2.split(" ")[1];
      this.editedItem.radioRow = item.excepcion;
      this.editedItem.radioPoa = item.poa;
      if (item.viaje_internacionals.length > 0) {
        this.editedItem.tv = item.viaje_internacionals[0].region_internacional;
      } else {
        this.editedItem.tv = item.viaje_nacionals[0].region_nacional;
        if (item.viaje_nacionals[0].region_nacional == 2) {
          this.showCheck = true;
        }
      }
      this.dialog = true;
    },

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /* liquidacionList() {
      let aux = this;
      axios
        .get("liquidacion/list")
        .then((response) => {
          aux.pasaje = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    }, */
    editItem1(item) {
      //this.editedIndex3 = this.liquidacion.indexOf(item);
      this.editedItem3.paisComision = item.pais;
      this.editedItem3.lugarli = item.lugar;
      if (item.viaje_nacionals.length > 0) {
        this.editedItem3.tvLi =
          item.viaje_nacionals[0].region_nacional_region_nacional.nombre;
        this.editedItem3.iddeptoComision =
          item.iddepto_departamento.departamento;
      } else {
        this.editedItem3.tvLi =
          item.viaje_internacionals[0].region_internacional_region_internacional.nombre;
      }
      this.editedItem3.motivoViaje = item.motivo;
      this.editedItem3.ruta_viajeLi = item.ruta_viaje;
      this.editedItem3.resolucionLi = item.resolucion;
      this.editedItem3.idunidadLi = item.idunidad;
      this.editedItem3.transporteLi = item.transporte;
      this.editedItem3.facturacion = item.facturacion;
      this.editedItem3.distanciaLi = item.distancia;
      this.editedItem3.financiamientoLi = item.financiamiento;
      this.editedItem3.fecha1li = item.fecha1;
      this.editedItem3.fecha2li = item.fecha2;
      let montoAereo = 0;
      let montoTerr = 0;
      item.pasajes.map((element) => {
        if (element.pas_aereo > 0) {
          montoAereo += element.total;
        }
        if (element.pas_terrestre > 0) {
          montoTerr += element.total;
        }
      });
      this.editedItem3.anticipo = item.anticipo;
      this.editedItem3.pasAereoLi = montoAereo;
      this.editedItem3.pasTerrestreLi = montoTerr;
      /* this.editedItem3.pasAereoLides =
        item.pasajes[0].pas_aereo - item.pasajes[0].pas_aereo * 0.155;
      this.editedItem3.pasTerrestreLides =
        item.pasajes[0].pas_terrestre - item.pasajes[0].pas_terrestre * 0.155; */
      /* this.editedItem3.boletosLi = item.boletos;
      this.editedItem3.facturacion = item.facturacion; */
      this.editedItem3.idviaje = item.id;
      this.editedItem3.idpasajes = item.id;
      this.dialog3 = true;
    },
    close1() {
      this.dialog3 = false;
      this.$nextTick(() => {
        this.viajeList();
        this.editedItem3 = Object.assign({}, this.defaultItem3);
        this.editedIndex3 = -1;
        this.select = Object.assign({}, this.defaultItem3);
        this.showterrestre = false;
        this.showaereo = false;
      });
      this.$refs.form3.reset();
    },
    convString(cad) {
      let palabras = cad.split(" ").map((palabra) => {
        return palabra[0].toUpperCase() + palabra.slice(1).toLowerCase();
      });
      return palabras.join(" ");
    },
    save1() {
      let verif = this.$refs.form3.validate();
      if (verif) {
        let aux = this;
        let header = { Token: this.$store.state.token };
        let conf = { headers: header };
        !this.editedItem3.observaciones
          ? ""
          : this.editedItem3.observaciones.toUpperCase().trim();
        axios
          .post(
            "liquidacion/add",
            {
              num_factura: this.editedItem3.num_factura,
              idfinanciamiento: this.editedItem3.financiamientoLi,
              idunidad: this.editedItem3.idunidadLi,
              idresolucion: this.editedItem3.resolucionLi,
              idtransporte: this.editedItem3.transporteLi,
              observaciones: this.editedItem3.observaciones,
              //reembolso: this.editedItem3.reembolso,
              facturacion: this.editedItem3.facturacion,
              viaje: this.editedItem3.idviaje,
              estado: 4,
              idviaje: this.editedItem3.idviaje,
              userupdate: this.$store.state.user._id,
              usercreate: this.$store.state.user._id,
              num_facturaaereo: this.editedItem3.num_facturaaereo,
              num_facturaterres: this.editedItem3.num_facturaterres,
              facturacionaereo: "reembolso", //this.editedItem3.facturacionaereo
              facturacionterres: "reembolso", //this.editedItem3.facturacionterres||
              anticipo: this.editedItem3.anticipo + "",
            },
            conf
          )
          .then((data) => {
            console.log(data.data);
            this.message = data.data.message;
            this.message = "DATOS REGISTRADOS";
            this.color = "success";
            this.snackbar = true;
            this.close1();
            this.viajeList();
          })
          .catch((error) => {
            console.log(error);
          });
        this.close1();
      }
    },
    changetipote(valor) {
      this.editedItem3.pasTerrestreLi = 0;
      valor != "anticipo"
        ? (this.showterrestre = true)
        : (this.showterrestre = false);
    },
    changetipoae(valor) {
      this.editedItem3.pasAereoLi = 0;
      valor != "anticipo" ? (this.showaereo = true) : (this.showaereo = false);
    },

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.editedItem.date = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substring(0, 10);
        this.editedItem.time = null;
        this.editedItem.date2 = null;
        this.editedItem.time2 = null;
        this.showLugar = false;
        this.showCheck = false;
        //this.showfacte = false;
      });
      this.$refs.form.reset();
    },
    save() {
      let verif = this.$refs.form.validate();
      let fecha1 = new Date(this.editedItem.date + " " + this.editedItem.time);
      let fecha2 = new Date(
        this.editedItem.date2 + " " + this.editedItem.time2
      );
      if (this.editedItem)
        if (verif) {
          let dias = this.calcuDays(
            new Date(this.editedItem.date2 + " " + this.editedItem.time2),
            new Date(this.editedItem.date + " " + this.editedItem.time),
            this.editedItem.resolucion,
            this.editedItem.tv
          );
          this.editedItem.fecha1 =
            this.editedItem.date + " " + this.editedItem.time;
          this.editedItem.fecha2 =
            this.editedItem.date2 + " " + this.editedItem.time2;
          this.editedItem.motivo = this.editedItem.motivo.toUpperCase();
          this.editedItem.lugar = this.editedItem.lugar.toUpperCase();
          this.editedItem.ruta_viaje = this.editedItem.ruta_viaje.toUpperCase();
          let informe = this.fechaEntrega(
            new Date(this.editedItem.date2 + " " + this.editedItem.time2)
          );
          let region = this.editedItem.tv;
          let header = { Token: this.$store.state.token };
          let conf = { headers: header };
          if (this.editedIndex > -1) {
            axios
              .put(
                "viaje/update/" + this.editedItem.id,
                {
                  estado: this.editedItem.estado,
                  comisionado: this.editedItem.comisionado,
                  pais: this.editedItem.pais,
                  ruta_viaje: this.editedItem.ruta_viaje,
                  lugar: this.editedItem.lugar,
                  transporte: this.editedItem.transporte,
                  financiamiento: this.editedItem.financiamiento,
                  fecha1: this.editedItem.fecha1,
                  fecha2: this.editedItem.fecha2,
                  dias: dias,
                  total_viatico: this.editedItem.total_viatico,
                  resolucion: this.editedItem.resolucion,
                  //fuente_financiamiento: ,
                  motivo: this.editedItem.motivo.trim(),
                  //unidad: this.editedItem.unidad,
                  slug: this.editedItem.slug,
                  cite: this.editedItem.cite,
                  representacion: this.editedItem.representacion,
                  viatico: this.editedItem.viatico,
                  fines_semana: this.editedItem.fines_semana,
                  //fecha_modificacion: ,
                  cot_dolar: this.editedItem.cot_dolar,
                  iva: this.editedItem.iva,
                  anticipo: this.editedItem.anticipo,
                  fecha_informe: informe,
                  //fecha_reliquidado: ,
                  //fecha_creacion: ,
                  //idpresupuesto: ,
                  idunidad: this.editedItem.idunidad,
                  iddepto: this.editedItem.iddepto,
                  userupdate: this.$store.state.user._id,
                  distancia: this.editedItem.distancia,
                  region: region,
                  inter: this.showLugar,
                  excepcion: this.editedItem.radioRow,
                  poa: this.editedItem.radioPoa,
                },
                conf
              )
              .then((data) => {
                this.message = data.data.message;
                this.color = "success";
                this.snackbar = true;
                this.close();
                this.viajeList();
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            axios
              .post(
                "viaje/add",
                {
                  //comisionado: ,
                  pais: this.editedItem.pais,
                  ruta_viaje: this.editedItem.ruta_viaje,
                  lugar: this.editedItem.lugar.trim(),
                  transporte: this.editedItem.transporte,
                  financiamiento: this.editedItem.financiamiento,
                  fecha1: this.editedItem.fecha1,
                  fecha2: this.editedItem.fecha2,
                  dias: dias,
                  total_viatico: 0,
                  resolucion: this.editedItem.resolucion,
                  //fuente_financiamiento: ,
                  motivo: this.editedItem.motivo.trim(),
                  //unidad: this.editedItem.unidad,
                  slug: "prueba",
                  cite: "",
                  estado: 1,
                  representacion: 0,
                  viatico: 0,
                  fines_semana: "1",
                  //fecha_modificacion: ,
                  cot_dolar: 6.97,
                  iva: "s",
                  anticipo: "No",
                  fecha_informe: informe,
                  //fecha_reliquidado: ,
                  //fecha_creacion: ,
                  //idpresupuesto: ,
                  idunidad: this.editedItem.idunidad,
                  iddepto: this.editedItem.iddepto,
                  usercreate: this.$store.state.user._id,
                  distancia: this.editedItem.distancia,
                  region: region,
                  inter: this.showLugar,
                  excepcion: this.editedItem.radioRow,
                  poa: this.editedItem.radioPoa,
                },
                conf
              )
              .then((data) => {
                this.message = data.data.message;
                this.color = "success";
                this.snackbar = true;
                this.close();
                this.viajeList();
              })
              .catch((error) => {
                console.log(error);
              });
          }
          this.close();
        }
    },
    calcuDays(fecha1, fecha2, resolucion, tv) {
      /* let d = (fecha1 - fecha2) / (1000 * 60 * 60 * 24);
      if (Math.floor(d) != 0) {
        if (Number.parseFloat(d)) {
          if (d - Math.floor(d) > 0.5) d = Math.round(d);
          else if (Math.round(d) == 0) d = Math.round(d) + 1;
          else d = Math.round(d);
        }
      }
      let c = 0;
      while (fecha2.getTime() <= fecha1.getTime()) {
        //console.log(fecha2.getTime());
        if (resolucion != 3) {
          if (fecha2.getDay() == 0 || fecha2.getDay() == 6) {
            c += 1;
          }
        }
        fecha2.setDate(fecha2.getDate() + 1);
      }
      d = d - c;
      if (d < 1 && d > 0) {
        d = 1;
      } else if (tv == 1) {
        if (fecha1.getHours() == 13 && fecha1.getMinutes() == 0) {
          d = d;
        } else if (
          fecha1.getHours() >= 13 &&
          fecha1.getMinutes() >= 0 &&
          fecha1.getHours() < 19
        ) {
          d = d + 0.5;
        } else if (fecha1.getHours() == 19 && fecha1.getMinutes() == 0) {
          d = d + 0.5;
        } else {
          d = d;
        }
      } else {
        console.log(d, fecha1.getHours(), fecha1.getMinutes());
        if (fecha1.getHours() >= 13 && fecha1.getMinutes() > 0 && d >= 1) {
          d = d - 0.5;
        } else {
          d = d;
        }
      }
      return d; */
      let f1 = new Date(`
        ${fecha1.getMonth() + 1}/
        ${fecha1.getDate()}/${fecha1.getFullYear()}
      `);
      let f2 = new Date(`
        ${fecha2.getMonth() + 1}/
        ${fecha2.getDate()}/
        ${fecha2.getFullYear()}
      `);
      //console.log((f1 - f2) / (1000 * 60 * 60 * 24));
      //console.log((((fecha1 - fecha2) / (1000 * 60 * 60 * 24)) * 10) / 10);
      let d = (f1 - f2) / (1000 * 60 * 60 * 24) + 1;
      console.log(d);
      /* if (Math.floor(d) != 0) {
        if (Number.parseFloat(d)) {
          if (d - Math.floor(d) > 0.5) d = Math.round(d);
          else if (Math.round(d) == 0) d = Math.round(d) + 1;
          else d = Math.round(d);
        }
      } */
      //console.log(d);
      let c = 0;
      while (fecha2.getTime() <= fecha1.getTime()) {
        //console.log(fecha2.getTime());
        if (resolucion != 3) {
          if (fecha2.getDay() == 0 || fecha2.getDay() == 6) {
            c += 1;
          }
        }
        fecha2.setDate(fecha2.getDate() + 1);
      }
      d = d - c;
      //console.log(d, c);

      if (d < 1 && d >= 0) {
        d = 1;
      } else if (tv == 1) {
        if (fecha1.getHours() == 13 && fecha1.getMinutes() == 0 && c == 0) {
          d = d - 1;
        } else if (
          fecha1.getHours() >= 13 &&
          fecha1.getMinutes() >= 0 &&
          fecha1.getHours() < 19 &&
          c == 0
        ) {
          d = d - 0.5;
        } else if (
          fecha1.getHours() == 19 &&
          fecha1.getMinutes() == 0 &&
          c == 0
        ) {
          d = d - 0.5;
        } else if (fecha1.getHours() < 13 && c == 0) {
          //console.log(d);
          d = d - 1;
        }
        if (d < 1) d = 1;
      } else {
        //console.log(d, fecha1.getHours(), fecha1.getMinutes());
        if (fecha1.getHours() >= 13 && d > 1) {
          if (fecha1.getHours() == 13 && fecha1.getMinutes() == 0) d = d - 1;
          else d = d - 0.5;
        } else {
          d = d - 1;
        }
        if (d == 0) d = 1;
      }
      console.log(d);
      return d;
    },
    changeState(valor) {
      if (valor == 187) this.showLugar = true;
      else {
        this.editedItem.lugar = "";
        this.showLugar = false;
      }
    },
    changeUnidad(valor) {
      let header = { Token: this.$store.state.token };
      let conf = { headers: header };
      let aux = this;
      var regex = /(\d+)/g;
      axios
        .get(`viaje/list/${valor}`, conf)
        .then((response) => {
          aux.viaje = response.data;
          aux.viaje.map((element) => {
            let tpasaje = 0;
            element.pasajes.map((item) => {
              tpasaje = tpasaje + item.total;
            });
            element.tpasaje = tpasaje;
            element.fecha1 =
              new Date(element.fecha1).toLocaleDateString("en-GB") +
              " " +
              new Date(element.fecha1).toLocaleTimeString("en-GB", {
                hour: "2-digit",
                minute: "2-digit",
              });
            element.fecha2 =
              new Date(element.fecha2).toLocaleDateString("en-GB") +
              " " +
              new Date(element.fecha2).toLocaleTimeString("en-GB", {
                hour: "2-digit",
                minute: "2-digit",
              });
            element.fecha_informe =
              new Date(element.fecha_informe).toLocaleDateString("en-US") +
              " " +
              new Date(element.fecha_informe).toLocaleTimeString("en-GB", {
                hour: "2-digit",
                minute: "2-digit",
              });
            /* element.fecha_informe = new Date(
              element.fecha_informe
            ).toLocaleDateString("en-GB"); */
            element.nro =
              element.cite.match(regex)[0] + "-" + element.cite.match(regex)[1];
            if (element.viaje_internacionals.length > 0) {
              element.total_viatico = element.total_viatico * 6.97;
            }
            if (element.pasajes.length > 0) {
              /*  if (element.estado == 2) {
                    element.expanded = true;
                  } else {
                    element.expanded = false;
                  } */
              element.pasajes.map((item) => {
                if (element.estado == 2) {
                  item.expanded = true;
                } else {
                  item.expanded = false;
                }
                //console.log(item);
              });
            }
          });
          //console.log(aux.viaje);
        })
        .catch(function (error) {
          console.log(error);
          aux.viaje = [];
        });
    },
    changeState2(valor) {
      let header = { Token: this.$store.state.token };
      let conf = { headers: header };
      let o = "";
      axios
        .get("usuario/query/" + this.$store.state.user._id, conf)
        .then((data) => {
          o = data.data.departamento_trabajo_departamento.nombre;
          this.editedItem.ruta_viaje = o + " - " + valor + " - " + o;
        });
    },
    changeTipo(valor) {
      if (valor == 2) this.showCheck = true;
      else {
        this.showCheck = false;
      }
    },
    changeBoleto(valor) {
      let a = this.reuBoleto.find((data) => data.id == valor);
      this.editedItem2.pasAereo = a.pasaje_pasaje.pas_aereo;
      this.editedItem2.lineaAerea = a.linea_aerea;
      this.editedItem2.reBoleto = a.ticket;
      this.editedItem2.tazaAereo = a.pasaje_pasaje.tazaAereo;
      this.editedItem2.pasTerrestre = 0;
    },
    fechaEntrega(fecha) {
      let i = 0;
      do {
        i++;
        fecha.setDate(fecha.getDate() + 1);
        if (fecha.getDay() == 0 || fecha.getDay() == 6) {
          i--;
        }
      } while (i < 8);
      fecha.setHours(23);
      fecha.setMinutes(59);
      return fecha;
    },
    changeRadio() {
      let a = new Date(Date.now() - new Date().getTimezoneOffset() * 60000);
      if (this.editedItem.radioRow == 1) {
        0;
        a.setDate(a.getDate() + 1);
        this.editedItem.date = a.toISOString().substring(0, 10);
      } else {
        this.editedItem.date = a.toISOString().substring(0, 10);
      }
    },
    addboleto() {
      this.editedItem2.boletoAereo.push({
        id: null,
        reBoleto: null,
        lineaAerea: null,
        pasAereo: null,
      });
    },
    removeboleto(index) {
      if (this.editedItem2.boletoAereo.length <= 1)
        alert("Se require un Boleto");
      else {
        this.editedItem2.delboletoAereo = [];
        this.editedItem2.delboletoAereo.push(
          this.editedItem2.boletoAereo[index]
        );
        this.editedItem2.boletoAereo.splice(index, 1);
      }
      //console.log(this.editedItem2.delboletoAereo);
    },
    addPasaje() {
      this.editedItem2.pasajeTerr.push({
        id: null,
        pasaje: null,
        factura: null,
      });
    },
    delPasaje(index) {
      if (this.editedItem2.pasajeTerr.length <= 1)
        alert("Se requiere minimo un Pasaje Terrestre");
      else this.editedItem2.pasajeTerr.splice(index, 1);
    },
    presupEdit(item) {
      //console.log(item);
      this.boletos = [];
      let header = { Token: this.$store.state.token };
      let conf = { headers: header };
      axios
        .get("aereo/query2/" + item.ruta_viaje, conf)
        .then((res) => {
          console.log(res.data);
          this.swBol = true;
          this.reuBoleto = res.data;
          res.data.map((item) => {
            this.boletos.push({ text: item.ticket, value: item.id });
          });
        })
        .catch((e) => {
          this.swBol = false;
        });
      //this.editedItem2 = Object.assign({}, item);
      this.editedItem2.unidad = this.unidad.find(
        (element) => element.value == item.idunidad
      ).text;
      axios
        .get("presupuesto/query2/" + item.idunidad, conf)
        .then((response) => {
          //console.log(response);
          this.presupuesto = response.data[0];
          this.editedItem2.cite = item.cite;
          this.editedItem2.id = item.id;
          this.editedItem2.periodoIda = item.fecha1;
          this.editedItem2.periodoVuelta = item.fecha2;
          this.editedItem2.viatico = item.dias;
          this.editedItem2.financiamiento = item.total_viatico;
          this.editedItem2.pasaje = this.roundToTwo(
            this.presupuesto.saldo_pasaje
          );
          this.editedItem2.salViatico = this.roundToTwo(
            this.presupuesto.saldo_viaticos
          );
          switch (item.transporte) {
            case 1:
              this.swtr = false;
              this.swtrAereo = true;
              this.editedItem2.pasTerrestre = 0;
              break;
            case 2:
              this.swtr = true;
              this.swtrAereo = false;
              this.editedItem2.reBoleto = "-";
              this.editedItem2.pasAereo = 0;
              break;
            case 3:
              this.swtr = false;
              this.swtrAereo = false;
              this.editedItem2.reBoleto = "-";
              this.editedItem2.pasAereo = 0;
              this.editedItem2.pasTerrestre = 0;
              break;
            case 4:
              this.swtr = false;
              this.swtrAereo = false;
              this.editedItem2.reBoleto = "-";
              this.editedItem2.pasAereo = 0;
              this.editedItem2.pasTerrestre = 0;
              break;
            case 5:
              this.swtr = true;
              this.swtrAereo = true;
              break;
          }
          this.dialog2 = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async presupEdit2(item) {
      console.log(item);
      let viaje = item.id;
      let header = { Token: this.$store.state.token };
      let conf = { headers: header };
      let aereo = [];
      let terrestre = [];
      axios
        .get("presupuesto/query2/" + item.idunidad, conf)
        .then(async (response) => {
          //console.log(response);
          this.presupuesto = response.data[0];
          if (item.transporte == 2 && item.pasajes.length == 0) {
            const res = await axios.post(
              "pasaje/add",
              {
                //total: monto,
                //numero_boleto: this.editedItem2.reBoleto,
                tipo: "TERRESTRE",
                boletoAereo: [],
                viaje: item.id,
                usercreate: this.$store.state.user._id,
                saldo_pasaje: this.presupuesto.saldo_pasaje,
                saldo_viaticos: this.presupuesto.saldo_viaticos,
                pas_terrestre: [
                  {
                    pasaje: 0,
                    factura: "-",
                  },
                ],
                presupuesto: this.presupuesto.id,
                auxBoleto: "",
              },
              conf
            );
            console.log(res.data);
            if (res.status == 200) {
              const r = await axios.get("pasaje/query2/" + item.id, conf);
              if (r.data) {
                console.log(r.data);
                item.pasajes.push({
                  id: r.data.id,
                  pas_terrestre: r.data.pas_terrestre,
                  numero_boleto: r.data.numero_boleto,
                });
              }
            }
          }
          axios.get("pasaje/query2/" + item.id, conf).then(async (data) => {
            //console.log(response);
            this.editedIndex2 = this.viaje.indexOf(item);
            this.editedItem2 = Object.assign({}, item);
            this.editedItem2.unidad = this.unidad.find(
              (element) => element.value == item.idunidad
            ).text;
            this.editedItem2.id = data.data.id;
            this.editedItem2.periodoIda = item.fecha1;
            this.editedItem2.periodoVuelta = item.fecha2;
            this.editedItem2.viatico = item.dias;
            this.editedItem2.financiamiento = item.total_viatico;
            this.editedItem2.pasaje = this.roundToTwo(
              this.presupuesto.saldo_pasaje
            );
            this.editedItem2.salViatico = this.roundToTwo(
              this.presupuesto.saldo_viaticos
            );
            this.editedItem2.boletoAereo = [];
            this.editedItem2.pasajeTerr = [];
            switch (item.transporte) {
              case 1:
                this.swtr = false;
                this.swtrAereo = true;
                item.pasajes.forEach((element) => {
                  this.editedItem2.boletoAereo = [];
                  if (element.pas_aereo > 0) {
                    this.editedItem2.boletoAereo.push({
                      id: element.id,
                      pasAereo: element.pas_aereo,
                      reBoleto: element.numero_boleto,
                      lineaAerea: element.aereo.linea_aerea,
                    });
                  }
                  //this.editedItem2.boletoAereo = [...this.boletos];
                });
                /* this.editedItem2.pasAereo = data.data.pas_aereo;
            this.editedItem2.lineaAerea = data.data.aereo.linea_aerea;
            this.editedItem2.reBoleto = data.data.numero_boleto; */
                break;
              case 2:
                this.swtr = true;
                this.swtrAereo = false;
                item.pasajes.map((element) => {
                  console.log(element);
                  if (element.pas_terrestre >= 0) {
                    this.editedItem2.pasajeTerr.push({
                      id: element.id,
                      pasaje: element.pas_terrestre,
                      factura: element.numero_boleto,
                    });
                  }
                });
                //this.editedItem2.pasTerrestre = data.data.pas_terrestre;
                break;
              case 3:
                this.swtr = false;
                this.swtrAereo = false;
                break;
              case 4:
                this.swtr = true;
                this.swtrAereo = false;
                item.pasajes.forEach((element) => {
                  if (element.pas_terrestre > 0 && element.aereo == null) {
                    this.editedItem2.pasajeTerr.push({
                      id: element.id,
                      pasaje: element.pas_terrestre,
                      factura: element.numero_boleto,
                    });
                  }
                });
                break;
              case 5:
                this.swtr = true;
                this.swtrAereo = true;
                let aux = item.pasajes.find(
                  (element) =>
                    element.pas_aereo == 0 || element.pas_aereo == null
                );
                let aux2 = item.pasajes.find(
                  (element) =>
                    element.pas_terrestre == 0 || element.pas_terrestre == null
                ); /*
                console.log(aux2, aux);
                if (!aux) this.addboleto();
                if (!aux2) this.addPasaje(); */
                //console.log(item.pasajes.length);
                if (item.pasajes.length < 2) {
                  if (item.pasajes[0].aereo != null) {
                    this.addPasaje();
                  } else {
                    this.addboleto();
                  }
                }
                item.pasajes.forEach((element) => {
                  //console.log(element);s
                  if (element.pas_aereo > 0 && element.aereo != null) {
                    this.editedItem2.boletoAereo.push({
                      id: element.id,
                      pasAereo: element.pas_aereo,
                      reBoleto: element.numero_boleto,
                      lineaAerea: element.aereo.linea_aerea,
                    });
                  }
                  if (element.pas_terrestre >= 0 && element.aereo == null) {
                    this.editedItem2.pasajeTerr.push({
                      id: element.id,
                      pasaje: element.pas_terrestre,
                      factura: element.numero_boleto,
                    });
                  }
                });
                break;
            }
            //this.editedItem2.boletoAereo = [...aereo];
            //this.editedItem2.pasajeTerr = [...terrestre];
            this.editedItem2.viaje = viaje;
            //console.log(this.editedItem2.boletoAereo);
            //console.log(this.editedItem2.pasajeTerr);

            //console.log(this.editedItem2.pasajeTerr);
            this.dialog2 = true;
            //console.log(this.editedItem2);
            this.editedItem2.aux =
              this.roundToTwo(this.presupuesto.saldo_pasaje) + data.data.total;
          });
        });
    },
    presupClose() {
      this.dialog2 = false;
      this.$nextTick(() => {
        this.editedIndex2 = -1;
        this.editedItem2 = Object.assign({}, this.defaultItem2);
        this.editedItem2.boletoAereo = [...this.defaultItem2.boletoAereo];
        this.editedItem2.pasajeTerr = [...this.defaultItem2.pasajeTerr];
        this.swtr = false;
        this.swtrAereo = false;
        this.reuBoleto = null;
        this.swBol = false;
      });
      this.$refs.form2.reset();
    },
    savePresup() {
      //console.log(this.editedItem2);
      let verif = this.$refs.form2.validate();
      if (verif) {
        let tipo = "";
        let monto = 0;
        if (this.swtr && this.swtrAereo) {
          tipo = "AEREO - TERRESTRE";
          /* monto = 0;
          this.editedItem2.boletoAereo.map((element) => {
            monto = parseFloat(element.pasAereo) + monto;
          });
          this.editedItem2.pasajeTerr.map((element) => {
            monto = monto + parseFloat(element.pasaje);
          }); */
        } else if (this.swtr) {
          tipo = "TERRESTRE";
          /* monto = 0;
          this.editedItem2.pasajeTerr.map((element) => {
            monto = monto + parseFloat(element.pasaje);
          });
          this.editedItem2.reBoleto = ""; */
        } else if (this.swtrAereo) {
          tipo = "AEREO";
          /* monto = 0;
          this.editedItem2.boletoAereo.map((element) => {
            monto = parseFloat(element.pasAereo) + monto;
          }); */
        } else {
          tipo = "TERRESTRE";
        }
        let header = { Token: this.$store.state.token };
        let conf = { headers: header };
        //console.log(this.editedItem2);
        if (this.editedIndex2 > -1) {
          axios
            .put(
              "pasaje/update/" + this.editedItem2.id,
              {
                //total: this.editedItem2.financiamiento + monto,
                boletoAereo: this.editedItem2.boletoAereo,
                tipo: tipo,
                //lineaAerea: this.editedItem2.lineaAerea,
                //tazaAereo: this.editedItem2.tazaAereo,
                userupdate: this.$store.state.user._id,
                saldo_pasaje: this.editedItem2.pasaje,
                saldo_viaticos: this.editedItem2.financiamiento,
                pas_terrestre: this.editedItem2.pasajeTerr,
                //pas_aereo: this.editedItem2.pasAereo,
                presupuesto: this.presupuesto.id,
                auxBoleto: this.editedItem2.auxBoleto,
                viaje: this.editedItem2.viaje,
              },
              conf
            )
            .then((data) => {
              this.message = data.data.message;
              this.color = "success";
              this.snackbar = true;
              this.presupClose();
              this.viajeList();
            })
            .catch((error) => {
              this.message = "Ocurrio Algun Fallo";
              this.color = "error";
              this.snackbar = true;
              this.presupClose();
              this.viajeList();
              console.log(error);
            });
        } else {
          axios
            .post(
              "pasaje/add",
              {
                //total: monto,
                //numero_boleto: this.editedItem2.reBoleto,
                tipo: tipo,
                boletoAereo: this.editedItem2.boletoAereo,
                viaje: this.editedItem2.id /*
                lineaAerea: this.editedItem2.lineaAerea,
                tazaAereo: this.editedItem2.tazaAereo, */,
                usercreate: this.$store.state.user._id,
                saldo_pasaje: this.editedItem2.pasaje,
                saldo_viaticos:
                  this.editedItem2.salViatico - this.editedItem2.financiamiento,
                pas_terrestre: this.editedItem2.pasajeTerr,
                /* pas_aereo: this.editedItem2.pasAereo, */
                presupuesto: this.presupuesto.id,
                auxBoleto: this.editedItem2.auxBoleto,
              },
              conf
            )
            .then((data) => {
              this.message = data.data.message;
              this.color = "success";
              this.snackbar = true;
              this.presupClose();
              this.viajeList();
            })
            .catch((error) => {
              this.message = "Ocurrio Algun Fallo";
              this.color = "error";
              this.snackbar = true;
              this.presupClose();
              this.viajeList();
              console.log(error);
            });
        }
      }
    },
    roundToTwo(num) {
      return +(Math.round(num + "e+2") + "e-2");
    },
    cancelViaje(item) {
      this.editedIndex2 = this.viaje.indexOf(item);
      this.editedItem2 = Object.assign({}, item);
      this.dialogCancel = true;
    },
    closeCancelViaje() {
      this.dialogCancel = false;
      this.$nextTick(() => {
        this.editedItem2 = Object.assign({}, this.defaultItem2);
        this.editedIndex2 = -1;
        this.select = Object.assign({}, this.defaultItem2);
        this.swBol = false;
      });
    },
    cancelViajeConfirm() {
      let header = { Token: this.$store.state.token };
      let conf = { headers: header };
      if (this.editedItem2.estado == 1 || this.editedItem2.estado == 2) {
        axios
          .put(
            "viaje/updateEstado/" + this.editedItem2.id,
            {
              userupdate: this.$store.state.user._id,
              idunidad: this.editedItem2.idunidad,
              total_viatico: this.editedItem2.total_viatico,
              obs: this.motivo,
              estado: 5,
            },
            conf
          )
          .then((data) => {
            this.message = data.data.message;
            this.color = "success";
            this.snackbar = true;
            this.motivo = "";
            this.viajeList();
            this.closeCancelViaje();
          })
          .catch((error) => {
            this.message = "Ocurrio Algun Fallo";
            this.color = "error";
            this.snackbar = true;
            this.viajeList();
            this.closeCancelViaje();
          });
      } else {
        this.message = "Ocurrio un error";
        this.color = "error";
        this.snackbar = true;
        this.viajeList();
        this.closeCancelViaje();
      }
    },
    changeEdit(item) {
      let header = { Token: this.$store.state.token };
      let conf = { headers: header };
      let fecha = new Date();
      fecha.setMinutes(fecha.getMinutes() + 15);
      axios
        .put("viaje/updateEdit/" + item.id, { actual: fecha }, conf)
        .then((response) => {
          this.viajeList();
          this.message = response.data.message;
          this.color = "success";
          this.snackbar = true;
        })
        .catch((error) => {
          this.message = error.response.data.message;
          this.color = "error";
          this.snackbar = true;
          this.viajeList();
        });
    },
    listTicket() {
      let header = { Token: this.$store.state.token };
      let conf = { header: header };
      axios.get();
    },
    anular(item) {
      console.log(item);
    },
    liberar(item) {
      console.log(item);
    },
    sumField(key, pasajes) {
      return pasajes.reduce((a, b) => a + (b[key] || 0), 0);
    },
    bajarPDF(item) {
      let header = { Token: this.$store.state.token };
      let conf = { headers: header };
      axios.get("viaje/filedownload/" + item.id, conf).then((response) => {
        let link = document.createElement("a");
        link.download = "INF-MEMO-" + item.cite.replaceAll("/", "-") + ".pdf";
        link.href =
          "data:application/octet-stream;base64," + response.data.archivo;
        link.target = "_blank";
        link.click();
      });
    },
    rechazar(item) {
      let header = { Token: this.$store.state.token };
      let conf = { headers: header };
      axios
        .put(
          "viaje/updateEstado2/" + item.id,
          {
            userupdate: this.$store.state.user._id,
            estado: 2,
          },
          conf
        )
        .then((data) => {
          this.message = data.data.message;
          this.color = "success";
          this.snackbar = true;
          this.viajeList();
          this.closeCancelViaje();
        })
        .catch((error) => {
          this.message = "Ocurrio Algun Fallo";
          this.color = "error";
          this.snackbar = true;
          this.viajeList();
          this.viajeList();
          this.closeCancelViaje();
        });
    },

    async crearPDF(item) {
      //var fechaActual = new Date().toLocaleDateString();
      let fechaActual = this.date.split("-").reverse().join("/");
      var doc = new jsPDF("p", "mm", "letter");
      /* doc.addFileToVFS("verdana-normal.ttf", verdana);
      doc.addFileToVFS("verdana-bold-bold.ttf", verdanaBold);
      doc.addFont("verdana-normal.ttf", "verdana", "normal");
      doc.addFont("verdana-bold-bold.ttf", "verdana", "bold"); */
      let width = doc.internal.pageSize.getWidth();
      let height = doc.internal.pageSize.getHeight();
      let logoaev = new Image();
      logoaev.src = logo2;
      doc.addImage(logoaev, "JPEG", 0, 0, width, height);
      doc.setFontSize(10);
      doc.setFont("helvetica", "bold");
      doc.setLineWidth(0.1);
      doc.text("AUTORIZACIÓN DE SOLICITUD DE PASAJES Y VIATICOS", 58, 38);
      doc.line(58, 38.5, 155, 38.5);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(8);
      doc.text(fechaActual + " - " + item.cite, 15, 48);
      let nro =
        item.cite.match(/(\d+)/g)[0] + "-" + item.cite.match(/(\d+)/g)[1];
      doc.text("AUTORIZACION DE VIAJE N°.:  " + nro, 145, 48);
      let data = `${item.comisionado_comisionado.usuario_usuario.nombre}||${item.dias}||${fechaActual}||${item.cite}`;
      let qr = await QRCode.toDataURL(data);
      doc.addImage(qr, "JPEG", 181, 23, 20, 20);
      doc.setFontSize(6);
      doc.text("PYV - 001", 187, 43);
      doc.rect(15, 49, 186, 195);
      doc.setDrawColor(0);
      doc.setFillColor(238, 237, 237);
      doc.rect(15.7, 49.6, 184.7, 5, "FD");
      doc.setFontSize(8);
      doc.text("DATOS DEL COMISIONADO", 87, 53);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(8);
      doc.rect(15.7, 55.5, 184.7, 21);
      doc.setFont("helvetica", "bold");
      doc.text("NOMBRE DEL COMISIONADO:", 16.5, 59);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);
      doc.text(item.comisionado_comisionado.usuario_usuario.nombre, 60.5, 59);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(8);
      doc.text("C.I.:", 148.5, 59);
      doc.setFontSize(7);
      doc.setFont("helvetica", "normal");
      doc.text(item.comisionado_comisionado.usuario_usuario.ci, 155.5, 59);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(8);
      doc.text("OFICINA:", 16.5, 64);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);
      doc.text(
        item.comisionado_comisionado.usuario_usuario.oficina_oficina.nombre,
        60.5,
        64
      );
      /* doc.setFont("helvetica", "bold");
      doc.setFontSize(8);
      doc.text("CELULAR:", 140.5, 58);
      doc.setFontSize(7);
      doc.setFont("helvetica", "normal");
      doc.text(
        String(item.comisionado_comisionado.usuario_usuario.celular || ""),
        155.5,
        58
      ); */
      doc.setFont("helvetica", "bold");
      doc.setFontSize(8);
      doc.text("CARGO:", 16.5, 69);
      doc.setFont("helvetica", "normal");
      doc.text(item.comisionado_comisionado.usuario_usuario.cargo, 60.5, 69);
      doc.setFont("helvetica", "bold");
      doc.text("CATEGORIA:", 16.5, 74);
      doc.setFont("helvetica", "normal");
      doc.text(
        String(item.comisionado_comisionado.usuario_usuario.categoria),
        60.5,
        74
      );
      doc.setFont("helvetica", "bold");
      doc.text("MOTIVO:", 16.5, 81);
      var parrafoMotivo = doc.splitTextToSize(item.motivo, 165);
      doc.setFont("helvetica", "normal");
      doc.text(parrafoMotivo, 35.5, 81);
      doc.rect(15.7, 76.5, 184.7, 18);
      doc.setDrawColor(0);
      doc.setFillColor(238, 237, 237);
      doc.rect(15.7, 94.5, 184.7, 5, "FD");
      doc.setFont("helvetica", "bold");
      doc.text("ITINERARIO", 98, 97.6);
      doc.rect(15.7, 99.5, 99.3, 22);
      doc.setFont("helvetica", "bold");
      doc.text("RUTA DE VIAJE:", 16.5, 104);
      doc.setFont("helvetica", "normal");
      var ruta = doc.splitTextToSize(item.ruta_viaje, 65);
      doc.text(ruta, 45.5, 102.5);
      doc.setFont("helvetica", "bold");
      doc.text("PAÍS:", 16.5, 111);
      doc.setFont("helvetica", "normal");
      doc.text(item.pais_pai.nombre.toUpperCase(), 45.5, 111);
      let lugar = doc.splitTextToSize(item.lugar, 65);
      if (item.pais_pai.nombre == "Bolivia") {
        doc.setFont("helvetica", "bold");
        doc.text("DEPARTAMENTO:", 16.5, 114);
        doc.setFont("helvetica", "normal");
        doc.text(
          item.iddepto_departamento.departamento.toUpperCase(),
          45.5,
          114
        );
        doc.setFont("helvetica", "bold");
        doc.text("LUGAR:", 16.5, 119);
        doc.setFont("helvetica", "normal");
        doc.text(lugar, 45.5, 119);
      } else {
        doc.setFont("helvetica", "bold");
        doc.text("LUGAR:", 16.5, 114);
        doc.setFont("helvetica", "normal");
        doc.text(lugar, 45.5, 114);
      }
      doc.rect(15.7, 121.5, 49.65, 5);
      doc.setFont("helvetica", "bold");
      doc.text("FECHA Y HORA DE SALIDA", 22.7, 124.7);
      doc.setFont("helvetica", "normal");
      doc.text(
        `${item.fecha1.split(" ")[0].replaceAll("/", "-")} ${
          item.fecha1.split(" ")[1]
        }`,
        40.7,
        131.5,
        null,
        null,
        "center"
      );
      doc.rect(65.35, 121.5, 49.65, 5);
      doc.setFont("helvetica", "bold");
      doc.text("FECHA Y HORA DE RETORNO", 69.7, 124.7);
      doc.setFont("helvetica", "normal");
      doc.text(
        `${item.fecha2.split(" ")[0].replaceAll("/", "-")} ${
          item.fecha2.split(" ")[1]
        }`,
        89.7,
        131.5,
        null,
        null,
        "center"
      );
      doc.rect(15.7, 126.5, 49.65, 10);
      doc.rect(65.35, 126.5, 49.65, 10);
      doc.rect(115.1, 99.5, 85.3, 22);
      doc.setFont("helvetica", "bold");
      doc.text("MEDIO DE TRANSPORTE:", 116.2, 104);
      doc.setFont("helvetica", "normal");
      let transporte = doc.splitTextToSize(
        item.transporte_transporte.nombre,
        45
      );
      doc.text(transporte, 153.2, 104);
      doc.setFont("helvetica", "bold");
      doc.text("TIPO DE VIAJE:", 116.2, 111);
      doc.setFont("helvetica", "normal");
      if (item.viaje_nacionals.length > 0) {
        doc.text(
          item.viaje_nacionals[0].region_nacional_region_nacional.nombre,
          153.2,
          111
        );
      } else if (item.viaje_internacionals.length > 0) {
        let parrafoTipo = doc.splitTextToSize(
          item.viaje_internacionals[0]
            .region_rectinternacional_region_internacional.nombre,
          50
        );
        doc.text(parrafoTipo, 153.2, 108.5);
      }
      doc.setFont("helvetica", "bold");
      doc.text("FINANCIAMIENTO:", 116.2, 119);
      doc.setFont("helvetica", "normal");
      doc.text(item.financiamiento_financiamiento.nombre, 153.2, 119);
      doc.rect(115.1, 121.5, 85.3, 15);
      doc.setFont("helvetica", "bold");
      doc.text("TOTAL DIAS:", 116.2, 127);
      doc.setFont("helvetica", "normal");
      doc.text(String(item.dias), 140.2, 127);
      doc.setFont("helvetica", "bold");
      doc.text("RESOLUCION:", 116.2, 132.5);
      doc.setFont("helvetica", "normal");
      let parrafoResolucion = doc.splitTextToSize(
        item.resolucion_resolucion.descripcion,
        60
      );
      doc.text(parrafoResolucion, 140.2, 132.5);
      doc.setDrawColor(0);
      doc.setFillColor(238, 237, 237);
      doc.rect(15.7, 136.5, 184.7, 5, "FD");
      doc.setFont("helvetica", "bold");
      doc.text("FINANCIAMIENTO", 93, 140);
      doc.rect(15.7, 141.5, 99.3, 7);
      doc.setFont("helvetica", "bold");
      doc.text("PROGRAMA:", 16.5, 145.7);
      doc.setFont("helvetica", "normal");
      let programa = doc.splitTextToSize(
        item.idunidad_unidad.fuente + " - " + item.idunidad_unidad.descripcion,
        80
      );
      doc.text(programa, 40.5, 144.7);
      doc.setFont("helvetica", "bold");
      doc.text("FUENTE:", 116.2, 145.7);
      doc.setFont("helvetica", "normal");
      doc.text(String(item.idunidad_unidad.fuente), 130.2, 145.7);
      doc.rect(115.1, 141.5, 85.3, 7);
      doc.setFont("helvetica", "bold");
      doc.text("PLAZO DE PRESENTACIÓN DE INFORME: ", 16.5, 152);
      doc.setFont("helvetica", "normal");
      doc.text(`${item.fecha_informe.replaceAll("/", "-")}`, 74.5, 152);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);
      doc.text(
        'El incumplimiento dará lugar a la aplicación del Art. 25 del "Reglamento Interno de Pasajes y Viáticos".',
        16.5,
        155.8
      );
      doc.rect(15.7, 148.5, 184.7, 9);
      doc.rect(15.7, 157.5, 184.7, 9);
      doc.rect(15.7, 166.5, 184.7, 33);
      doc.setFont("helvetica", "bold");
      doc.text("SE ENCUENTRA EN EL POA DEL AREA.:", 18.5, 162.8);
      doc.setDrawColor(0);
      doc.setFillColor(255, 255, 255);
      doc.roundedRect(70, 160.1, 5, 4, 2, 2, "FD");
      doc.setFontSize(6);
      doc.setFont("helvetica", "normal");
      doc.text("Si", 76, 162.8);
      doc.setDrawColor(0);
      doc.setFillColor(255, 255, 255);
      doc.roundedRect(79, 160.1, 5, 4, 2, 2, "FD");
      doc.setFontSize(6);
      doc.setFont("helvetica", "normal");
      doc.text("No", 85, 162.8);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(9);
      if (item.poa == 1) {
        doc.text("X", 71.5, 163.3);
      } else {
        doc.text("X", 80.5, 162.3);
      }
      doc.setFontSize(7);
      doc.setFont("helvetica", "bold");
      doc.text("VIAJES POR.:", 108.5, 162.8);
      doc.setFont("helvetica", "bold");
      doc.setDrawColor(0);
      doc.setFillColor(255, 255, 255);
      doc.roundedRect(125.8, 160.1, 4, 4, 2, 2, "FD");
      doc.setFont("helvetica", "normal");
      doc.setFontSize(6);
      doc.text("Excepción - (ART. 14 RPV)", 130.5, 162.8);
      doc.setDrawColor(0);
      doc.setFillColor(255, 255, 255);
      doc.roundedRect(157.5, 160.1, 4, 4, 2, 2, "FD");
      doc.setFont("helvetica", "normal");
      doc.setFontSize(6);
      doc.text("Extraordinario - (Instrucción MAE)", 162.5, 162.8);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(9);
      if (item.excepcion == 2) {
        doc.text("X", 126.8, 163.3);
      } else if (item.excepcion == 3) {
        doc.text("X", 158.5, 163.3);
      }
      doc.setFont("helvetica", "bold");
      doc.setFontSize(7);
      doc.text("SE DEBE TOMAR EN CUENTA QUE:", 16.5, 173.7);
      doc.line(16.5, 171.3, 58.8, 171.3);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(6);
      doc.text(
        "1.- Este formulario debe presentarse debidamente firmado por el comisionado y por la autoridad que aprueba el viaje en los plazos establecidos en el Reglamento de Pasajes y Viáticos.",
        18.5,
        176.5
      );
      doc.text(
        "2.- Para la suspensión de un viaje, se deberá presentar una nota de justificación con un plazo no menor a las 24 horas antes del viaje, salvo excepciones.",
        18.5,
        178.7
      );
      doc.setFontSize(7);
      doc.setFont("helvetica", "bold");
      doc.line(16.5, 184.3, 37, 184.3);
      doc.text("ACLARACIONES", 16.5, 183.7);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(6);
      let parrafoAclaracion = doc.splitTextToSize(
        "En los viajes INTERDEPARTAMENTALES, cuando el retorno de una comisión que incluya pernocte, sea efectuado entre horas 13:00 y 19:00 corresponderá el pago al equivalente del 50% de un día de viático por el día de retorno y, el 100% de un día viático, cuando el retorno sea realizado con posterioridad a las 19:00 horas, considerando la hora señalada en el pasaje terrestre o pase abordo, según corresponda, conforme al Reglamento de Pasajes y Viaticos, Artículo 31.\nCuando los viajes sean de carácter INTRADEPARTAMENTAL y la distancia del viaje sea menor a 100 kilómetros de la sede de funciones del comisionado, se pagará un estipendio de Bs. 50; Cuando esta distancia sea mayor a los 100 km se pagará el equivalente al 40% de un día de viático de acuerdo a escala establecida en el presente Reglamento.",
        180
      );
      doc.text(parrafoAclaracion, 17.5, 187.7);
      doc.rect(15.7, 180.5, 184.7, 62.8);
      let comisionado = doc.splitTextToSize(
        item.comisionado_comisionado.usuario_usuario.nombre +
          "\n" +
          item.comisionado_comisionado.usuario_usuario.cargo,
        60
      );
      doc.setFont("helvetica", "italic");
      doc.setFontSize(8);
      doc.line(35.5, 234, 103.5, 234);
      doc.text(comisionado, 68.5, 237, { align: "center" });
      doc.line(122.5, 234, 190, 234);
      doc.text("FIRMA Y SELLO DE APROBACION", 155.5, 237, { align: "center" });
      /* doc.setFont("helvetica", "italic");
      doc.setFontSize(5);
      doc.text("PAGINA 1 - SOLICITUD DE VIAJE", 173.5, 260);
      doc.text(
        "Calle Fernando Guachalla No 411 Esq 20 de Octubre - Teléfonos: (591) - 2 - 2147767 - 2148747",
        108.5,
        269,
        { align: "center" }
      );
      doc.line(16, 267, 201, 267); */
      //doc.output("dataurlnewwindow", { filename: "Formulario-Viaje" });
      //doc.save("Formulario-viaje.pdf");
      window.open(doc.output("bloburl", { filename: "Solicitud-Viaje" }));
      this.closeCamFecha();
      //doc.save("Formulario-viaje.pdf");
    },
    async crearPresupPDF(item) {
      //console.log(item);
      let fechaActual = new Date().toLocaleDateString("en-GB");
      let a;
      let monto = 0;
      let montoUnico = 0;
      let montoPas = 0;
      await item.pasajes.map((element) => {
        montoPas = montoPas + element.total;
      });
      if (item.viaje_nacionals.length > 0) {
        a = item.viaje_nacionals[0].region_nacional_region_nacional.nombre;
        if (item.viaje_nacionals[0].region_nacional == 2) {
          if (item.distancia == 1) {
            montoUnico = 50;
          } else {
            if (item.dias == 1)
              montoUnico =
                item.viaje_nacionals[0].region_nacional_region_nacional
                  .cat_ints[0].monto * 0.4;
            else
              montoUnico =
                item.viaje_nacionals[0].region_nacional_region_nacional
                  .cat_ints[0].monto;
          }
        } else {
          montoUnico =
            item.viaje_nacionals[0].region_nacional_region_nacional.cat_ints[0]
              .monto;
        }
        monto = item.total_viatico;
      } else if (item.viaje_internacionals.length > 0) {
        a =
          item.viaje_internacionals[0].region_internacional_region_internacional
            .nombre;
        monto = item.total_viatico * 6.97;
        montoUnico =
          item.viaje_internacionals[0].region_internacional_region_internacional
            .cat_exts[0].monto * 6.97;
      }
      let doc = new jsPDF("p", "mm", "letter");
      let width = doc.internal.pageSize.getWidth();
      let height = doc.internal.pageSize.getHeight();
      let logoaev = new Image();
      logoaev.src = logo2;
      doc.setFont("helvetica", "bold");
      doc.setFontSize(8);
      doc.addImage(logoaev, "JPEG", 0, 0, width, height);
      let data = `${item.comisionado_comisionado.usuario_usuario.nombre}||${item.dias}||${fechaActual}||${item.cite}`;
      let qr = await QRCode.toDataURL(data);
      doc.addImage(qr, "JPEG", 182, 22, 20, 20);
      doc.text("PYV - 003", 185, 43);
      doc.setFontSize(11);
      doc.setFont("helvetica", "bold");
      doc.setLineWidth(0.2);
      let titulo = doc.splitTextToSize(
        "DIRECCIÓN NACIONAL ADMINISTRATIVA FINANCIERA\nPROVISIÓN DE PASAJES Y VIÁTICOS"
      );
      doc.text(titulo, 118, 38, { align: "center" });
      doc.text("CERTIFICACIÓN PRESUPUESTARIA", 83, 142);
      doc.line(83, 142.5, 150.5, 142.5);
      doc.line(67, 38.5, 170, 38.5);
      doc.line(83, 43.2, 153.5, 43.2);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);
      doc.text("(Expresado en Bolivianos)", 102, 46);
      doc.setFontSize(11);
      doc.text("FIRMA Y SELLO ENCARGADO DE PASAJE Y VIÁTICOS", 68.5, 133);
      doc.line(66.5, 129.5, 170.5, 129.5);
      doc.text("FIRMA Y SELLO ENCARGADO DE PRESUPUESTO", 74.5, 230);
      doc.line(72.5, 226.5, 167.5, 226.5);
      doc.setFontSize(7);
      doc.text("(Expresado en Bolivianos)", 103.5, 145.5);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(8);
      let nro =
        item.cite.match(/(\d+)/g)[0] + "-" + item.cite.match(/(\d+)/g)[1];
      doc.text("AUTORIZACIÓN DE VIAJE N°: " + nro, 147, 148.5);
      doc.setFont("helvetica", "italic");
      doc.setFontSize(7);
      doc.text(
        "EL ENCARGADO DE PRESUPUESTO CERTIFICA LA EXISTENCIA DE PRESUPUESTO SUFUCIENTE EN LAS PARTIDAS Y FUENTES SEÑALADAS.",
        15,
        182.5
      );
      //doc.line(16, 254, 202.5, 254);
      //doc.text("PÁGINA 2 - CERTIFICACIÓN PRESUPUESTARIA", 153, 253);

      let table = [
        ["TOTAL DÍAS: ", item.dias],
        [
          "TOTAL POR DÍA: ",
          new Intl.NumberFormat("es-BO", {
            minimumFractionDigits: 2,
          }).format(montoUnico),
        ],
        [
          "TOTAL VIÁTICOS: ",
          new Intl.NumberFormat("es-BO", {
            minimumFractionDigits: 2,
          }).format(monto),
        ],
        [
          "RC - IVA (13%): ",
          new Intl.NumberFormat("es-BO", {
            minimumFractionDigits: 2,
          }).format(0),
        ],
        [
          "TASA AEROPUERTARIA: ",
          new Intl.NumberFormat("es-BO", {
            minimumFractionDigits: 2,
          }).format(0) ||
            new Intl.NumberFormat("es-BO", {
              minimumFractionDigits: 2,
            }).format(0),
        ],
        [
          "TOTAL LIQUIDO PAGABLE VIÁTICO: ",
          {
            content: new Intl.NumberFormat("es-BO", {
              minimumFractionDigits: 2,
            }).format(item.total_viatico),
            styles: { halign: "right", fontStyle: "bold" },
          },
        ],
      ];
      doc.autoTable({
        head: [
          [
            {
              content: "DETALLE DE LA LIQUIDACIÓN DE VIÁTICOS",
              colSpan: 2,
              styles: { halign: "center", color: [255, 255, 255] },
            },
          ],
        ],
        body: table,
        margin: { top: 47, left: 45 },
        columnStyles: { 1: { cellWidth: 65, halign: "right" } },
        tableWidth: 140,
        styles: {
          lineWidth: 0.25,
          lineColor: 0,
          textColor: 50,
          cellPadding: 0.8,
          fontSize: 8,
        },
        headStyles: {
          fillColor: [255, 255, 255],
          fontSize: 9,
        },
        theme: "plain",
      });
      let table2 = [
        [
          "FUENTE",
          "DETALLE",
          "PARTIDA PRESUP.",
          "PRESUP. VIGENTE",
          "EJECUTADO",
          "REQUERIDO",
          "SALDO",
        ],

        [
          item.idunidad_unidad.fuente,
          "PASAJE " + a,
          {
            content: item.idunidad_unidad.presupuestos[0].partida_pas,
            styles: { halign: "right" },
          },
          {
            content: new Intl.NumberFormat("es-BO", {
              minimumFractionDigits: 2,
            }).format(item.idunidad_unidad.presupuestos[0].monto_pre_pasaje),
            styles: { halign: "right" },
          },
          {
            content: new Intl.NumberFormat("es-BO", {
              minimumFractionDigits: 2,
            }).format(
              item.idunidad_unidad.presupuestos[0].monto_pre_pasaje -
                item.idunidad_unidad.presupuestos[0].saldo_pasaje -
                montoPas
            ),
            styles: { halign: "right" },
          },
          {
            content: new Intl.NumberFormat("es-BO", {
              minimumFractionDigits: 2,
            }).format(montoPas),
            styles: { halign: "right" },
          },
          {
            content:
              new Intl.NumberFormat("es-BO", {
                minimumFractionDigits: 2,
              }).format(item.idunidad_unidad.presupuestos[0].saldo_pasaje) ||
              new Intl.NumberFormat("es-BO", {
                minimumFractionDigits: 2,
              }).format(0),
            styles: { halign: "right" },
          },
        ],
        [
          item.idunidad_unidad.fuente,
          "VIÁTICOS " + a,
          {
            content: item.idunidad_unidad.presupuestos[0].partida_via,
            styles: { halign: "right" },
          },
          {
            content: new Intl.NumberFormat("es-BO", {
              minimumFractionDigits: 2,
            }).format(item.idunidad_unidad.presupuestos[0].monto_pre_viaticos),
            styles: { halign: "right" },
          },
          {
            content: new Intl.NumberFormat("es-BO", {
              minimumFractionDigits: 2,
            }).format(
              item.idunidad_unidad.presupuestos[0].monto_pre_viaticos -
                item.idunidad_unidad.presupuestos[0].saldo_viaticos -
                item.total_viatico
            ),
            styles: { halign: "right" },
          },
          {
            content: new Intl.NumberFormat("es-BO", {
              minimumFractionDigits: 2,
            }).format(item.total_viatico),
            styles: { halign: "right" },
          },
          {
            content:
              new Intl.NumberFormat("es-BO", {
                minimumFractionDigits: 2,
              }).format(item.idunidad_unidad.presupuestos[0].saldo_viaticos) ||
              new Intl.NumberFormat("es-BO", {
                minimumFractionDigits: 2,
              }).format(0),
            styles: { halign: "right" },
          },
        ],
        [
          {
            content: "TOTAL PASAJES:  ",
            colSpan: 5,
            styles: { halign: "right", fontStyle: "bold" },
          },
          {
            content:
              new Intl.NumberFormat("es-BO", {
                minimumFractionDigits: 2,
              }).format(montoPas) ||
              new Intl.NumberFormat("es-BO", {
                minimumFractionDigits: 2,
              }).format(0),
            colSpan: 2,
            styles: { halign: "center", fontStyle: "bold" },
          },
        ],
        [
          {
            content: "TOTAL VIÄTICOS:  ",
            colSpan: 5,
            styles: { halign: "right", fontStyle: "bold" },
          },
          {
            content: new Intl.NumberFormat("es-BO", {
              minimumFractionDigits: 2,
            }).format(item.total_viatico),
            colSpan: 2,
            styles: { halign: "center", fontStyle: "bold" },
          },
        ],
      ];
      let finalY = doc.lastAutoTable.finalY;
      doc.autoTable({
        startY: finalY + 68,
        head: [
          [
            {
              content: "DETALLE DE LA CERTIFICACIÓN PRESUPUESTARIA",
              colSpan: 7,
              styles: { halign: "center", color: [255, 255, 255] },
            },
          ],
        ],
        body: table2,
        styles: {
          lineWidth: 0.25,
          lineColor: 0,
          textColor: 50,
          cellPadding: 0.8,
          fontSize: 8,
          halign: "center",
        },
        headStyles: {
          fillColor: [255, 255, 255],
          fontSize: 9,
        },
        theme: "plain",
      });
      /* doc.output("dataurlnewwindow", {
        filename: "Certificado-Presupuesto.pdf",
      }); */
      doc.setProperties({
        title: "Certificado de Presupuesto ",
      });
      window.open(doc.output("bloburl", "Certificado_de_Presupuesto.pdf"));
      //doc.output("pdfobjectnewwindow", "Certificado-Presupuesto.pdf");
      //doc.save("Certificado-Presupuesto.pdf");
    },
    async crearLiquiPDF(item) {
      console.log(item);
      let header = { Token: this.$store.state.token };
      let conf = { header: header };
      let remAereo = 0;
      let antAereo = 0;
      let remTerr = 0;
      let antTerr = 0;
      let antViatico = 0;
      let remViatico = 0;
      await axios.get("liquidacion/query/" + item.id, conf).then((resp) => {
        let calt = 0;
        console.log(resp.data);
        if (resp.data.facturacionaereo == "reembolso") {
          item.pasajes.map((element) => {
            if (element.aereo) {
              if (element.aereo.linea_aerea != 1)
                remAereo = remAereo + element.pas_aereo;
              else if (
                resp.data.idunidad != 1 &&
                resp.data.idunidad != 11 &&
                resp.data.idunidad != 12
              )
                remAereo = remAereo + element.pas_aereo;
            }
          });
        } else {
          item.pasajes.map((element) => {
            if (element.aereo) {
              if (element.aereo.linea_aerea != 1)
                antAereo = antAereo + element.pas_aereo;
            }
          });
        }
        if (resp.data.facturacionterres == "reembolso") {
          item.pasajes.map((element) => {
            if (element.pas_terrestre > 0) {
              remTerr = remTerr + element.pas_terrestre;
            }
          });
        } else {
          item.pasajes.map((element) => {
            if (element.pas_terrestre > 0) {
              antTerr = antTerr + element.pas_terrestre;
            }
          });
        }
        if (resp.data.viaje_viaje.anticipo == "SI") {
          antViatico = resp.data.viaje_viaje.total_viatico;
        } else {
          remViatico = resp.data.viaje_viaje.total_viatico;
        }
      });
      let fechaActual = new Date().toLocaleDateString("en-GB");
      let nro =
        item.cite.match(/(\d+)/g)[0] + "-" + item.cite.match(/(\d+)/g)[1];
      let montoUnico = 0;
      let montoAd = 0;
      let fecha1 = new Date(
        item.fecha1.split(" ")[0].split("/").reverse().join("-")
      )
        .toISOString()
        .substring(0, 10)
        .split("-")
        .reverse()
        .join("/");
      let hora1 = item.fecha1.split(" ")[1].substring(0, 5);
      let fecha2 = new Date(
        item.fecha2.split(" ")[0].split("/").reverse().join("-")
      )
        .toISOString()
        .substring(0, 10)
        .split("-")
        .reverse()
        .join("/");
      let hora2 = item.fecha2.split(" ")[1].substring(0, 5);
      let h = new Date(
        item.fecha2.split(" ")[0].split("/").reverse().join("-") +
          " " +
          item.fecha2.split(" ")[1]
      ).getHours();
      let m = new Date(
        item.fecha2.split(" ")[0].split("/").reverse().join("-") +
          " " +
          item.fecha2.split(" ")[1]
      ).getMinutes();
      if (item.viaje_nacionals.length > 0) {
        if (item.viaje_nacionals[0].region_nacional == 2) {
          if (item.distancia == 1) {
            montoUnico = 50;
            if (item.dias > 1 && h >= 13 && m >= 0) {
              montoAd = montoUnico * 0.5;
            }
          } else {
            if (item.dias == 1)
              montoUnico =
                item.viaje_nacionals[0].region_nacional_region_nacional
                  .cat_ints[0].monto * 0.4;
            else
              montoUnico =
                item.viaje_nacionals[0].region_nacional_region_nacional
                  .cat_ints[0].monto;
            if (item.dias > 1 && h >= 13 && m >= 0) {
              montoAd = montoUnico * 0.5;
            }
          }
        } else {
          montoUnico =
            item.viaje_nacionals[0].region_nacional_region_nacional.cat_ints[0]
              .monto;
          if (item.dias > 1 && h >= 13 && h < 19 && m >= 0) {
            montoAd = montoUnico * 0.5;
          }
        }
      } else if (item.viaje_internacionals.length > 0) {
        monto = item.total_viatico * 6.97;
        montoUnico =
          item.viaje_internacionals[0].region_internacional_region_internacional
            .cat_exts[0].monto * 6.97;
      }
      let res = "SI() NO()";
      if (item.resolucion == 1) {
        res = "SI(X) NO()";
      } else if (item.resolucion == 2) {
        res = "SI() NO(X)";
      }
      let doc = new jsPDF("p", "mm", "letter");
      let width = doc.internal.pageSize.getWidth();
      let height = doc.internal.pageSize.getHeight();
      let logoaev = new Image();
      logoaev.src = logo2;
      doc.addImage(logoaev, "JPEG", 0, 0, width, height);
      let data = `${item.comisionado_comisionado.usuario_usuario.nombre}||${item.dias}||${fechaActual}||${item.cite}`;
      let qr = await QRCode.toDataURL(data);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(8);
      doc.addImage(qr, "JPEG", 182, 22, 20, 20);
      doc.text("PYV - 004", 185, 43);
      //doc.addImage(qr, "JPEG", 182, 9, 20, 20);
      doc.setFontSize(11);
      doc.setFont("helvetica", "bold");
      doc.setLineWidth(0.2);
      let titulo = doc.splitTextToSize(
        "DIRECCIÓN NACIONAL ADMINISTRATIVA FINANCIERA\nPLANILLA DE LIQUIDACIÓN DE VIÁTICOS Y PASAJES"
      );
      doc.text(titulo, 118, 40, { align: "center" });
      doc.line(67, 40.5, 170, 40.5);
      doc.line(67.5, 45.2, 168.8, 45.2);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);
      doc.text("(Expresado en Bolivianos)", 118, 48, { align: "center" });

      /* doc.text(
        "PAGINA 1 - 1 - FORMULARIO DE LIQUIDACIÓN DE VIAJE",
        151.5,
        253.1
      ); */
      /* doc.text(
        "Calle Fernando Guachalla No 411 Esq 20 de Octubre - Teléfonos: (591) - 2 - 2147767 - 2148747",
        108.5,
        263,
        { align: "center" }
      );
      doc.line(16, 261, 201, 261); */
      let tipoViaje = "";
      if (item.viaje_nacionals.length > 0) {
        tipoViaje =
          item.viaje_nacionals[0].region_nacional_region_nacional.nombre;
      } else if (item.viaje_internacionals.length > 0) {
        tipoViaje =
          item.viaje_internacionals[0]
            .region_internacional_region_internacional;
      }
      let table = [
        [
          {
            content: "AUTORIZACIÓN N°.:  ",
            styles: { halign: "left", fontStyle: "bold", cellWidth: "wrap" },
          },
          {
            content: nro,
            styles: { halign: "left" },
          },
          {
            content: "COMPLEMENTARIO:  ",
            styles: { halign: "left", fontStyle: "bold" },
          },
          {
            content: "-",
            colSpan: 3,
            styles: { halign: "left", fontStyle: "bold" },
          },
        ],
        [
          {
            content: "NOMBRE:  ",
            styles: { halign: "left", fontStyle: "bold" },
          },
          {
            content: item.comisionado_comisionado.usuario_usuario.nombre,
            styles: { halign: "left" },
          },
          {
            content: "C.I.:  ",
            styles: { halign: "left", fontStyle: "bold" },
          },
          {
            content: item.comisionado_comisionado.usuario_usuario.ci,
            styles: { halign: "left", minCellWidth: 20 },
          },
          {
            content: "CATEGORÍA:  ",
            styles: { halign: "left", fontStyle: "bold" },
          },
          {
            content: item.comisionado_comisionado.categoria,
            styles: { halign: "left" },
          },
        ],
        [
          {
            content: "CARGO: ",
            styles: { halign: "left", fontStyle: "bold" },
          },
          {
            content: item.comisionado_comisionado.usuario_usuario.cargo,
            colSpan: 5,
          },
        ],
        [
          {
            content: "OFICINA: ",
            styles: { halign: "left", fontStyle: "bold" },
          },
          {
            content:
              item.comisionado_comisionado.usuario_usuario.oficina_oficina
                .nombre,
            styles: { halign: "left" },
          },
          {
            content: "RESOLUCIÓN: ",
            styles: { halign: "left", fontStyle: "bold" },
          },
          {
            content: res,
            styles: { halign: "left" },
          },
          {
            content: "TIPO DE VIAJE: ",
            styles: { halign: "left", fontStyle: "bold" },
          },
          {
            content: tipoViaje,
            styles: { halign: "left" },
          },
        ],
        [
          {
            content: "PROGRAMA:",
            styles: { halign: "left", fontStyle: "bold" },
          },
          {
            content: item.idunidad_unidad.descripcion,
          },
          {
            content: "FUENTE:",
            styles: { fontStyle: "bold" },
          },
          {
            content: item.idunidad_unidad.fuente,
            colSpan: 3,
          },
        ],
      ];
      let table2 = [
        [{ content: item.motivo, colSpan: 4, styles: { minCellHeight: 15 } }],
        [
          { content: "RUTA:", styles: { fontStyle: "bold" } },
          { content: item.ruta_viaje, cellWidth: 60 },
          {
            content: "MEDIO DE TRANSPORTE: ",
            styles: { fontStyle: "bold" },
          },
          {
            content: item.transporte_transporte.nombre,
            styles: { minCellWidth: 35 },
          },
        ],
      ];
      let table3 = [
        [
          {
            content: "SALIDA",
            colSpan: 4,
            styles: { halign: "center", fontStyle: "bold" },
          },
          {
            content: "RETORNO",
            colSpan: 4,
            styles: { halign: "center", fontStyle: "bold" },
          },
        ],
        [
          { content: "FECHA: ", styles: { fontStyle: "bold" } },
          { content: fecha1, styles: { halign: "center" } },
          { content: "HORA:", styles: { fontStyle: "bold" } },
          { content: hora1, styles: { halign: "center" } },
          { content: "FECHA: ", styles: { fontStyle: "bold" } },
          { content: fecha2, styles: { halign: "center" } },
          { content: "HORA:", styles: { fontStyle: "bold" } },
          { content: hora2, styles: { halign: "center" } },
        ],
      ];
      //console.log(item.pasajes[0]);
      let terr = 0;
      let aereo = 0;
      for (let i = 0; i < item.pasajes.length; i++) {
        if (item.pasajes[i].aereo) aereo += item.pasajes[i].total;
        else terr += item.pasajes[i].total;
      }
      let table4 = [
        [
          { content: "DÍAS", styles: { halign: "center", fontStyle: "bold" } },
          {
            content: "VIATICO POR DÍA",
            styles: { halign: "center", fontStyle: "bold" },
          },
          {
            content: "% ADICIONAL",
            styles: { halign: "center", fontStyle: "bold" },
          },
          { content: "TOTAL", styles: { halign: "center", fontStyle: "bold" } },
        ],
        [
          { content: item.dias, styles: { halign: "center" } },
          {
            content: new Intl.NumberFormat("es-BO", {
              minimumFractionDigits: 2,
            }).format(montoUnico),
            styles: { halign: "center" },
          },
          {
            content:
              "50% = " +
              new Intl.NumberFormat("es-BO", {
                minimumFractionDigits: 2,
              }).format(montoAd),
            styles: { halign: "center" },
          },
          {
            content: new Intl.NumberFormat("es-BO", {
              minimumFractionDigits: 2,
            }).format(item.total_viatico),
            styles: { halign: "right" },
          },
        ],
        [
          {
            content: "PASAJE TERRESTRE:",
            colSpan: 3,
            styles: { fontStyle: "bold" },
          },
          {
            content:
              new Intl.NumberFormat("es-BO", {
                minimumFractionDigits: 2,
              }).format(terr || 0) ||
              new Intl.NumberFormat("es-BO", {
                minimumFractionDigits: 2,
              }).format(0),
            styles: { halign: "right" },
          },
        ],
        [
          {
            content: "PASAJE AEREO:",
            colSpan: 3,
            styles: { fontStyle: "bold" },
          },
          {
            content:
              new Intl.NumberFormat("es-BO", {
                minimumFractionDigits: 2,
              }).format(aereo || 0) ||
              new Intl.NumberFormat("es-BO", {
                minimumFractionDigits: 2,
              }).format(0),
            styles: { halign: "right" },
          },
        ],
        [
          {
            content: "RETENCIONES RC-IVA (13%):",
            colSpan: 3,
            styles: { fontStyle: "bold" },
          },
          {
            content: new Intl.NumberFormat("es-BO", {
              minimumFractionDigits: 2,
            }).format(0),
            styles: { halign: "right" },
          },
        ],
        [
          {
            content: "TASA AEROPUERTARIA:",
            colSpan: 3,
            styles: { fontStyle: "bold" },
          },
          {
            content:
              new Intl.NumberFormat("es-BO", {
                minimumFractionDigits: 2,
              }).format(item.pasajes[0]?.tazaAereo || 0) ||
              new Intl.NumberFormat("es-BO", {
                minimumFractionDigits: 2,
              }).format(0),
            styles: { halign: "right" },
          },
        ],
        [
          {
            content: "TOTAL PASAJES Y VIÁTICOS:",
            colSpan: 3,
            styles: { fontStyle: "bold" },
          },
          {
            content: new Intl.NumberFormat("es-BO", {
              minimumFractionDigits: 2,
            }).format(terr + aereo + item.total_viatico),
            styles: { fontStyle: "bold", halign: "right" },
          },
        ],
      ];
      let table5 = [
        [
          {
            content: "ANTICIPO",
            colSpan: 2,
            styles: { halign: "center", fontStyle: "bold" },
          },
          {
            content: "REEMBOLSO",
            colSpan: 2,
            styles: { halign: "center", fontStyle: "bold" },
          },
        ],
        [
          { content: "VIÁTICO:", styles: { fontStyle: "bold" } },
          {
            content: new Intl.NumberFormat("es-BO", {
              minimumFractionDigits: 2,
            }).format(antViatico),
            styles: { halign: "right", minCellWidth: 33 },
          },
          { content: "VIÁTICO:", styles: { fontStyle: "bold" } },
          {
            content: new Intl.NumberFormat("es-BO", {
              minimumFractionDigits: 2,
            }).format(remViatico),
            styles: { halign: "right", minCellWidth: 33 },
          },
        ],
        [
          { content: "RC-IVA:", styles: { fontStyle: "bold" } },
          {
            content: new Intl.NumberFormat("es-BO", {
              minimumFractionDigits: 2,
            }).format(0),
            styles: { halign: "right", minCellWidth: 33 },
          },
          { content: "RC-IVA:", styles: { fontStyle: "bold" } },
          {
            content: new Intl.NumberFormat("es-BO", {
              minimumFractionDigits: 2,
            }).format(0),
            styles: { halign: "right", minCellWidth: 33 },
          },
        ],
        [
          { content: "TASA AEROPUERTO", styles: { fontStyle: "bold" } },
          {
            content: new Intl.NumberFormat("es-BO", {
              minimumFractionDigits: 2,
            }).format(0),
            styles: { halign: "right", minCellWidth: 33 },
          },
          { content: "TASA AEROPUERTO", styles: { fontStyle: "bold" } },
          {
            content:
              new Intl.NumberFormat("es-BO", {
                minimumFractionDigits: 2,
              }).format(item.pasajes[0]?.tazaAereo || 0) ||
              new Intl.NumberFormat("es-BO", {
                minimumFractionDigits: 2,
              }).format(0),
            styles: { halign: "right", minCellWidth: 33 },
          },
        ],
        [
          { content: "PASAJE TERRESTRE", styles: { fontStyle: "bold" } },
          {
            content: new Intl.NumberFormat("es-BO", {
              minimumFractionDigits: 2,
            }).format(antTerr),
            styles: { halign: "right", minCellWidth: 33 },
          },
          { content: "PASAJE TERRESTRE", styles: { fontStyle: "bold" } },
          {
            content:
              new Intl.NumberFormat("es-BO", {
                minimumFractionDigits: 2,
              }).format(remTerr) ||
              new Intl.NumberFormat("es-BO", {
                minimumFractionDigits: 2,
              }).format(0),
            styles: { halign: "right", minCellWidth: 33 },
          },
        ],
        [
          { content: "PASAJE AEREO:", styles: { fontStyle: "bold" } },
          {
            content: new Intl.NumberFormat("es-BO", {
              minimumFractionDigits: 2,
            }).format(antAereo),
            styles: { halign: "right", minCellWidth: 33 },
          },
          { content: "PASAJE AEREO:", styles: { fontStyle: "bold" } },
          {
            content:
              new Intl.NumberFormat("es-BO", {
                minimumFractionDigits: 2,
              }).format(remAereo) ||
              new Intl.NumberFormat("es-BO", {
                minimumFractionDigits: 2,
              }).format(0),
            styles: { halign: "right", minCellWidth: 33 },
          },
        ],
        [
          { content: "DEPOSITO CUT:", styles: { fontStyle: "bold" } },
          {
            content: new Intl.NumberFormat("es-BO", {
              minimumFractionDigits: 2,
            }).format(0),
            styles: { halign: "right", minCellWidth: 33 },
          },
          { content: "DEPOSITO CUT:", styles: { fontStyle: "bold" } },
          {
            content: new Intl.NumberFormat("es-BO", {
              minimumFractionDigits: 2,
            }).format(0),
            styles: { halign: "right", minCellWidth: 33 },
          },
        ],
        [
          { content: "TOTAL LIQUIDACIÓN:", styles: { fontStyle: "bold" } },
          {
            content: new Intl.NumberFormat("es-BO", {
              minimumFractionDigits: 2,
            }).format(antAereo + antTerr + antViatico),
            styles: { fontStyle: "bold", halign: "right", minCellWidth: 33 },
          },
          { content: "TOTAL LIQUIDACIÓN:", styles: { fontStyle: "bold" } },
          {
            content: new Intl.NumberFormat("es-BO", {
              minimumFractionDigits: 2,
            }).format(remViatico + remAereo + remTerr),
            styles: { fontStyle: "bold", halign: "right", minCellWidth: 33 },
          },
        ],
      ];
      doc.autoTable({
        head: [
          [
            {
              content: "DATOS DEL COMISIONADO",
              colSpan: 6,
              styles: { halign: "center", color: [255, 255, 255] },
            },
          ],
        ],
        body: table,
        margin: { top: 50 },
        styles: {
          lineWidth: 0.25,
          lineColor: 0,
          textColor: 50,
          cellPadding: 0.8,
          fontSize: 7,
        },
        headStyles: {
          fillColor: [255, 255, 255],
          fontSize: 8,
        },
        theme: "plain",
      });
      doc.autoTable({
        head: [
          [
            {
              content: "OBJETIVO DEL VIAJE",
              colSpan: 4,
              styles: { halign: "center", color: [255, 255, 255] },
            },
          ],
        ],
        body: table2,
        startY: doc.lastAutoTable.finalY,
        styles: {
          lineWidth: 0.25,
          lineColor: 0,
          textColor: 50,
          cellPadding: 0.8,
          fontSize: 7,
        },
        headStyles: {
          fillColor: [255, 255, 255],
          fontSize: 8,
        },
        theme: "plain",
      });
      doc.autoTable({
        head: [
          [
            {
              content: "ITINERARIO",
              colSpan: 8,
              styles: { halign: "center", color: [255, 255, 255] },
            },
          ],
        ],
        body: table3,
        startY: doc.lastAutoTable.finalY,
        styles: {
          lineWidth: 0.25,
          lineColor: 0,
          textColor: 50,
          cellPadding: 0.8,
          fontSize: 7,
        },
        headStyles: {
          fillColor: [255, 255, 255],
          fontSize: 8,
        },
        theme: "plain",
      });
      doc.autoTable({
        head: [
          [
            {
              content: "CALCULO VIÁTICOS",
              colSpan: 4,
              styles: { halign: "center", color: [255, 255, 255] },
            },
          ],
        ],
        body: table4,
        startY: doc.lastAutoTable.finalY,
        styles: {
          lineWidth: 0.25,
          lineColor: 0,
          textColor: 50,
          cellPadding: 0.8,
          fontSize: 7,
        },
        headStyles: {
          fillColor: [255, 255, 255],
          fontSize: 8,
        },
        theme: "plain",
      });
      doc.autoTable({
        head: [
          [
            {
              content: "MONTO ANTICIPÓ - REEMBOLSO",
              colSpan: 4,
              styles: { halign: "center", color: [255, 255, 255] },
            },
          ],
        ],
        body: table5,
        startY: doc.lastAutoTable.finalY,
        styles: {
          lineWidth: 0.25,
          lineColor: 0,
          textColor: 50,
          cellPadding: 0.8,
          fontSize: 7,
        },
        headStyles: {
          fillColor: [255, 255, 255],
          fontSize: 8,
        },
        theme: "plain",
      });
      doc.autoTable({
        head: [
          [
            {
              content: "RECOMENDACIONES",
              styles: { halign: "center", color: [255, 255, 255] },
            },
          ],
        ],
        body: [[{ content: "", styles: { minCellHeight: 15 } }]],
        startY: doc.lastAutoTable.finalY,
        styles: {
          lineWidth: 0.25,
          lineColor: 0,
          textColor: 50,
          cellPadding: 0.8,
          fontSize: 7,
        },
        headStyles: {
          fillColor: [255, 255, 255],
          fontSize: 8,
        },
        theme: "plain",
      });
      let y = doc.lastAutoTable.finalY + 25;
      doc.setDrawColor(0, 0, 0);
      doc.setFontSize(10);
      doc.line(35.5, y, 103.5, y);
      doc.text("ELABORADO Y VERIFICADO", 68.5, y + 4, { align: "center" });
      doc.line(122.5, y, 190, y);
      doc.text("APROBADO", 155.5, y + 4, { align: "center" });
      doc.setFont("helvetica", "italic");
      doc.setFontSize(6);
      doc.setProperties({
        title: "Certificado de Liquidación",
      });
      window.open(doc.output("bloburl", "Certificado_de_Liquidacion.pdf"));
      //doc.output("dataurlnewwindow", { filename: "Certificado-Liquidación" });
      //doc.save("Certificado-Liquidación.pdf");
    },
  },
};
</script>
<style scoped>
.inputPrice >>> input[type="number"] {
  -moz-appearance: textfield;
}
.inputPrice >>> input::-webkit-outer-spin-button,
.inputPrice >>> input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.custom-spacing {
  margin-top: 10px;
  padding-left: 20px;
}
</style>
