<template>
  <v-layout align-start>
    <v-flex>
      <v-data-table
        :headers="headers"
        :items="viaje"
        :search="search"
        sort-by="nombre"
        class="elevation-1"
        style="background: rgba(255, 255, 255, 0.8)"
      >
        <template #item.total_viatico="{ item }">
          {{
            new Intl.NumberFormat("es-BO", {
              minimumFractionDigits: 2,
            }).format(item.total_viatico)
          }}
        </template>
        <template #item.fecha1="{ item }">
          {{
            item.fecha1
            /* `${new Date(
            item.fecha1.split(" ")[0].split("/").reverse().join("/")
            ).toLocaleDateString("en-GB")} ${new Date(
            item.fecha1
            ).toLocaleTimeString("en-GB")}` */
          }}
        </template>
        <template #item.fecha2="{ item }">
          {{
            item.fecha2
            /* `${new Date(
            item.fecha2.split(" ")[0].split("/").reverse().join("/")
            ).toLocaleDateString("en-GB")} ${new Date(
            item.fecha2
            ).toLocaleTimeString("en-GB")}` */
          }}
        </template>
        <template #item.fecha_informe="{ item }">
          <v-chip :color="getColor(item.fecha_informe, item.estado)" dark>
            {{
              `${new Date(
                item.fecha_informe.split(" ")[0].split("/").reverse().join("/")
              ).toLocaleDateString("en-GB")}`
            }}
          </v-chip>
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>VIAJES</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Buscar...."
              single-line
              hide-details
              ref="searchbar"
            >
            </v-text-field>
            <v-spacer></v-spacer>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-dialog v-model="dialog" max-width="850px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon left dark>flight_takeoff</v-icon> Registra Viaje
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row class="py-md-1 my-md-n6">
                        <v-col cols="12">
                          <v-textarea
                            v-model="editedItem.motivo"
                            rows="2"
                            no-resize
                            color="primary"
                            :rules="[(v) => !!v || 'Motivo es Requerido']"
                            label="Motivo de Viaje"
                            required
                            maxlength="300"
                            hint="Maximo 300 caracteres"
                          >
                          </v-textarea>
                        </v-col>
                      </v-row>
                      <v-row class="py-md-1 my-md-n6">
                        <v-col cols="12" sm="6" md="4">
                          <v-autocomplete
                            v-model="editedItem.pais"
                            :items="pais"
                            :rules="[(v) => !!v || 'Pais es Requerido']"
                            label="Pais"
                            @change="(valor) => changeState(valor)"
                            required
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                          v-if="editedItem.pais == 187 || showLugar"
                        >
                          <v-autocomplete
                            v-model="editedItem.iddepto"
                            :items="departamentos"
                            :rules="[(v) => !!v || 'Departamento es Requerido']"
                            label="Departamento Destino"
                            required
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.lugar"
                            label="Lugar"
                            :rules="[(v) => !!v || 'Lugar es Requerido']"
                            @change="(valor) => changeState2(valor)"
                            required
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="py-md-1 my-md-n6">
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.ruta_viaje"
                            label="Ruta Viaje"
                            :rules="[(v) => !!v || 'Ruta es Requerido']"
                            required
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="6" md="4">
                          <v-select
                            v-model="editedItem.resolucion"
                            :items="resolucion"
                            :rules="[(v) => !!v || 'Resolución es Requerido']"
                            label="Resolución"
                            required
                          >
                          </v-select>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                          v-if="editedItem.pais == 187 || showLugar"
                        >
                          <v-select
                            v-model="editedItem.tv"
                            :items="rn"
                            :rules="[
                              (v) => !!v || 'Tipo de Viaje es Requerido',
                            ]"
                            label="Tipo de Viaje"
                            @change="(valor) => changeTipo(valor)"
                            required
                          >
                          </v-select>
                          <v-checkbox
                            v-model="editedItem.distancia"
                            v-if="showCheck"
                            label="¿Es Menor a 100 Km?"
                          >
                          </v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" v-else>
                          <v-select
                            v-model="editedItem.tv"
                            :items="ri"
                            :rules="[
                              (v) => !!v || 'Tipo de Viaje es Requerido',
                            ]"
                            label="Tipo de Viaje"
                            required
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-select
                            v-model="editedItem.idunidad"
                            :items="unidad"
                            :rules="[(v) => !!v || 'Unidad es Requerido']"
                            label="Unidad"
                            readonly
                            required
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-select
                            v-model="editedItem.transporte"
                            :items="transporte"
                            :rules="[(v) => !!v || 'Transporte es Requerido']"
                            label="Transporte"
                            required
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-select
                            v-model="editedItem.financiamiento"
                            :items="financiamientos"
                            :rules="[
                              (v) => !!v || 'Financiamiento es Requerido',
                            ]"
                            label="Financiamiento"
                            required
                          >
                          </v-select>
                        </v-col>
                        <v-row class="py-md-0 my-md-n6">
                          <v-col cols="12">
                            <v-radio-group
                              v-model="editedItem.radioPoa"
                              label="Se encuentra en el POA"
                              row
                              readonly
                              :rules="[(v) => !!v || 'Poa es Requerido']"
                              required
                            >
                              <v-radio label="SI" value="1"></v-radio>
                              <v-radio label="NO" value="2"></v-radio>
                            </v-radio-group>
                          </v-col>
                        </v-row>
                        <v-row class="py-md-3 my-md-n6">
                          <v-col cols="12">
                            <v-radio-group
                              v-model="editedItem.radioRow"
                              label="Comisión de Viajes:"
                              row
                              :rules="[
                                (v) => !!v || 'Tipo de viaje es Requerido',
                              ]"
                              @change="changeRadio()"
                              required
                            >
                              <v-radio label="Ninguna" value="1"></v-radio>
                              <v-radio
                                label="Excepcion - (ART. 14 RPV)"
                                value="2"
                              ></v-radio>
                              <v-radio
                                label="Extraordinario - (Instr. MAE)"
                                value="3"
                              ></v-radio>
                            </v-radio-group>
                          </v-col>
                        </v-row>
                        <v-row class="py-md-1 my-md-n6">
                          <v-col cols="12" sm="6" md="4">
                            <v-menu
                              v-model="menu"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="editedItem.date"
                                  label="Dia partida"
                                  prepend-icon="calendar_month"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  :rules="[(v) => !!v || 'Fecha es Requerido']"
                                  required
                                >
                                </v-text-field>
                              </template>
                              <v-date-picker
                                v-model="editedItem.date"
                                :min="editedItem.date"
                                @input="menu = false"
                              >
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-menu
                              ref="menu"
                              v-model="menu3"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              :return-value.sync="editedItem.time"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="editedItem.time"
                                  label="Hora de Partida"
                                  prepend-icon="schedule"
                                  readonly
                                  v-bind="attrs"
                                  :rules="[(v) => !!v || 'Hora es Requerido']"
                                  v-on="on"
                                  required
                                >
                                </v-text-field>
                              </template>
                              <v-time-picker
                                v-if="menu3"
                                v-model="editedItem.time"
                                full-width
                                format="24hr"
                                @click:minute="$refs.menu.save(editedItem.time)"
                              >
                              </v-time-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                        <v-row class="py-md-1 my-md-n6">
                          <v-col cols="12" sm="6" md="4">
                            <v-menu
                              v-model="menu2"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="editedItem.date2"
                                  label="Dia Retorno"
                                  prepend-icon="calendar_month"
                                  readonly
                                  v-bind="attrs"
                                  :rules="[(v) => !!v || 'Fecha es Requerido']"
                                  v-on="on"
                                  required
                                >
                                </v-text-field>
                              </template>
                              <v-date-picker
                                v-model="editedItem.date2"
                                :min="editedItem.date"
                                @input="menu2 = false"
                              >
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-menu
                              ref="menu2"
                              v-model="menu4"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              :return-value.sync="editedItem.time2"
                              transition="scale-transition"
                              luxon
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="editedItem.time2"
                                  label="Hora de Retorno"
                                  prepend-icon="schedule"
                                  readonly
                                  v-bind="attrs"
                                  :rules="[(v) => !!v || 'Hora es Requerido']"
                                  v-on="on"
                                  required
                                >
                                </v-text-field>
                              </template>
                              <v-time-picker
                                v-if="menu4"
                                v-model="editedItem.time2"
                                format="24hr"
                                full-width
                                :min="
                                  editedItem.date2 != editedItem.date
                                    ? '0:00'
                                    : calHour(editedItem.time)
                                "
                                @click:minute="
                                  $refs.menu2.save(editedItem.time2)
                                "
                              >
                              </v-time-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn
                      :disabled="!valid"
                      color="blue darken-1"
                      text
                      @click="save"
                    >
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-form>
            <v-form ref="form2" v-model="valid2" lazy-validation>
              <v-dialog v-model="modalSubirInforme" max-width="450px">
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Informe</span>
                  </v-card-title>
                  <v-card-text>
                    <v-file-input
                      v-model="informe"
                      accept="application/pdf"
                      label="Subir informe en formato PDF"
                      :rules="[
                        (v) =>
                          !!v || v.size < 1000000 || 'Tamaño maximo de 1 MB',
                      ]"
                      required
                    ></v-file-input>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeInforme">
                      Cancelar
                    </v-btn>
                    <v-btn
                      :disabled="!valid2"
                      color="blue darken-1"
                      text
                      @click="saveInforme"
                      >Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-form>
            <v-form ref="form3" v-model="valid3" lazy-validation>
              <v-dialog v-model="modalInforme" max-width="740px">
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle2 }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedInfItem.informe"
                            label="Nro. Infome"
                            :rules="[(v) => !!v || 'Informe es Requerido']"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <!-- <v-select
                            v-model="editedInfItem.director"
                            :items="director"
                            :rules="[(v) => !!v || 'Dirigido es Requerido']"
                            label="Dirigido a:"
                            required
                          >
                          </v-select> -->
                          <v-autocomplete
                            v-model="editedInfItem.director"
                            :items="director"
                            :rules="[(v) => !!v || 'Dirigido es Requerido']"
                            dense
                            filled
                            label="Dirigido a:"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedInfItem.ref"
                            label="Referencia"
                            :rules="[(v) => !!v || 'Referencia es Requerido']"
                            required
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="py-md-1 my-md-n6">
                        <div
                          v-for="(via, index3) in editedInfItem.vias"
                          :key="index3"
                        >
                          <v-row class="pl-3">
                            <v-col cols="12" sm="6" md="6">
                              <!-- <v-select
                                v-model="via.value"
                                :items="director"
                                label="Via"
                              >
                              </v-select> -->
                              <v-autocomplete
                                v-model="via.value"
                                :items="director"
                                dense
                                filled
                                label="Via"
                              ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6" md="2" class="pt-md-11">
                              <v-icon
                                class="mr-2"
                                @click="removeVia(index3)"
                                color="error"
                              >
                                remove_circle
                              </v-icon>
                            </v-col>
                          </v-row>
                        </div>
                      </v-row>
                      <v-row class="p1-3">
                        <v-spacer></v-spacer>
                        <v-col cols="12" sm="6" md="4">
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="addVia(index3)"
                          >
                            Añadir Via
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <v-textarea
                            v-model="editedInfItem.antecedente"
                            label="Antecedente"
                            rows="2"
                            no-resize
                            row-height="20"
                          >
                          </v-textarea>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-textarea
                            no-resize
                            row-height="20"
                            rows="2"
                            v-model="editedInfItem.objeto"
                            label="Objeto"
                          >
                          </v-textarea>
                        </v-col>
                      </v-row>
                      <v-row class="pa-md-4">
                        <v-col cols="12" sm="6" md="4" v-if="showTrans">
                          <v-text-field
                            v-model="editedInfItem.placa"
                            label="Placa"
                            :rules="[(v) => !!v || 'Placa es Requerido']"
                            required
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" v-if="showTrans">
                          <v-text-field
                            v-model="editedInfItem.chofer"
                            label="Chofer"
                            :rules="[(v) => !!v || 'Chofer es Requerido']"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>

                      <v-row v-if="!showTrans" class="py-md-1 my-md-n6">
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            label="Observaciones Partida"
                            v-model="editedInfItem.obs1"
                            counter
                            maxlength="100"
                            hint="Maximo 100 caracteres"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            label="Observaciones Retorno"
                            counter
                            maxlength="100"
                            hint="Maximo 100 caracteres"
                            v-model="editedInfItem.obs2"
                          ></v-text-field
                        ></v-col>
                      </v-row>
                      <div
                        v-for="(fecha, index) in editedInfItem.fechas"
                        :key="index"
                      >
                        <v-row>
                          <div class="pt-md-4">
                            <span>{{ fecha.date }}</span>
                          </div>
                        </v-row>
                        <v-row>
                          <div
                            v-for="(atrib, index2) in fecha.camp"
                            :key="index2"
                          >
                            <v-row>
                              <v-col cols="12" sm="5" md="2">
                                <v-menu
                                  :ref="`menu5` + index"
                                  :v-model="menu5 + index"
                                  :name="`editedInfItem.fechas[${index}].camp[${index2}][hour]`"
                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  :return-value.sync="atrib.hour"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="atrib.hour"
                                      label="Hora"
                                      prepend-icon="schedule"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                      :rules="[
                                        (v) => !!v || 'Hora es Requerido',
                                      ]"
                                      required
                                    >
                                    </v-text-field>
                                  </template>
                                  <v-time-picker
                                    v-if="menu5 + index2"
                                    v-model="atrib.hour"
                                    :name="`editedInfItem.fechas[${index}].camp[${index2}][hour]`"
                                    format="24hr"
                                    full-width
                                    @click:minute="
                                      $refs[`menu5` + index][index2].save(
                                        atrib.hour
                                      )
                                    "
                                  >
                                  </v-time-picker>
                                </v-menu>
                                <!--<v-text-field
                                  v-model="atrib.hour"
                                  :name="`editedInfItem.fechas[${index}].camp[${index2}][hour]`"
                                  label="Hora"
                                  prepend-icon="schedule"
                                  :rules="[(v) => !!v || 'Cargo es Requerido']"
                                  required
                                ></v-text-field>-->
                              </v-col>
                              <v-col cols="12" sm="6" md="4">
                                <v-textarea
                                  label="Actividad"
                                  rows="2"
                                  no-resize
                                  row-height="20"
                                  v-model="atrib.activity"
                                  counter
                                  maxlength="255"
                                  hint="Maximo 255 caracteres"
                                  :name="`editedInfItem.fechas[${index}].camp[${index2}][activity]`"
                                  :rules="[
                                    (v) => !!v || 'Actividad es Requerido',
                                  ]"
                                  required
                                ></v-textarea>
                              </v-col>
                              <v-col cols="12" sm="6" md="4">
                                <v-textarea
                                  label="Resultados y respaldos"
                                  rows="2"
                                  no-resize
                                  row-height="20"
                                  counter
                                  maxlength="255"
                                  hint="Maximo 255 caracteres"
                                  v-model="atrib.result"
                                  :name="`editedInfItem.fechas[${index}].camp[${index2}][result]`"
                                ></v-textarea>
                              </v-col>
                              <v-col cols="12" sm="6" md="2" class="pt-md-11">
                                <div>
                                  <v-icon
                                    class="mr-2"
                                    @click="removeCamp(index, index2)"
                                    color="error"
                                  >
                                    remove_circle
                                  </v-icon>
                                </div>
                              </v-col>
                            </v-row>
                            <v-divider></v-divider>
                          </div>

                          <v-row class="pa-md-4">
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="addCamp(index)"
                            >
                              Añadir
                            </v-btn>
                          </v-row>
                        </v-row>
                      </div>
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <v-textarea
                            label="Conclusiones"
                            rows="2"
                            no-resize
                            row-height="20"
                            v-model="editedInfItem.conclusiones"
                            :rules="[(v) => !!v || 'Conclusiones es Requerido']"
                          ></v-textarea>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-textarea
                            label="Recomendaciones"
                            rows="2"
                            no-resize
                            row-height="20"
                            v-model="editedInfItem.recomendaciones"
                            :rules="[
                              (v) => !!v || 'Recomendaciones es Requerido',
                            ]"
                          ></v-textarea
                        ></v-col>
                      </v-row>
                      <v-row>
                        <!-- <v-text-field
                          v-model="editedInfItem.adjunto"
                          label="Documentacion adjunta"
                          placeholder="Actas de Reunión, Reporte Fotográfico, Lista de Asistencia y Otros"
                          :rules="[
                            (v) => !!v || 'Recomendaciones es Requerido',
                          ]"
                          counter
                          maxlength="255"
                        >
                        </v-text-field> -->
                        <v-autocomplete
                          v-model="editedInfItem.adjunto"
                          :items="adjunto"
                          outlined
                          dense
                          chips
                          small-chips
                          label="Adjunto Informe"
                          :rules="[(v) => !!v || 'Adjunto es Requerido']"
                          multiple
                        ></v-autocomplete>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeInf">
                      Cancelar
                    </v-btn>
                    <v-btn
                      :disabled="!valid3"
                      color="blue darken-1"
                      text
                      @click="saveInf"
                      >Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-form>
            <v-dialog v-model="dialogQr" persistent max-width="390">
              <v-card>
                <v-card-title class="text-h5"> Descargar QR </v-card-title>
                <v-card-text>
                  Let Google help apps determine location. This means sending
                  anonymous location data to Google, even when no apps are
                  running.
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="green darken-1" text @click="dialogQr = false">
                    Disagree
                  </v-btn>
                  <v-btn color="green darken-1" text @click="dialogQr = false">
                    Agree
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="text-truncate">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  class="mr-2"
                  @click="editItem(item)"
                  color="primary"
                  v-if="
                    new Date(item.create_vi).setHours(0, 0, 0, 0) ==
                      new Date().setHours(0, 0, 0, 0) &&
                    item.estado_estado.id == 1
                  "
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mode_edit </v-icon>
                </v-btn>
              </template>
              <span>Modificar Viaje</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  class="mr-2"
                  @click="crearPDF(item)"
                  v-if="item.estado_estado.id == 1"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>print </v-icon>
                </v-btn>
              </template>
              <span>Imprimir Solicitud</span>
            </v-tooltip>
            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  class="mr-2"
                  @click="editInfItem(item)"
                  color="info"
                  v-if="item.estado_estado.id == 2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>qr_code_2</v-icon>
                </v-btn>
              </template>
              <span>Generar QR</span>
            </v-tooltip> -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  class="mr-2"
                  @click="bajarDocInf(item)"
                  color="info"
                  v-if="
                    item.estado_estado.id == 2 &&
                    new Date().getTime() <=
                      new Date(item.fecha_modificacion).getTime()
                  "
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>file_open</v-icon>
                </v-btn>
              </template>
              <span>Generar Informe</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  class="mr-2"
                  @click="subirPDF(item)"
                  color="info"
                  v-if="
                    item.estado_estado.id == 2 &&
                    new Date().getTime() <=
                      new Date(item.fecha_modificacion).getTime()
                  "
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>upload_file </v-icon>
                </v-btn>
              </template>
              <span>Subir Informe</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  class="mr-2"
                  @click="subirPDF(item)"
                  color="info"
                  v-if="
                    item.estado == 2 &&
                    new Date().getTime() >
                      new Date(item.fecha_modificacion).getTime() &&
                    new Date().getTime() <= new Date(item.fecha_edita).getTime()
                  "
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>upload_file </v-icon>
                </v-btn>
              </template>
              <span>Subir Informe</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  class="mr-2"
                  @click="bajarPDF(item)"
                  color="info"
                  v-if="item.estado_estado.id > 2 && item.estado_estado.id < 5"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>picture_as_pdf </v-icon>
                </v-btn>
              </template>
              <span>Descargar Informe</span>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="viajeList()"> Refrescar </v-btn>
        </template>
      </v-data-table>
      <v-snackbar
        v-model="snackbar"
        :timeout="2200"
        bottom
        centered
        rounded="pill"
        :color="color"
        elevation="20"
      >
        <template>
          {{ message }}
        </template>
      </v-snackbar>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-flex>
  </v-layout>
</template>

<script>
import axios from "axios";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { DateTime } from "luxon";
import QRCode from "qrcode";
const logo = require("@/assets/logoCab.jpg");
const logo2 = require("@/assets/membrete.png");
import Verdana from "@/assets/fonts/verdana-normal";
import VerdanaBold from "@/assets/fonts/verdana-bold-bold";
import VerdanaBoldItalic from "@/assets/fonts/verdana-bold-italic-bolditalic";

export default {
  data() {
    return {
      dialog: false,
      dialogQr: false,
      modalSubirInforme: false,
      modalInforme: false,
      informe: null,
      valid: false,
      valid2: false,
      valid3: false,
      showLugar: false,
      showCheck: false,
      showTrans: false,
      overlay: false,
      memo: "",
      search: "",
      message: "",
      color: "",
      radioRow: "",
      snackbar: false,
      index3: 0,
      dateNow: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 10),
      timeNow: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substring(11, 5),
      menu: "",
      menu2: "",
      menu3: "",
      menu4: "",
      menu5: "",
      viaje: [],
      checkbox: false,
      departamentos: [],
      pais: [],
      categoria: "",
      transporte: [],
      unidad: [],
      financiamientos: [],
      ri: [],
      rn: [],
      resolucion: [],
      director: [],
      adjunto: [
        "Actas de Reunión",
        "Reporte Fotografico",
        "Lista Asistencia",
        "Otros",
      ],
      headers: [
        { text: "MEMO", value: "cite" },
        { text: "Pais", value: "pais_pai.nombre", sortable: false },
        {
          text: "Departamento",
          value: "iddepto_departamento.departamento",
          sortable: false,
        },
        { text: "Lugar", value: "lugar", sortable: false },
        { text: "Ida", value: "fecha1", sortable: false },
        { text: "Retorno", value: "fecha2", sortable: false },
        {
          text: "Viaticos",
          value: "total_viatico",
          sortable: false,
          align: "right",
        },
        { text: "Dias", value: "dias", sortable: false },
        { text: "Plazo Informe", value: "fecha_informe", sortable: false },
        { text: "Estado", value: "estado_estado.nombre", sortable: false },
        {
          text: "Opciones",
          value: "actions",
          align: "center",
          width: "10%",
          sortable: false,
        },
      ],
      editedIndex: -1,
      editedItem: {
        anticipo: null,
        cite: null,
        comisionado: null,
        cot_dolar: null,
        dias: null,
        estado: null,
        fecha1: null,
        fecha2: null,
        fecha_informe: null,
        fecha_reliquidado: null,
        financiamiento: 3,
        fines_semana: null,
        fuente_financiamiento: null,
        id: null,
        iddepto: null,
        idpresupuesto: null,
        idunidad: null,
        iva: null,
        lugar: null,
        motivo: null,
        pais: 187,
        pais_pai: null,
        representacion: null,
        resolucion: 2,
        ruta_viaje: null,
        slug: null,
        total_viatico: null,
        transporte: null,
        viatico: null,
        date: new Date(new Date().setDate(new Date().getDate() + 1))
          .toISOString()
          .substring(0, 10),
        date2: null,
        time: null,
        time2: null,
        tv: "",
        distancia: false,
        radioRow: "1",
        radioPoa: "1",
      },
      defaultItem: {
        anticipo: null,
        cite: null,
        comisionado: null,
        cot_dolar: null,
        dias: null,
        estado: null,
        fecha1: null,
        fecha2: null,
        fecha_informe: null,
        fecha_reliquidado: null,
        financiamiento: 3,
        fines_semana: null,
        fuente_financiamiento: null,
        id: null,
        iddepto: null,
        idpresupuesto: null,
        idunidad: null,
        iva: null,
        lugar: null,
        motivo: null,
        pais: 187,
        pais_pai: null,
        representacion: null,
        resolucion: 2,
        ruta_viaje: null,
        slug: null,
        total_viatico: null,
        transporte: null,
        viatico: null,
        date: "" /* new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substring(0, 10), */,
        date2: null,
        time: null,
        time2: null,
        tv: "",
        distancia: false,
        radioRow: "1",
        radioPoa: "1",
      },
      editedInfIndex: -1,
      editedInfItem: {
        informe: "",
        cite: "",
        director: "",
        antecedente: "",
        dias: "",
        objeto: "",
        placa: "",
        via: "",
        chofer: "",
        ref: "",
        motivo: "",
        lugar1: "",
        lugar2: "",
        fechaIni: "",
        hora1: "",
        fechaFin: "",
        hora2: "",
        fechas: [],
        nro: "",
        conclusiones: "",
        recomendaciones: "",
        adjunto: "",
        transporte: "",
        obs1: "",
        obs2: "",
        viaje: "",
        id: "",
        resolucion: "",
        vias: [],
      },
      defaultInfItem: {
        informe: "",
        cite: "",
        director: "",
        placa: "",
        dias: "",
        antecedente: "",
        objeto: "",
        via: "",
        chofer: "",
        ref: "",
        motivo: "",
        lugar1: "",
        lugar2: "",
        fechaIni: "",
        hora1: "",
        fechaFin: "",
        hora2: "",
        fechas: [],
        nro: "",
        conclusiones: "",
        recomendaciones: "",
        adjunto: "",
        transporte: "",
        obs1: "",
        obs2: "",
        viaje: "",
        id: "",
        resolucion: "",
        vias: [],
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Nuevo Registro"
        : "Editar Registro " + this.editedItem.cite;
    },
    formTitle2() {
      return this.editedInfItem === -1 ? "Editar Informe" : "Nuevo Informe";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    modalInforme(val) {
      val || this.closeInf();
    },
  },
  created() {
    this.initialize();
    this.viajeList();
  },
  methods: {
    initialize() {
      let aux = this;
      let header = { Token: this.$store.state.token };
      let conf = { headers: header };
      axios
        .get("pais/list", conf)
        .then((response) => {
          response.data.map((item) => {
            aux.pais.push({ text: item.nombre, value: item.id });
          });
        })
        .catch((error) => {
          //console.log(error.response.status);
          if (error.response.status == 403) {
            this.$store.dispatch("out");
          }
        });
      axios
        .get("departamento/list", conf)
        .then((response) => {
          response.data.map((item) => {
            aux.departamentos.push({
              text: item.departamento,
              value: item.id,
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get("transporte/list", conf)
        .then((response) => {
          response.data.map((item) => {
            aux.transporte.push({ text: item.nombre, value: item.id });
          });
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get("unidad/list", conf)
        .then((response) => {
          response.data.map((item) => {
            aux.unidad.push({ text: item.descripcion, value: item.id });
          });
        })
        .catch((error) => {
          console.log(error);
        });
      axios.get("resolucion/list", conf).then((response) => {
        response.data.map((item) => {
          aux.resolucion.push({ text: item.descripcion, value: item.id });
        });
      });
      axios
        .get("financiamiento/list", conf)
        .then((response) => {
          response.data.map((item) => {
            aux.financiamientos.push({ text: item.nombre, value: item.id });
          });
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get("region_nacional/list", conf)
        .then((response) => {
          response.data.map((item) => {
            aux.rn.push({ text: item.nombre, value: item.id });
          });
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get("region_internacional/list", conf)
        .then((response) => {
          response.data.map((item) => {
            aux.ri.push({ text: item.nombre, value: item.id });
          });
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get("usuario/query/" + this.$store.state.user._id, conf)
        .then((response) => {
          let u = response.data;
          this.editedItem.idunidad = u.unidad;
          this.defaultItem.idunidad = u.unidad;
          axios.get("usuario/list/" + u.unidad, conf).then((data) => {
            data.data.map((item) => {
              aux.director.push({
                text: item.nombre,
                value: item.id,
                aux: item.cargo,
              });
            });
          });
        });
    },
    uppercase() {
      this.editedItem.motivo = this.editedItem.motivo.toUpperCase();
    },
    viajeList() {
      let aux = this;
      let header = { Token: this.$store.state.token };
      let conf = { headers: header };
      this.viaje = [];
      axios
        .get("viaje/query/" + this.$store.state.user._id, conf)
        .then(function (response) {
          aux.viaje = response.data;
          aux.viaje.forEach((element) => {
            element.fecha_reliquidado = element.fecha_informe;
            element.fecha1 =
              new Date(element.fecha1).toLocaleDateString("en-GB") +
              " " +
              new Date(element.fecha1).toLocaleTimeString("en-GB", {
                hour: "2-digit",
                minute: "2-digit",
              });
            //console.log(element.fecha1);
            element.fecha2 =
              new Date(element.fecha2).toLocaleDateString("en-GB") +
              " " +
              new Date(element.fecha2).toLocaleTimeString("en-GB", {
                hour: "2-digit",
                minute: "2-digit",
              });
            element.fecha_modificacion = element.fecha_informe;
            element.fecha_informe = new Date(
              element.fecha_informe
            ).toLocaleDateString();
            //console.log(element.fecha1, element.fecha2);
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    editItem(item) {
      //onsole.log(item);
      /* if (item.pais == 187) {
        this.showLugar = true;
      } else {
        this.showLugar = false;
      } */
      this.editedIndex = this.viaje.indexOf(item);
      this.editedItem = Object.assign({}, item);
      if (item.distancia == 0) {
        this.editedItem.distancia = false;
      } else {
        this.editedItem.distancia = true;
      }
      this.editedItem.date = new Date(
        item.fecha1.split(" ")[0].split("/").reverse().join("-")
      )
        .toISOString()
        .substring(0, 10);
      this.editedItem.time = item.fecha1.split(" ")[1];
      this.editedItem.date2 = new Date(
        item.fecha2.split(" ")[0].split("/").reverse().join("-")
      )
        .toISOString()
        .substring(0, 10);
      this.editedItem.time2 = item.fecha2.split(" ")[1];
      this.editedItem.radioRow = item.excepcion;
      this.editedItem.radioPoa = item.poa;
      if (item.viaje_internacionals.length > 0) {
        this.editedItem.tv = item.viaje_internacionals[0].region_internacional;
      } else {
        this.editedItem.tv = item.viaje_nacionals[0].region_nacional;
        if (item.viaje_nacionals[0].region_nacional == 2) {
          this.showCheck = true;
        }
      }
      this.dialog = true;
    },
    async editInfItem(item) {
      //console.log(item);
      if (item.transporte == 3 || item.transporte == 4) this.showTrans = true;
      else this.showTrans = false;
      let fechaIni = new Date(
        item.fecha1.split(" ")[0].split("/").reverse().join("-") + " 00:00"
      );
      let fechaFin = new Date(
        item.fecha2.split(" ")[0].split("/").reverse().join("-") + " 00:00"
      );
      this.editedInfItem.fechaIni = fechaIni.toISOString();
      this.editedInfItem.fechaFin = fechaFin.toISOString();
      let a = "",
        b = "";
      if (item.fecha1.split(" ")[1].substring(0, 5).substr(-1) == ":") {
        a = item.fecha1.split(" ")[1].substring(0, 4);
      } else {
        a = item.fecha1.split(" ")[1].substring(0, 5);
      }
      if (item.fecha2.split(" ")[1].substring(0, 5).substr(-1) == ":") {
        b = item.fecha2.split(" ")[1].substring(0, 4);
      } else {
        b = item.fecha2.split(" ")[1].substring(0, 5);
      }
      this.editedInfItem.hora1 = a;
      this.editedInfItem.hora2 = b;
      this.editedInfItem.dias = item.dias;
      this.editedInfItem.cite = item.cite;
      this.editedInfItem.motivo = item.motivo;
      this.editedInfItem.lugar1 = item.ruta_viaje.split(" - ")[0];
      this.editedInfItem.lugar2 = item.lugar;
      this.editedInfItem.transporte = item.transporte;
      this.editedInfItem.viaje = item.id;
      this.editedInfItem.nro =
        item.cite.match(/(\d+)/g)[0] + " - " + item.cite.match(/(\d+)/g)[1];
      this.editedInfItem.resolucion = item.resolucion;
      let header = { Token: this.$store.state.token };
      let conf = { headers: header };
      await axios
        .get("informe/query2/" + item.id, conf)
        .then((response) => {
          console.log(response);
          this.editedInfItem.id = response.data.id;
          this.editedInfIndex = response.data.id;
          this.editedInfItem.informe = response.data.nro_informe;
          this.editedInfItem.ref = response.data.referencia;
          this.editedInfItem.antecedente = response.data.antecedente;
          this.editedInfItem.objeto = response.data.objeto;
          this.editedInfItem.chofer = response.data.chofer;
          this.editedInfItem.placa = response.data.placa;
          let o = response.data.observaciones.split("<br/>");
          this.editedInfItem.obs1 = o[0];
          this.editedInfItem.obs2 = o[1];
          this.editedInfItem.director = response.data.dirigido;
          let auxvia = response.data.via.split("<br/>");
          auxvia.map((element) => {
            if (element != "")
              this.editedInfItem.vias.push({ value: Math.trunc(element) });
          });
          //console.log(this.editedInfItem.vias);
          let c = response.data.concluciones_recomendaciones.split("<br/>");
          this.editedInfItem.conclusiones = c[0];
          this.editedInfItem.recomendaciones = c[1];
          let actividades = response.data.actividads;
          actividades.forEach((element) => {
            let camp = [];
            element.detalle_actividads.forEach((valor) => {
              camp.push({
                hour: valor.hora,
                activity: valor.actividad,
                result: valor.resultado,
                id: valor.id,
              });
            });
            //console.log(element.dia);
            this.editedInfItem.fechas.push({
              date: new Date(element.dia.replace(/-/g, "/")).toLocaleDateString(
                "en-GB"
              ),
              camp: camp,
              id: element.id,
            });
          });
        })
        .catch((error) => {
          this.editedInfIndex = -1;
          //this.editedInfItem.informe = item.informe;
          this.editedInfItem.informe =
            item.comisionado_comisionado.usuario_usuario.oficina_oficina.cite +
            "/";
          this.editedInfItem.antecedente = `En cumplimiento a la Autorización de Viaje N° ${this.editedInfItem.nro}`;
          this.editedInfItem.objeto = `El objeto del viaje autorizado, fue realizar las siguientes actividades: ${this.editedInfItem.motivo}`;
          //this.editedInfItem.ref = item.ref;
          this.editedInfItem.vias = [{ value: "" }];
          while (fechaIni.getTime() <= fechaFin.getTime()) {
            if (fechaIni.getDay() != 0 && fechaIni.getDay() != 6) {
              this.editedInfItem.fechas.push({
                date: fechaIni.toLocaleDateString("en-GB"),
                camp: [
                  {
                    hour: "",
                    activity: "",
                    result: "",
                  },
                ],
              });
            } else if (item.resolucion == 3) {
              this.editedInfItem.fechas.push({
                date: fechaIni.toLocaleDateString("en-GB"),
                camp: [
                  {
                    hour: "",
                    activity: "",
                    result: "",
                  },
                ],
              });
            }
            fechaIni.setDate(fechaIni.getDate() + 1);
          }
          let flon = this.editedInfItem.fechas.length;
          this.editedInfItem.fechas[0].camp[0].hour = this.editedInfItem.hora1;
          if (item.dias > 1)
            this.editedInfItem.fechas[flon - 1].camp[0].hour =
              this.editedInfItem.hora2;
        });
      this.modalInforme = true;
    },
    closeInforme() {
      this.modalSubirInforme = false;
      this.$nextTick(() => {
        this.memo = "";
        this.informe = null;
      });
      this.viajeList();
      this.$refs.form2.reset();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        (this.editedItem.date = new Date(
          new Date().setDate(new Date().getDate() + 1)
        )
          .toISOString()
          .substring(0, 10)),
          (this.editedItem.time = null);
        this.editedItem.date2 = null;
        this.editedItem.time2 = null;
        this.showLugar = false;
        this.showCheck = false;
      });
      this.$refs.form.reset();
    },
    closeInf() {
      this.modalInforme = false;
      this.$nextTick(() => {
        this.editedInfItem = Object.assign({}, this.defaultInfItem);
        this.editedInfIndex = -1;
        this.editedInfItem.fechas = [];
        this.editedInfItem.vias = [];
        this.index3 = 0;
        this.editedInfItem.informe = "";
        this.editedInfItem.ref = "";
      });
      this.$refs.form3.reset();
    },
    addCamp(index) {
      this.editedInfItem.fechas[index].camp.push({
        hour: "",
        activity: "",
        result: "",
      });
    },
    addVia(index) {
      this.editedInfItem.vias.push({ value: "" });
    },
    removeVia(index) {
      this.editedInfItem.vias.splice(index, 1);
    },
    removeCamp(index, index2) {
      //console.log(this.editedInfItem.fechas[index].camp[index2]);
      this.editedInfItem.fechas[index].camp.splice(index2, 1);
    },
    async saveInforme() {
      let verif = this.$refs.form2.validate();
      let formdata = new FormData();
      formdata.append("file", this.informe);
      if (verif) {
        let a = this.memo;
        await axios
          .post("file/" + a, formdata)
          .then((resp) => {
            let header = { Token: this.$store.state.token };
            let conf = { headers: header };
            axios.put("viaje/updateEstado2/" + a, { estado: 3 }, conf);
            this.message = resp.data.message;
            this.color = "success";
            this.snackbar = true;
            this.viajeList();
            this.closeInforme();
          })
          .catch((error) => {
            //this.message = "Ocurrio un Error al Subir el Archivo";
            this.message = error.response.data.message;
            this.color = "error";
            this.snackbar = true;
            /* this.closeInforme();
            this.viajeList(); */
          });
        this.closeInforme();
      }
    },
    convString(cad) {
      let palabras = cad.split(" ").map((palabra) => {
        return palabra[0].toUpperCase() + palabra.slice(1).toLowerCase();
      });
      return palabras.join(" ");
    },

    save() {
      let verif = this.$refs.form.validate();
      if (verif) {
        let dias = this.calcuDays(
          new Date(this.editedItem.date2 + " " + this.editedItem.time2),
          new Date(this.editedItem.date + " " + this.editedItem.time),
          this.editedItem.resolucion,
          this.editedItem.tv
        );
        this.editedItem.fecha1 =
          this.editedItem.date + " " + this.editedItem.time;
        this.editedItem.fecha2 =
          this.editedItem.date2 + " " + this.editedItem.time2;
        this.editedItem.motivo = this.editedItem.motivo.toUpperCase().trim();
        this.editedItem.lugar = this.editedItem.lugar.toUpperCase().trim();
        this.editedItem.ruta_viaje = this.editedItem.ruta_viaje.toUpperCase();
        let informe = this.fechaEntrega(
          new Date(this.editedItem.date2 + " " + this.editedItem.time2)
        );
        let region = this.editedItem.tv;
        let header = { Token: this.$store.state.token };
        let conf = { headers: header };
        if (this.editedIndex > -1) {
          let fecha = new Date(this.editItem.fecha_edita);
          fecha.setMinutes(fecha.getMinutes() - 15);
          axios
            .put(
              "viaje/update/" + this.editedItem.id,
              {
                comisionado: this.editedItem.comisionado,
                pais: this.editedItem.pais,
                ruta_viaje: this.editedItem.ruta_viaje,
                lugar: this.editedItem.lugar,
                transporte: this.editedItem.transporte,
                financiamiento: this.editedItem.financiamiento,
                fecha1: this.editedItem.fecha1,
                fecha2: this.editedItem.fecha2,
                dias: dias,
                total_viatico: this.editedItem.total_viatico,
                resolucion: this.editedItem.resolucion,
                //fuente_financiamiento: ,
                motivo: this.editedItem.motivo,
                //unidad: this.editedItem.unidad,
                slug: this.editedItem.slug,
                cite: this.editedItem.cite,
                estado: this.editedIndex.estado,
                representacion: this.editedItem.representacion,
                viatico: this.editedItem.viatico,
                fines_semana: this.editedItem.fines_semana,
                //fecha_modificacion: ,
                cot_dolar: this.editedItem.cot_dolar,
                iva: this.editedItem.iva,
                anticipo: this.editedItem.anticipo,
                fecha_informe: informe,
                //fecha_reliquidado:,
                //fecha_creacion:,
                fecha_edita: fecha,
                idunidad: this.editedItem.idunidad,
                iddepto: this.editedItem.iddepto,
                userupdate: this.$store.state.user._id,
                distancia: this.editedItem.distancia,
                region: region,
                inter: this.showLugar,
                excepcion: this.editedItem.radioRow,
                poa: this.editedItem.radioPoa,
                userupdate: this.$store.state.user._id,
              },
              conf
            )
            .then((data) => {
              this.message = data.data.message;
              this.color = "success";
              this.snackbar = true;
              this.close();
              this.viajeList();
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          axios
            .post(
              "viaje/add",
              {
                //comisionado:,
                pais: this.editedItem.pais,
                ruta_viaje: this.editedItem.ruta_viaje,
                lugar: this.editedItem.lugar,
                transporte: this.editedItem.transporte,
                financiamiento: this.editedItem.financiamiento,
                fecha1: this.editedItem.fecha1,
                fecha2: this.editedItem.fecha2,
                dias: dias,
                total_viatico: 0,
                resolucion: this.editedItem.resolucion,
                //fuente_financiamiento: ,
                motivo: this.editedItem.motivo,
                //unidad: this.editedItem.unidad,
                slug: "prueba",
                cite: "",
                estado: 1,
                representacion: 0,
                viatico: 0,
                fines_semana: "1",
                //fecha_modificacion: ,
                cot_dolar: 6.97,
                iva: "SI",
                anticipo: "NO",
                fecha_informe: informe,
                //fecha_reliquidado: ,
                //fecha_creacion: ,
                //idpresupuesto: ,
                idunidad: this.editedItem.idunidad,
                iddepto: this.editedItem.iddepto,
                distancia: this.editedItem.distancia,
                region: region,
                inter: this.showLugar,
                excepcion: this.editedItem.radioRow,
                poa: this.editedItem.radioPoa,
                usercreate: this.$store.state.user._id,
              },
              conf
            )
            .then((data) => {
              this.message = data.data.message;
              this.color = "success";
              this.snackbar = true;
              this.close();
              this.viajeList();
            })
            .catch((error) => {
              console.log(error.response.data);
              this.message = error.response.data.message;
              this.color = "error";
              this.snackbar = true;
            });
        }
        this.close();
      }
    },
    calcuDays(fecha1, fecha2, resolucion, tv) {
      /* let d = Math.round(((fecha1 - fecha2) / (1000 * 60 * 60 * 24)) * 10) / 10;
      let c = 0;
      if (d < 1 && d > 0) {
        d = 1;
      } else {
        d = d + 1;
      }
      while (fecha2.getTime() <= fecha1.getTime()) {
        if (resolucion != 3) {
          if (fecha2.getDay() == 0 || fecha2.getDay() == 6) {
            c += 1;
          }
        }
        fecha2.setDate(fecha2.getDate() + 1);
      }
      d = d - c;
      if (d < 1 && d > 0) {
        d = 1;
      } else if (tv == 1) {
        if (
          fecha1.getHours() >= 13 &&
          fecha1.getMinutes() >= 0 &&
          fecha1.getHours() < 19
        ) {
          d = Math.trunc(d) + 0.5;
        } else if (fecha1.getHours() >= 19 && fecha1.getMinutes() >= 0) {
          d = Math.trunc(d) + 1;
        } else {
          d = Math.trunc(d);
        }
      } else {
        if (fecha1.getHours() >= 13 && fecha1.getMinutes() >= 0) {
          d = Math.trunc(d) + 0.5;
        } else {
          d = Math.trunc(d);
        }
      }
      return d; */
      let f1 = new Date(`
        ${fecha1.getMonth() + 1}/
        ${fecha1.getDate()}/${fecha1.getFullYear()}
      `);
      let f2 = new Date(`
        ${fecha2.getMonth() + 1}/
        ${fecha2.getDate()}/${fecha2.getFullYear()}
      `);
      //console.log((f1 - f2) / (1000 * 60 * 60 * 24));
      //console.log((((fecha1 - fecha2) / (1000 * 60 * 60 * 24)) * 10) / 10);
      let d = (f1 - f2) / (1000 * 60 * 60 * 24) + 1;
      console.log(d);
      /* if (Math.floor(d) != 0) {
        if (Number.parseFloat(d)) {
          if (d - Math.floor(d) > 0.5) d = Math.round(d);
          else if (Math.round(d) == 0) d = Math.round(d) + 1;
          else d = Math.round(d);
        }
      } */
      //console.log(d);
      let c = 0;
      while (fecha2.getTime() <= fecha1.getTime()) {
        //console.log(fecha2.getTime());
        if (resolucion != 3) {
          if (fecha2.getDay() == 0 || fecha2.getDay() == 6) {
            c += 1;
          }
        }
        fecha2.setDate(fecha2.getDate() + 1);
      }
      d = d - c;
      if (d < 1 && d >= 0) {
        d = 1;
      } else if (tv == 1) {
        if (fecha1.getHours() == 13 && fecha1.getMinutes() == 0) {
          d = d - 1;
        } else if (
          fecha1.getHours() >= 13 &&
          fecha1.getMinutes() >= 0 &&
          fecha1.getHours() < 19
        ) {
          d = d - 0.5;
        } else if (fecha1.getHours() == 19 && fecha1.getMinutes() == 0) {
          d = d - 0.5;
        } else if (fecha1.getHours() < 13 && c == 0) {
          d = d - 1;
        }
        if (d < 1) d = 1;
      } else {
        //console.log(d, fecha1.getHours(), fecha1.getMinutes());
        /* if (fecha1.getHours() >= 13 && fecha1.getMinutes() > 0 && d >= 1) {
          d = d - 0.5;
        } else {
          d = d - 1;
        } */
        if (fecha1.getHours() >= 13 && d > 1) {
          if (fecha1.getHours() == 13 && fecha1.getMinutes() == 0) d = d - 1;
          else d = d - 0.5;
        } else {
          if (c == 0) d = d - 1;
        }
        if (d == 0) d = 1;
      }
      return d;
    },
    changeState(valor) {
      if (valor == 187) this.showLugar = true;
      else {
        this.editedItem.lugar = "";
        this.showLugar = false;
      }
    },
    changeState2(valor) {
      let header = { Token: this.$store.state.token };
      let conf = { headers: header };
      let o = "";
      axios
        .get("usuario/query/" + this.$store.state.user._id, conf)
        .then((data) => {
          //console.log(data);
          o = data.data.departamento_trabajo_departamento.departamento;
          this.editedItem.ruta_viaje = o + " - " + valor + " - " + o;
        });
    },
    changeTipo(valor) {
      if (valor == 2) this.showCheck = true;
      else {
        this.showCheck = false;
      }
    },
    fechaEntrega(fecha) {
      let i = 0;
      do {
        i++;
        fecha.setDate(fecha.getDate() + 1);
        if (fecha.getDay() == 0 || fecha.getDay() == 6) {
          i--;
        }
      } while (i < 8);
      fecha.setHours(23);
      fecha.setMinutes(59);
      return fecha;
    },
    changeRadio() {
      if (this.editedIndex == -1) {
        let a = new Date(Date.now() - new Date().getTimezoneOffset() * 60000);
        if (this.editedItem.radioRow == 1) {
          a.setDate(a.getDate() + 1);
          this.editedItem.date = a.toISOString().substring(0, 10);
        } else {
          this.editedItem.date = a.toISOString().substring(0, 10);
        }
      }
    },
    subirPDF(item) {
      this.memo = item.id;
      this.modalSubirInforme = true;
    },
    bajarPDF(item) {
      let header = { Token: this.$store.state.token };
      let conf = { headers: header };
      axios.get("viaje/filedownload/" + item.id, conf).then((response) => {
        /* var obj = document.createElement("object");
        obj.style.width = "100%";
        obj.style.height = "842pt";
        obj.type = "application/pdf";
        obj.data = "data:application/pdf;base64," + response.data.archivo;
        document.body.appendChild(obj);*/
        let link = document.createElement("a");
        link.download = "INF-MEMO-" + item.cite.replaceAll("/", "-") + ".pdf";
        link.href =
          "data:application/octet-stream;base64," + response.data.archivo;
        link.target = "_blank";
        link.click();
      });
    },

    bajarDocInf(item) {
      let header = { Token: this.$store.state.token };
      let conf = { headers: header };
      axios.get("viaje/fileInf/", conf).then((response) => {
        let link = document.createElement("a");
        link.download = "INF-MEMO-" + item.cite.replaceAll("/", "-") + ".docx";
        link.href =
          "data:application/octet-stream;base64," + response.data.archivo;
        link.target = "_blank";
        link.click();
      });
    },
    getColor(fecha, estado) {
      let date = new Date(fecha.split(" ")[0].split("/").reverse().join("-"));
      if (estado == 3) return "warning";
      if (estado == 4) return "accent";
      if (new Date().getTime() <= date.getTime() && estado != 5) {
        return "success";
      }
      return "error";
    },
    calHour(time) {
      let t = time.split(":");
      let r = parseInt(t[0]) + 6;
      return r + ":00";
    },

    async getQr(item) {
      //this.dialogQr = true;
      let id = this.$store.state.user._id;
      let head = { Token: this.$store.state.token };
      let conf = { headers: head };
      let dias = this.editedInfItem.dias;
      let cite = this.editedInfItem.cite;
      let fechaActual = DateTime.now().toLocaleString(DateTime.DATE_FULL);
      let u;
      await axios.get("usuario/query/" + id, conf).then((response) => {
        u = response.data;
      });
      let data = `${u.nombre}||${dias}||${fechaActual}||${cite}`;
      console.log(data);
      let qr = await QRCode.toDataURL(data);
      let link = document.createElement("a");
      link.download = "INF-MEMO-" + item.cite.replaceAll("/", "-") + ".png";
      link.href = qr;
      link.target = "_blank";
      link.click();
    },

    async crearPDF(item) {
      //console.log(item);
      var fechaActual = new Date(item.create_vi).toLocaleDateString("en-GB");
      var doc = new jsPDF("p", "mm", "letter");
      /* doc.addFileToVFS("verdana-normal.ttf", verdana);
      doc.addFileToVFS("verdana-bold-bold.ttf", verdanaBold);
      doc.addFont("verdana-normal.ttf", "verdana", "normal");
      doc.addFont("verdana-bold-bold.ttf", "verdana", "bold"); */
      let width = doc.internal.pageSize.getWidth();
      let height = doc.internal.pageSize.getHeight();
      let logoaev = new Image();
      logoaev.src = logo2;
      doc.addImage(logoaev, "JPEG", 0, 0, width, height);
      doc.setFontSize(10);
      doc.setFont("helvetica", "bold");
      doc.setLineWidth(0.1);
      doc.text("AUTORIZACIÓN DE SOLICITUD DE PASAJES Y VIATICOS", 58, 38);
      doc.line(58, 38.5, 155, 38.5);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(8);
      doc.text(fechaActual + " - " + item.cite, 15, 48);
      let nro =
        item.cite.match(/(\d+)/g)[0] + "-" + item.cite.match(/(\d+)/g)[1];
      doc.text("AUTORIZACION DE VIAJE N°.:  " + nro, 145, 48);
      let data = `${item.comisionado_comisionado.usuario_usuario.nombre}||${item.dias}||${fechaActual}||${item.cite}`;
      let qr = await QRCode.toDataURL(data);
      doc.addImage(qr, "JPEG", 181, 23, 20, 20);
      doc.setFontSize(6);
      doc.text("PYV - 001", 187, 43);
      doc.rect(15, 49, 186, 195);
      doc.setDrawColor(0);
      doc.setFillColor(238, 237, 237);
      doc.rect(15.7, 49.6, 184.7, 5, "FD");
      doc.setFontSize(8);
      doc.text("DATOS DEL COMISIONADO", 87, 53);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(8);
      doc.rect(15.7, 55.5, 184.7, 21);
      doc.setFont("helvetica", "bold");
      doc.text("NOMBRE DEL COMISIONADO:", 16.5, 59);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);
      doc.text(item.comisionado_comisionado.usuario_usuario.nombre, 60.5, 59);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(8);
      doc.text("C.I.:", 148.5, 59);
      doc.setFontSize(7);
      doc.setFont("helvetica", "normal");
      doc.text(item.comisionado_comisionado.usuario_usuario.ci, 155.5, 59);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(8);
      doc.text("OFICINA:", 16.5, 64);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);
      doc.text(
        item.comisionado_comisionado.usuario_usuario.oficina_oficina.nombre,
        60.5,
        64
      );
      /* doc.setFont("helvetica", "bold");
      doc.setFontSize(8);
      doc.text("CELULAR:", 140.5, 58);
      doc.setFontSize(7);
      doc.setFont("helvetica", "normal");
      doc.text(
        String(item.comisionado_comisionado.usuario_usuario.celular || ""),
        155.5,
        58
      ); */
      doc.setFont("helvetica", "bold");
      doc.setFontSize(8);
      doc.text("CARGO:", 16.5, 69);
      doc.setFont("helvetica", "normal");
      doc.text(item.comisionado_comisionado.usuario_usuario.cargo, 60.5, 69);
      doc.setFont("helvetica", "bold");
      doc.text("CATEGORIA:", 16.5, 74);
      doc.setFont("helvetica", "normal");
      doc.text(
        String(item.comisionado_comisionado.usuario_usuario.categoria),
        60.5,
        74
      );
      doc.setFont("helvetica", "bold");
      doc.text("MOTIVO:", 16.5, 81);
      var parrafoMotivo = doc.splitTextToSize(item.motivo, 165);
      doc.setFont("helvetica", "normal");
      doc.text(parrafoMotivo, 35.5, 81);
      doc.rect(15.7, 76.5, 184.7, 18);
      doc.setDrawColor(0);
      doc.setFillColor(238, 237, 237);
      doc.rect(15.7, 94.5, 184.7, 5, "FD");
      doc.setFont("helvetica", "bold");
      doc.text("ITINERARIO", 98, 97.6);
      doc.rect(15.7, 99.5, 99.3, 22);
      doc.setFont("helvetica", "bold");
      doc.text("RUTA DE VIAJE:", 16.5, 104);
      doc.setFont("helvetica", "normal");
      var ruta = doc.splitTextToSize(item.ruta_viaje, 65);
      doc.text(ruta, 45.5, 102.5);
      doc.setFont("helvetica", "bold");
      doc.text("PAÍS:", 16.5, 111);
      doc.setFont("helvetica", "normal");
      doc.text(item.pais_pai.nombre.toUpperCase(), 45.5, 111);
      let lugar = doc.splitTextToSize(item.lugar, 65);
      if (item.pais_pai.nombre == "Bolivia") {
        doc.setFont("helvetica", "bold");
        doc.text("DEPARTAMENTO:", 16.5, 114);
        doc.setFont("helvetica", "normal");
        doc.text(
          item.iddepto_departamento.departamento.toUpperCase(),
          45.5,
          114
        );
        doc.setFont("helvetica", "bold");
        doc.text("LUGAR:", 16.5, 119);
        doc.setFont("helvetica", "normal");
        doc.text(lugar, 45.5, 119);
      } else {
        doc.setFont("helvetica", "bold");
        doc.text("LUGAR:", 16.5, 114);
        doc.setFont("helvetica", "normal");
        doc.text(lugar, 45.5, 114);
      }
      doc.rect(15.7, 121.5, 49.65, 5);
      doc.setFont("helvetica", "bold");
      doc.text("FECHA Y HORA DE SALIDA", 22.7, 124.7);
      doc.setFont("helvetica", "normal");
      doc.text(
        `${item.fecha1.split(" ")[0].replaceAll("/", "-")} ${
          item.fecha1.split(" ")[1]
        }`,
        40.7,
        131.5,
        null,
        null,
        "center"
      );
      doc.rect(65.35, 121.5, 49.65, 5);
      doc.setFont("helvetica", "bold");
      doc.text("FECHA Y HORA DE RETORNO", 69.7, 124.7);
      doc.setFont("helvetica", "normal");
      doc.text(
        `${item.fecha2.split(" ")[0].replaceAll("/", "-")} ${
          item.fecha2.split(" ")[1]
        }`,
        89.7,
        131.5,
        null,
        null,
        "center"
      );
      doc.rect(15.7, 126.5, 49.65, 10);
      doc.rect(65.35, 126.5, 49.65, 10);
      doc.rect(115.1, 99.5, 85.3, 22);
      doc.setFont("helvetica", "bold");
      doc.text("MEDIO DE TRANSPORTE:", 116.2, 104);
      doc.setFont("helvetica", "normal");
      let transporte = doc.splitTextToSize(
        item.transporte_transporte.nombre,
        45
      );
      doc.text(transporte, 153.2, 104);
      doc.setFont("helvetica", "bold");
      doc.text("TIPO DE VIAJE:", 116.2, 111);
      doc.setFont("helvetica", "normal");
      if (item.viaje_nacionals.length > 0) {
        doc.text(
          item.viaje_nacionals[0].region_nacional_region_nacional.nombre,
          153.2,
          111
        );
      } else if (item.viaje_internacionals.length > 0) {
        let parrafoTipo = doc.splitTextToSize(
          item.viaje_internacionals[0]
            .region_rectinternacional_region_internacional.nombre,
          50
        );
        doc.text(parrafoTipo, 153.2, 108.5);
      }
      doc.setFont("helvetica", "bold");
      doc.text("FINANCIAMIENTO:", 116.2, 119);
      doc.setFont("helvetica", "normal");
      doc.text(item.financiamiento_financiamiento.nombre, 153.2, 119);
      doc.rect(115.1, 121.5, 85.3, 15);
      doc.setFont("helvetica", "bold");
      doc.text("TOTAL DIAS:", 116.2, 127);
      doc.setFont("helvetica", "normal");
      doc.text(String(item.dias), 140.2, 127);
      doc.setFont("helvetica", "bold");
      doc.text("RESOLUCION:", 116.2, 132.5);
      doc.setFont("helvetica", "normal");
      let parrafoResolucion = doc.splitTextToSize(
        item.resolucion_resolucion.descripcion,
        60
      );
      doc.text(parrafoResolucion, 140.2, 132.5);
      doc.setDrawColor(0);
      doc.setFillColor(238, 237, 237);
      doc.rect(15.7, 136.5, 184.7, 5, "FD");
      doc.setFont("helvetica", "bold");
      doc.text("FINANCIAMIENTO", 93, 140);
      doc.rect(15.7, 141.5, 99.3, 7);
      doc.setFont("helvetica", "bold");
      doc.text("PROGRAMA:", 16.5, 145.7);
      doc.setFont("helvetica", "normal");
      let programa = doc.splitTextToSize(
        item.idunidad_unidad.fuente + " - " + item.idunidad_unidad.descripcion,
        80
      );
      doc.text(programa, 40.5, 144.7);
      doc.setFont("helvetica", "bold");
      doc.text("FUENTE:", 116.2, 145.7);
      doc.setFont("helvetica", "normal");
      doc.text(String(item.idunidad_unidad.fuente), 130.2, 145.7);
      doc.rect(115.1, 141.5, 85.3, 7);
      doc.setFont("helvetica", "bold");
      doc.text("PLAZO DE PRESENTACIÓN DE INFORME: ", 16.5, 152);
      doc.setFont("helvetica", "normal");
      doc.text(`${item.fecha_informe.replaceAll("/", "-")}`, 74.5, 152);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);
      doc.text(
        'El incumplimiento dará lugar a la aplicación del Art. 25 del "Reglamento Interno de Pasajes y Viáticos".',
        16.5,
        155.8
      );
      doc.rect(15.7, 148.5, 184.7, 9);
      doc.rect(15.7, 157.5, 184.7, 9);
      doc.rect(15.7, 166.5, 184.7, 33);
      doc.setFont("helvetica", "bold");
      doc.text("SE ENCUENTRA EN EL POA DEL AREA.:", 18.5, 162.8);
      doc.setDrawColor(0);
      doc.setFillColor(255, 255, 255);
      doc.roundedRect(70, 160.1, 5, 4, 2, 2, "FD");
      doc.setFontSize(6);
      doc.setFont("helvetica", "normal");
      doc.text("Si", 76, 162.8);
      doc.setDrawColor(0);
      doc.setFillColor(255, 255, 255);
      doc.roundedRect(79, 160.1, 5, 4, 2, 2, "FD");
      doc.setFontSize(6);
      doc.setFont("helvetica", "normal");
      doc.text("No", 85, 162.8);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(9);
      if (item.poa == 1) {
        doc.text("X", 71.5, 163.3);
      } else {
        doc.text("X", 80.5, 162.3);
      }
      doc.setFontSize(7);
      doc.setFont("helvetica", "bold");
      doc.text("VIAJES POR.:", 108.5, 162.8);
      doc.setFont("helvetica", "bold");
      doc.setDrawColor(0);
      doc.setFillColor(255, 255, 255);
      doc.roundedRect(125.8, 160.1, 4, 4, 2, 2, "FD");
      doc.setFont("helvetica", "normal");
      doc.setFontSize(6);
      doc.text("Excepción - (ART. 14 RPV)", 130.5, 162.8);
      doc.setDrawColor(0);
      doc.setFillColor(255, 255, 255);
      doc.roundedRect(157.5, 160.1, 4, 4, 2, 2, "FD");
      doc.setFont("helvetica", "normal");
      doc.setFontSize(6);
      doc.text("Extraordinario - (Instrucción MAE)", 162.5, 162.8);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(9);
      if (item.excepcion == 2) {
        doc.text("X", 126.8, 163.3);
      } else if (item.excepcion == 3) {
        doc.text("X", 158.5, 163.3);
      }
      doc.setFont("helvetica", "bold");
      doc.setFontSize(7);
      doc.text("SE DEBE TOMAR EN CUENTA QUE:", 16.5, 171.7);
      doc.line(16.5, 173.3, 58.8, 173.3);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(6);
      doc.text(
        "1.- Este formulario debe presentarse debidamente firmado por el comisionado y por la autoridad que aprueba el viaje en los plazos establecidos en el Reglamento de Pasajes y Viáticos.",
        18.5,
        176.5
      );
      doc.text(
        "2.- Para la suspensión de un viaje, se deberá presentar una nota de justificación con un plazo no menor a las 24 horas antes del viaje, salvo excepciones.",
        18.5,
        178.7
      );
      doc.setFontSize(7);
      doc.setFont("helvetica", "bold");
      doc.line(16.5, 184.3, 37, 184.3);
      doc.text("ACLARACIONES", 16.5, 183.7);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(6);
      let parrafoAclaracion = doc.splitTextToSize(
        "En los viajes INTERDEPARTAMENTALES, cuando el retorno de una comisión que incluya pernocte, sea efectuado entre horas 13:00 y 19:00 corresponderá el pago al equivalente del 50% de un día de viático por el día de retorno y, el 100% de un día viático, cuando el retorno sea realizado con posterioridad a las 19:00 horas, considerando la hora señalada en el pasaje terrestre o pase abordo, según corresponda, conforme al Reglamento de Pasajes y Viaticos, Artículo 31.\nCuando los viajes sean de carácter INTRADEPARTAMENTAL y la distancia del viaje sea menor a 100 kilómetros de la sede de funciones del comisionado, se pagará un estipendio de Bs. 50; Cuando esta distancia sea mayor a los 100 km se pagará el equivalente al 40% de un día de viático de acuerdo a escala establecida en el presente Reglamento.",
        180
      );
      doc.text(parrafoAclaracion, 17.5, 187.7);
      doc.rect(15.7, 180.5, 184.7, 62.8);
      let comisionado = doc.splitTextToSize(
        item.comisionado_comisionado.usuario_usuario.nombre +
          "\n" +
          item.comisionado_comisionado.usuario_usuario.cargo,
        60
      );
      doc.setFont("helvetica", "italic");
      doc.setFontSize(8);
      doc.line(35.5, 234, 103.5, 234);
      doc.text(comisionado, 68.5, 237, { align: "center" });
      doc.line(122.5, 234, 190, 234);
      doc.text("FIRMA Y SELLO DE APROBACION", 155.5, 237, { align: "center" });
      /* doc.setFont("helvetica", "italic");
      doc.setFontSize(5);
      doc.text("PAGINA 1 - SOLICITUD DE VIAJE", 173.5, 260);
      doc.text(
        "Calle Fernando Guachalla No 411 Esq 20 de Octubre - Teléfonos: (591) - 2 - 2147767 - 2148747",
        108.5,
        269,
        { align: "center" }
      );
      doc.line(16, 267, 201, 267); */
      //doc.output("dataurlnewwindow", { filename: "Formulario-Viaje" });
      //doc.save("Formulario-viaje.pdf");
      window.open(doc.output("bloburl", { filename: "Solicitud-Viaje" }));
    },

    async saveInf() {
      /* let doc2 = new jsPDF('p', 'mm', 'letter')
      let margin = 20
      let scale = ((doc2.internal.pageSize.width - margin) * 2) / document.body.scrollWidth
      doc2.html(document.body, {
        x: margin,
        y: margin,
        html2canvas: {
          scale: scale
        },
        callback: function (doc2) {
          doc2.output('dataurlnewwindow', { filename: "prueba.pdf" })
        }
      }) */
      //console.log(this.editedInfItem);
      let verif = this.$refs.form3.validate();
      if (verif) {
        let id = this.$store.state.user._id;
        let head = { Token: this.$store.state.token };
        let conf = { headers: head };
        let u;
        let dias = this.editedInfItem.dias;
        let cite = this.editedInfItem.cite;
        let adjunto = this.editedInfItem.adjunto;
        await axios.get("usuario/query/" + id, conf).then((response) => {
          u = response.data;
        });
        let direc = await this.director.find(
          (item) => item.value == this.editedInfItem.director
        );

        /* if (via == null) {
          via = { value: 0, text: "" };
        } */
        let fechaActual = DateTime.now().toLocaleString(DateTime.DATE_FULL);
        this.editedInfItem.fechaIni = DateTime.fromISO(
          this.editedInfItem.fechaIni
        ).toFormat("dd/MM/yyyy");
        this.editedInfItem.fechaFin = DateTime.fromISO(
          this.editedInfItem.fechaFin
        ).toFormat("dd/MM/yyyy");
        let table = [];
        /* let table = [
        ]; */
        table.push(
          [
            {
              content: "A:",
              styles: { halign: "right", fontStyle: "bold" },
            },
            {
              content: this.convString(direc.text),
              styles: { halign: "left" },
            },
          ],
          [
            {
              content: "",
              styles: {
                halign: "left",
                fontStyle: "bold",
                cellWidth: "wrap",
                cellPadding: { top: -1, left: 2 },
              },
            },
            {
              content: direc.aux,
              styles: {
                halign: "left",
                fontStyle: "bold",
                cellWidth: "wrap",
                cellPadding: { top: -1, left: 2 },
              },
            },
          ]
        );
        let aux2 = "";
        await this.editedInfItem.vias.map(async (element) => {
          aux2 = element.value + "<br/>" + aux2;
          let via = await this.director.find(
            (item) => item.value == element.value
          );
          table.push(
            [
              {
                content: "Via:",
                styles: { halign: "right", fontStyle: "bold" },
              },
              {
                content: this.convString(via.text),
                styles: { halign: "left" },
              },
            ],
            [
              {
                content: "",
                styles: {
                  halign: "left",
                  fontStyle: "bold",
                  cellWidth: "wrap",
                  cellPadding: { top: -1, left: 2 },
                },
              },
              {
                content: via.aux,
                styles: {
                  halign: "left",
                  fontStyle: "bold",
                  cellWidth: "wrap",
                  cellPadding: { top: -1, left: 2 },
                },
              },
            ]
          );
        });

        table.push(
          [
            {
              content: "De:",
              styles: { halign: "right", fontStyle: "bold" },
            },
            {
              content: this.convString(u.nombre),
              styles: { halign: "left" },
            },
          ],
          [
            {
              content: "",
              styles: {
                halign: "left",
                fontStyle: "bold",
                cellWidth: "wrap",
                cellPadding: { top: -1, left: 2 },
              },
            },
            {
              content: u.cargo,
              styles: {
                halign: "left",
                fontStyle: "bold",
                cellWidth: "wrap",
                cellPadding: { top: -1, left: 2 },
              },
            },
          ],
          [
            {
              content: "Ref.:",
              styles: { halign: "right", fontStyle: "bold" },
            },
            {
              content: this.editedInfItem.ref.toUpperCase(),
              styles: { halign: "left" },
            },
          ],
          [
            {
              content: "Fecha:",
              styles: { halign: "right", fontStyle: "bold" },
            },
            {
              content:
                u.departamento_trabajo_departamento.departamento +
                ", " +
                fechaActual,
              styles: { halign: "left" },
            },
          ]
        );
        //console.log(this.editedInfItem);
        let tableTA = [
          [
            { content: "RECORRIDO", styles: { fontStyle: "bold" } },
            { content: "FECHAS", styles: { fontStyle: "bold" } },
            { content: "HORA", styles: { fontStyle: "bold" } },
            { content: "OBS.", styles: { fontStyle: "bold" } },
          ],
          [
            {
              content: this.convString(
                u.departamento_trabajo_departamento.departamento +
                  " - " +
                  this.editedInfItem.lugar2
              ),
            },
            { content: this.editedInfItem.fechaIni },
            { content: this.editedInfItem.hora1 },
            { content: this.editedInfItem.obs1 },
          ],
          [
            {
              content: this.convString(
                this.editedInfItem.lugar2 +
                  " - " +
                  u.departamento_trabajo_departamento.departamento
              ),
            },
            { content: this.editedInfItem.fechaFin },
            { content: this.editedInfItem.hora2 },
            { content: this.editedInfItem.obs2 },
          ],
        ];
        //console.log(u);
        let tableTT = [
          [
            { content: "RECORRIDO", styles: { fontStyle: "bold" } },
            { content: "FECHAS", styles: { fontStyle: "bold" } },
            { content: "HORA", styles: { fontStyle: "bold" } },
            { content: "PLACA VEHICULO", styles: { fontStyle: "bold" } },
            { content: "NOMBRE CHOFER", styles: { fontStyle: "bold" } },
          ],
          [
            {
              content: this.convString(
                u.departamento_trabajo_departamento.departamento +
                  " - " +
                  this.editedInfItem.lugar2
              ),
              //content: this.convString(this.editedInfItem.lugar2),
            },
            { content: this.editedInfItem.fechaIni },
            { content: this.editedInfItem.hora1 },
            { content: this.editedInfItem.placa },
            { content: this.editedInfItem.chofer },
          ],
          [
            {
              content: this.convString(
                this.editedInfItem.lugar2 +
                  " - " +
                  u.departamento_trabajo_departamento.departamento
              ),
              //content: this.convString(this.editedInfItem.lugar2),
            },
            { content: this.editedInfItem.fechaFin },
            { content: this.editedInfItem.hora2 },
            { content: this.editedInfItem.placa },
            { content: this.editedInfItem.chofer },
          ],
        ];
        let table2 = [[this.editedInfItem.antecedente]];
        let table4 = [[this.editedInfItem.objeto]];
        let table6 = [
          [
            {
              content:
                "El proyecto: " +
                this.editedInfItem.conclusiones +
                "\nSe recomienda: " +
                this.editedInfItem.recomendaciones +
                `\nEs cuanto informo para fines respectivos, adjuntando documentación de respaldo, según lo establecido en el RPYV (${adjunto}).`,
            },
          ],
        ];
        let table7 = [
          [
            {
              content: "ELABORADO POR:",
              styles: { halign: "center", fontStyle: "bold" },
            },
            {
              content: "APROBADO POR:",
              styles: { halign: "center", fontStyle: "bold" },
            },
          ],
          [
            {
              content: this.convString(u.nombre),
              styles: { halign: "center" },
            },
            {
              content: this.convString(direc.text),
              styles: { halign: "center" },
            },
          ],
          [
            {
              content: u.cargo,
              styles: {
                halign: "center",
                fontStyle: "bold",
                cellPadding: { top: -1 },
              },
            },
            {
              content: direc.aux,
              styles: {
                halign: "center",
                fontStyle: "bold",
                cellPadding: { top: -1 },
              },
            },
          ],
        ];
        let k = 0;
        let x = this.editedInfItem.fechas.length;
        let f = [];
        let fechas = [];
        do {
          let aux = this.editedInfItem.fechas[k].camp;
          let camp = [];
          for (let i = 0; i < aux.length; i++) {
            let row = [];
            for (let key in aux[i]) {
              row.push(aux[i][key]);
            }
            camp.push({
              hour: row[0],
              activity: row[1],
              result: row[2],
              id: row[3],
            });
            if (0 == i) {
              row.unshift({
                rowSpan: aux.length,
                content: this.editedInfItem.fechas[k].date,
                styles: { valign: "middle", halign: "center" },
              });
            }
            f.push(row);
          }
          fechas.push({
            fecha: this.editedInfItem.fechas[k].date
              .split(" ")[0]
              .split("/")
              .reverse()
              .join("-"),
            detalle: camp,
            id: this.editedInfItem.fechas[k].id,
          });
          k++;
        } while (k < x);
        let obs = "";
        if (!this.showTrans) {
          obs = this.editedInfItem.obs1 + "<br/>" + this.editedInfItem.obs2;
        }
        let informe = {
          fecha: new Date(),
          nro_informe: this.editedInfItem.informe,
          viaje: this.editedInfItem.viaje,
          ref: this.editedInfItem.ref.toUpperCase(),
          director: this.editedInfItem.director,
          antecedente: this.editedInfItem.antecedente,
          objeto: this.editedInfItem.objeto,
          via: aux2, //this.editedInfItem.via,
          conclusion:
            this.editedInfItem.conclusiones +
            "<br/>" +
            this.editedInfItem.recomendaciones,
          observacion: obs,
          placa: this.editedInfItem.placa,
          chofer: this.editedInfItem.chofer,
          fechas: fechas,
          adjunto: this.editedInfItem.adjunto,
          user: this.$store.state.user._id,
        };
        let pdf = false;
        if (this.editedInfIndex > -1) {
          await axios
            .put("informe/update/" + this.editedInfItem.id, informe, conf)
            .then((response) => {
              pdf = true;
              this.message = response.data.message;
              this.color = "success";
              this.snackbar = true;
              this.close();
              this.viajeList();
            })
            .catch((error) => {
              pdf = false;
              this.message = "Ocurrio Algun Fallo";
              this.color = "error";
              this.snackbar = true;
              this.closeInf();
              this.viajeList();
              console.log(error);
            });
        } else {
          await axios
            .post("informe/add", informe, conf)
            .then((response) => {
              pdf = true;
              this.message = response.data.message;
              this.color = "success";
              this.snackbar = true;
              this.close();
              this.viajeList();
            })
            .catch((error) => {
              pdf = false;
              this.message = "Ocurrio Algun Fallo";
              this.color = "error";
              this.snackbar = true;
              this.closeInf();
              this.viajeList();
              console.log(error);
            });
        }
        if (pdf) {
          let doc = new jsPDF("p", "mm", "letter");
          let width = doc.internal.pageSize.getWidth();
          let height = doc.internal.pageSize.getHeight() + 3;
          let logoaev = new Image();
          logoaev.src = logo2;
          doc.addImage(logoaev, "JPEG", 0, 0, width, height);
          console.log(doc.getFontList());
          doc.autoTable({
            head: [
              [
                {
                  content: this.editedInfItem.informe,
                  colSpan: 2,
                  styles: { fontStyle: "bold", halign: "center" },
                },
              ],
            ],
            body: table,
            margin: { top: 36, left: 20 },
            styles: {
              /* lineWidth: 0.25,
              lineColor: 0, */
              fontSize: 11,
              font: "verdana-bold",
            },
            columnStyles: {
              0: { cellWidth: 35 },
              1: { cellWidth: 141 },
            },
            theme: "plain",
            //didDrawPage: function (data) {
            // Header
            //doc.addImage(logoaev, "JPEG", 0, 0, width, height);
            // Footer
            /* let texto = doc.splitTextToSize(
                "La Paz Oficina Nacional: Calle Fernando Guachalla No. 411 esq. Av. 20 de Octubre Teléfonos: 2148984 – 800102373 – Fax – 2148743 – www.aevivienda.gob.bo",
                500
              );
              let texto2 = doc.splitTextToSize(
                "Oficinas departamentales: La Paz (2125356), Oruro (5112500), Santa Cruz (3436259), Cochabamba (4123991), Tarija (6113375), Chuquisaca (6914698), Beni (4631445), Pando (8421497)",
                580
              );
              doc.setFontSize(7.5);
              doc.setFont("helvetica", "italic");
              let pageSize = doc.internal.pageSize;
              let pageHeight = pageSize.height
                ? pageSize.height
                : pageSize.getHeight();
              doc.text(texto, 20, pageHeight - 10);
              doc.setFontSize(6.5);
              doc.text(texto2, 20, pageHeight - 7.5);
              doc.setDrawColor(0, 0, 0);
              doc.setLineWidth(0.5);
              doc.line(20, pageHeight - 13, 196.5, pageHeight - 13); */
            //},
          });
          doc.setFont("verdana-bold", "bold");
          doc.setFontSize(10);
          doc.text("PYV - 006", 185, 32);
          let data = `${u.nombre}||${dias}||${fechaActual}||${cite}`;
          let qr = await QRCode.toDataURL(data);
          doc.addImage(qr, "JPEG", 182, 9, 20, 20);
          doc.setFontSize(10);
          doc.setFont("verdana-bold", "bold");
          doc.text(doc.internal.pageSize.width / 2, 35, "INFORME", "center");
          doc.setLineWidth(0.5);
          doc.setDrawColor(0, 0, 0);
          let y = doc.lastAutoTable.finalY + 4;
          doc.line(20, y, 196.5, y);
          doc.autoTable({
            body: table2,
            startY: doc.lastAutoTable.finalY + 8,
            head: [
              [{ content: "1.   ANTECEDENTES", style: { fontStyle: "bold" } }],
            ],
            margin: { left: 20, right: 20 },
            styles: {
              fontSize: 10,
              font: "verdana",
            },
            theme: "plain",
          });
          if (
            this.editedInfItem.transporte == 3 ||
            this.editedInfItem.transporte == 4
          ) {
            doc.autoTable({
              body: tableTT,
              startY: doc.lastAutoTable.finalY + 3,
              head: [
                [
                  {
                    content:
                      "TRANSPORTE TERRESTRE (VEHICULO OFICIAL, PRIVADO Y/O TERCEROS)",
                    colSpan: 5,
                  },
                ],
              ],
              margin: { left: 30, right: 30 },
              styles: {
                lineWidth: 0.25,
                lineColor: 0,
                fontSize: 10,
                font: "helvetica",
                halign: "center",
              },
              columnStyles: {
                0: { cellWidth: 38 },
                2: { cellWidth: 20 },
                3: { cellWidth: 23 },
              },
              theme: "plain",
            });
          } else {
            doc.autoTable({
              body: tableTA,
              startY: doc.lastAutoTable.finalY + 3,
              head: [
                [
                  {
                    content: "TRANSPORTE AEREO Y/O TERRESTRE PÚBLICO",
                    colSpan: 4,
                  },
                ],
              ],
              margin: { left: 45, right: 45 },
              styles: {
                lineWidth: 0.25,
                lineColor: 0,
                fontSize: 10,
                font: "helvetica",
                halign: "center",
              },
              columnStyles: {
                0: { cellWidth: 38 },
                1: { cellWidth: 25 },
                2: { cellWidth: 20 },
              },
              theme: "plain",
            });
          }
          doc.autoTable({
            body: table4,
            startY: doc.lastAutoTable.finalY + 3,
            head: [
              [
                {
                  content: "2.\tOBJETO DEL VIAJE",
                  style: { fontStyle: "bold" },
                },
              ],
            ],

            margin: { left: 20, right: 20 },
            styles: {
              fontSize: 10,
              font: "helvetica",
            },
            theme: "plain",
          });
          doc.autoTable({
            startY: doc.lastAutoTable.finalY + 3,
            head: [
              [{ content: "3.\tDESARROLLO", style: { fontStyle: "bold" } }],
            ],
            margin: { left: 20, right: 20 },
            styles: {
              fontSize: 10,
              font: "helvetica",
            },
            theme: "plain",
          });
          doc.autoTable({
            head: [
              [
                {
                  content: "FECHA",
                  styles: {
                    valign: "middle",
                    halign: "center",
                    fontStyle: "bold",
                  },
                },
                {
                  content: "HORA",
                  styles: {
                    valign: "middle",
                    halign: "center",
                    fontStyle: "bold",
                  },
                },
                {
                  content:
                    "ACTIVIDAD DESARROLLADAS DURANTE LA\nCOMISION DEL VIAJE",
                  styles: {
                    valign: "middle",
                    halign: "center",
                    fontStyle: "bold",
                  },
                },
                {
                  content: "RESULTADOS\nALCANZADOS",
                  styles: {
                    valign: "middle",
                    halign: "center",
                    fontStyle: "bold",
                  },
                },
              ],
            ],
            body: f,
            //showHead: "firstPage",
            startY: doc.lastAutoTable.finalY + 3,
            margin: { top: 30, left: 20, right: 20, bottom: 30 },
            //pageBreak: "auto",

            styles: {
              lineWidth: 0.25,
              lineColor: 0,
              fontSize: 10,
              font: "helvetica",
            },
            columnStyles: {
              0: { valign: "middle", halign: "center", cellWidth: 23 },
              1: { valign: "middle", halign: "center", cellWidth: 15 },
              2: { valign: "middle" },
              3: { valign: "middle", cellWidth: 55 },
            },
            theme: "plain",
          });
          doc.autoTable({
            body: table6,
            startY: doc.lastAutoTable.finalY + 5,
            head: [
              [
                {
                  content: "4.\tCONCLUSIONES Y RECOMENDACIONES",
                  style: { fontStyle: "bold" },
                  colSpan: 2,
                },
              ],
            ],
            margin: { top: 30, left: 20, right: 20 },
            styles: {
              fontSize: 10,
              font: "helvetica",
            },
            showHead: "firstPage",
            foot: [],
            theme: "plain",
          });
          doc.autoTable({
            body: table7,
            startY: doc.lastAutoTable.finalY + 35,
            margin: { top: 60, left: 20, right: 20 },

            styles: {
              fontSize: 10,
              font: "helvetica",
            },
            foot: [],
            columnStyles: {
              0: { cellWidth: 90 },
              2: { cellWidth: 100 },
            },
            theme: "plain",
          });
          doc.setProperties({
            title: "Informe de Viaje",
          });
          window.open(doc.output("bloburl", { filename: "Informe-Viaje" }));
          //doc.output("datauristring", { filename: "Informe-Viaje" });
          this.closeInf();
        }
      }
    },
  },
};
</script>
<style scoped></style>
